.mx_GenericErrorPage {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mx_GenericErrorPage_box {
    display: inline;
    width: 500px;
    min-height: 125px;
    border: 1px solid #f22;
    padding: 10px 10px 20px;
    background-color: #fcc;
}
