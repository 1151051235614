/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2019 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_ContextualMenu_wrapper {
    position: fixed;
    z-index: 10000;
}

.mx_ContextualMenu_wrapper_drilled {
    .mx_ContextualMenu  {
        background-color: $background;
    }
}

.mx_ContextualMenu_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1.0;

    &_clickthrough {
        pointer-events: none;
    }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.mx_ContextualMenu {
    overflow: hidden;
    border-radius: 12px;
    // box-shadow: 4px 4px 12px 0 $menu-box-shadow-color;
    box-shadow: 0px 4px 24px rgba(10, 38, 46, 0.08);
    background-color: $menu-bg-color;
    color: $primary-content;
    position: absolute;
    font-size: $font-14px;
    // z-index: 5001; // not enough
    width: max-content;
    z-index: 10001;

    animation: 0.1s ease 0s 1 zoomIn forwards;
    transform: scale(0.9);
    opacity: 0;
}

.mx_ContextualMenu_right {
    right: 0;
}

.mx_ContextualMenu.mx_ContextualMenu_withChevron_right {
    right: 8px;
}

.mx_ContextualMenu_chevron_right {
    position: absolute;
    right: -8px;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 8px solid $menu-bg-color;
    border-bottom: 8px solid transparent;
}

.mx_ContextualMenu_left {
    left: 0;
}

.mx_ContextualMenu.mx_ContextualMenu_withChevron_left {
    left: 8px;
}

.mx_ContextualMenu_chevron_left {
    position: absolute;
    left: -8px;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid $menu-bg-color;
    border-bottom: 8px solid transparent;
}

.mx_ContextualMenu_top {
    top: 0;
}

.mx_ContextualMenu.mx_ContextualMenu_withChevron_top {
    top: 8px;
}

.mx_ContextualMenu_chevron_top {
    position: absolute;
    left: 0px;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-bottom: 8px solid $menu-bg-color;
    border-right: 8px solid transparent;
}

.mx_ContextualMenu_bottom {
    bottom: 0;
}

.mx_ContextualMenu.mx_ContextualMenu_withChevron_bottom {
    bottom: 8px;
}

.mx_ContextualMenu_chevron_bottom {
    position: absolute;
    left: 0px;
    bottom: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-top: 8px solid $menu-bg-color;
    border-right: 8px solid transparent;
}

.mx_ContextualMenu_rightAligned {
    transform: translateX(-100%);
}

.mx_ContextualMenu_bottomAligned {
    transform: translateY(-100%);
}
