@import "./_colors.scss";

.bp_Icon {

    margin-top: 3px;

    &.blue {
      fill: $azure-500;
    }

    &.red {
      fill: $siren-100;
    }

    &.yellow {
      fill: $shine-100;
    }

    &.gray {
      fill: $bp-icon-gray;
    }

    &.green {
      fill:#00C853;
    }
}
