.bp_LinkPreview {

    margin-top: 2px;
    border-radius: 12px;
    overflow: hidden;
    cursor: default;

    // override a tag styles 
    a {
        text-decoration: none;
        color: unset;
    }

    &_CoverImage {
        object-fit: cover;
        margin-bottom: -6px;
    }

    &_InfoBox {
        background-color: $event-tile-bg;
        padding: 10px;
    }

    &_url {
        font-size: 12px;
        color: $koolaid-100;
        margin: 4px 0;
    }

    &_description {
        font-size: 13px;
        color: $link-preview-text-color;
    }

    .bp_LinkPreview_InfoBox {
        background: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--message-bg-saturation),
            var(--message-bg-lightness),
            var(--message-bg-opacity)
        );
    }
}

.line_limit {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  &.one {
      // line-height: 14px; /* fallback */
      // max-height: 28px; /* fallback */
      -webkit-line-clamp: 1;
  }

  &.two {
      // max-height: 32px; /* fallback */
      -webkit-line-clamp: 2;
  }
}