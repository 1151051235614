.mx_ToastContainer {
    .mx_Toast_toast {
        border-radius: 16px;

        &.mx_Toast_hasIcon {
            &.mx_Toast_icon_verification::after {
                width: 25px;
                height: 25px;
                background: url("$(res)/themes/beeper/img/icon-shield.svg");
                mask-image: none;
            }

            &.mx_Toast_icon_verification_warning {
                &::before {
                    width: 25px;
                    height: 25px;
                    background-color: transparent;
                    background: url("$(res)/themes/beeper/img/icon-shield.svg");
                    mask-image: none;
                }

                &::after {
                    mask-image: none;
                    background-color: transparent;
                }
            }
        }
    }
}

.mx_Toast_title {
    font-size: 14px;
    font-weight: 400;
}