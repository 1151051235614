/*
Copyright 2019 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_HeaderButtons {
    -webkit-app-region: no-drag;
    display: flex;
    align-items: center;
}

.mx_RoomHeader_buttons + .mx_HeaderButtons {
    // remove the | separator line for when next to RoomHeaderButtons
    // TODO: remove this once when we redo communities and make the right panel similar to the new rooms one
    &::before {
        content: unset;
    }
}
