.bp_ChatSectionContextMenu-settings {
    max-width: 300px;
    padding: 0 20px;

    .mx_SettingsFlag {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        flex-direction: row-reverse;
        text-align: left;
    }

    .mx_SettingsRadio {
        display: flex;
        justify-content: flex-end;
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin: 10px 0;
    }

    .mx_SettingsFlag_microcopy {
        display: none;
    }

    .bp_SettingsField {
        margin: 0;
        min-width: 70px;
        max-width: 70px;
    }
}