/*
Copyright 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_FilePanel {
    order: 2;
    flex: 1 1 0;
    overflow-y: auto;
    display: flex;
}

.mx_FilePanel .mx_RoomView_messageListWrapper {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mx_FilePanel .mx_RoomView_MessageList {
    width: 100%;
}

.mx_FilePanel .mx_RoomView_MessageList h2 {
    display: none;
}

/* FIXME: rather than having EventTile's default CSS be for MessagePanel,
   we should make EventTile a base CSS class and customise it specifically
   for usage in {Message,File,Notification}Panel. */

.mx_FilePanel .mx_EventTile_avatar {
    display: none;
}

/* Overrides for the attachment body tiles */

.mx_FilePanel .mx_EventTile:not([data-layout=bubble]) {
    word-break: break-word;
    margin-top: 10px;
    padding-top: 0;

    .mx_EventTile_line {
        padding-left: 0;
    }
}

.mx_FilePanel .mx_EventTile .mx_MImageBody {
    margin-right: 0px;
}

.mx_FilePanel .mx_EventTile .mx_MFileBody {
    line-height: 2.4rem;
}

.mx_FilePanel .mx_EventTile .mx_MFileBody_download {
    padding-top: 8px;
    display: flex;
    font-size: $font-14px;
    color: $event-timestamp-color;
}

.mx_FilePanel .mx_EventTile .mx_MFileBody_downloadLink {
    flex: 1 1 auto;
    color: $light-fg-color;
}

.mx_FilePanel .mx_EventTile .mx_MImageBody_size {
    flex: 1 0 0;
    font-size: $font-14px;
    text-align: right;
    white-space: nowrap;
}

/* Overides for the sender details line */

.mx_FilePanel .mx_EventTile_senderDetails {
    display: flex;
    margin-top: -2px;
}

.mx_FilePanel .mx_EventTile_senderDetailsLink {
    text-decoration: none;
}

.mx_FilePanel .mx_EventTile .mx_SenderProfile {
    flex: 1 1 auto;
    line-height: initial;
    padding: 0px;
    font-size: $font-14px;
    opacity: 1.0;
    color: $event-timestamp-color;
}

.mx_FilePanel .mx_EventTile .mx_MessageTimestamp {
    flex: 1 0 0;
    text-align: right;
    visibility: visible;
    position: initial;
    font-size: $font-14px;
    opacity: 1.0;
    color: $event-timestamp-color;
}

/* Overrides for the wrappers around the body tile */

.mx_FilePanel .mx_EventTile_line {
    margin-right: 0px;
    padding-left: 0px;
}

.mx_FilePanel .mx_EventTile_selected .mx_EventTile_line {
    padding-left: 0px;
}

.mx_FilePanel_empty::before {
    mask-image: url('$(res)/img/element-icons/room/files.svg');
}
