@import "../../../css/_common.scss";
@import "../../../css/_spacing.scss";
@import "../../../css/_font-sizes.scss";
@import "../../../css/structures/_AutoHideScrollbar.scss";
@import "../../../css/structures/_CompatibilityPage.scss";
@import "../../../css/structures/_ContextualMenu.scss";
@import "../../../css/structures/_CreateRoom.scss";
@import "../../../css/structures/_CustomRoomTagPanel.scss";
@import "../../../css/structures/_FilePanel.scss";
@import "../../../css/structures/_GenericErrorPage.scss";
@import "../../../css/structures/_HeaderButtons.scss";
@import "../../../css/structures/_HomePage.scss";
/* @import "../../../css/structures/_LeftPanel.scss"; */
/* @import "../../../css/structures/_LeftPanel2.scss"; */
@import "../../../css/structures/_MainSplit.scss";
@import "../../../css/structures/_MatrixChat.scss";
@import "../../../css/structures/_NonUrgentToastContainer.scss";
@import "../../../css/structures/_RightPanel.scss";
@import "../../../css/structures/_RoomDirectory.scss";
// @import "../../../css/structures/_RoomSearch.scss";
@import "../../../css/structures/_RoomStatusBar.scss";
/* @import "../../../css/structures/_RoomSubList.scss"; */
// @import "../../../css/structures/_RoomSubList.scss";
@import "../../../css/structures/_RoomView.scss";
// @import "../../../css/structures/beeper/_RoomToast.scss";
@import "../../../css/structures/_ScrollPanel.scss";
@import "../../../css/structures/_SearchBox.scss";
@import "../../../css/structures/_SpaceHierarchy.scss";
@import "../../../css/structures/_SpacePanel.scss";
@import "../../../css/structures/_SpaceRoomView.scss";
@import "../../../css/structures/_TabbedView.scss";
@import "../../../css/structures/_GroupFilterPanel.scss";
@import "../../../css/structures/_ToastContainer.scss";
@import "../../../css/structures/_UserMenu.scss";
@import "../../../css/structures/_ViewSource.scss";
@import "../../../css/structures/auth/_CompleteSecurity.scss";
@import "../../../css/structures/auth/_Login.scss";
@import "../../../css/structures/auth/_BeeperRegistration.scss";
@import "../../../css/views/audio_messages/_AudioPlayer.scss";
@import "../../../css/views/audio_messages/_PlayPauseButton.scss";
@import "../../../css/views/audio_messages/_PlaybackContainer.scss";
@import "../../../css/views/audio_messages/_SeekBar.scss";
@import "../../../css/views/audio_messages/_Waveform.scss";
@import "../../../css/views/auth/_AuthBody.scss";
@import "../../../css/views/auth/_AuthButtons.scss";
@import "../../../css/views/auth/_AuthFooter.scss";
@import "../../../css/views/auth/_AuthHeader.scss";
@import "../../../css/views/auth/_AuthHeaderLogo.scss";
@import "../../../css/views/auth/_AuthPage.scss";
@import "../../../css/views/auth/_CompleteSecurityBody.scss";
@import "../../../css/views/auth/_CountryDropdown.scss";
@import "../../../css/views/auth/_InteractiveAuthEntryComponents.scss";
@import "../../../css/views/auth/_LanguageSelector.scss";
@import "../../../css/views/auth/_PassphraseField.scss";
@import "../../../css/views/auth/_Welcome.scss";
@import "../../../css/views/avatars/_BaseAvatar.scss";
@import "../../../css/views/avatars/_DecoratedRoomAvatar.scss";
@import "../../../css/views/avatars/_WidgetAvatar.scss";
@import "../../../css/views/context_menus/_CallContextMenu.scss";
@import "../../../css/views/context_menus/_IconizedContextMenu.scss";
@import "../../../css/views/context_menus/_MessageContextMenu.scss";
@import "../../../css/views/context_menus/_TagTileContextMenu.scss";
@import "../../../css/views/dialogs/_AddExistingToSpaceDialog.scss";
@import "../../../css/views/dialogs/_Analytics.scss";
@import "../../../css/views/dialogs/_ChangelogDialog.scss";
@import "../../../css/views/dialogs/_ChatCreateOrReuseChatDialog.scss";
@import "../../../css/views/dialogs/_ConfirmUserActionDialog.scss";
@import "../../../css/views/dialogs/_CreateRoomDialog.scss";
@import "../../../css/views/dialogs/_DeactivateAccountDialog.scss";
@import "../../../css/views/dialogs/_DevtoolsDialog.scss";
@import "../../../css/views/dialogs/_IncomingSasDialog.scss";
@import "../../../css/views/dialogs/_InviteDialog.scss";
@import "../../../css/views/dialogs/_KeyboardShortcutsDialog.scss";
@import "../../../css/views/dialogs/_MessageEditHistoryDialog.scss";
@import "../../../css/views/dialogs/_ModalWidgetDialog.scss";
@import "../../../css/views/dialogs/_NewSessionReviewDialog.scss";
@import "../../../css/views/dialogs/_RegistrationEmailPromptDialog.scss";
@import "../../../css/views/dialogs/_RoomSettingsDialog.scss";
@import "../../../css/views/dialogs/_RoomSettingsDialogBridges.scss";
@import "../../../css/views/dialogs/_RoomUpgradeDialog.scss";
@import "../../../css/views/dialogs/_RoomUpgradeWarningDialog.scss";
@import "../../../css/views/dialogs/_ServerOfflineDialog.scss";
@import "../../../css/views/dialogs/_ServerPickerDialog.scss";
@import "../../../css/views/dialogs/_SetEmailDialog.scss";
@import "../../../css/views/dialogs/_SettingsDialog.scss";
@import "../../../css/views/dialogs/_ShareDialog.scss";
@import "../../../css/views/dialogs/_SlashCommandHelpDialog.scss";
@import "../../../css/views/dialogs/_TabbedIntegrationManagerDialog.scss";
@import "../../../css/views/dialogs/_TermsDialog.scss";
@import "../../../css/views/dialogs/_UploadConfirmDialog.scss";
@import "../../../css/views/dialogs/_UserSettingsDialog.scss";
@import "../../../css/views/dialogs/_WidgetCapabilitiesPromptDialog.scss";
@import "../../../css/views/dialogs/_WidgetOpenIDPermissionsDialog.scss";
@import "../../../css/views/dialogs/security/_AccessSecretStorageDialog.scss";
@import "../../../css/views/dialogs/security/_CreateCrossSigningDialog.scss";
@import "../../../css/views/dialogs/security/_CreateKeyBackupDialog.scss";
@import "../../../css/views/dialogs/security/_CreateSecretStorageDialog.scss";
@import "../../../css/views/dialogs/security/_KeyBackupFailedDialog.scss";
@import "../../../css/views/dialogs/security/_RestoreKeyBackupDialog.scss";
@import "../../../css/views/directory/_NetworkDropdown.scss";
@import "../../../css/views/elements/_AccessibleButton.scss";
@import "../../../css/views/elements/_AddressSelector.scss";
@import "../../../css/views/elements/_AddressTile.scss";
@import "../../../css/views/elements/_AppControls.scss";
@import "../../../css/views/elements/_DesktopBuildsNotice.scss";
@import "../../../css/views/elements/_DesktopCapturerSourcePicker.scss";
@import "../../../css/views/elements/_DirectorySearchBox.scss";
@import "../../../css/views/elements/_Dropdown.scss";
@import "../../../css/views/elements/_EditableItemList.scss";
@import "../../../css/views/elements/_ErrorBoundary.scss";
@import "../../../css/views/elements/_EventListSummary.scss";
@import "../../../css/views/elements/_Field.scss";
@import "../../../css/views/elements/_InlineSpinner.scss";
@import "../../../css/views/elements/_InteractiveTooltip.scss";
@import "../../../css/views/elements/_ManageIntegsButton.scss";
@import "../../../css/views/elements/_MiniAvatarUploader.scss";
@import "../../../css/views/elements/_PowerSelector.scss";
@import "../../../css/views/elements/_ProgressBar.scss";
@import "../../../css/views/elements/_QRCode.scss";
@import "../../../css/views/elements/_ReplyChain.scss";
@import "../../../css/views/elements/_ResizeHandle.scss";
@import "../../../css/views/elements/_RichText.scss";
@import "../../../css/views/elements/_RoleButton.scss";
@import "../../../css/views/elements/_RoomAliasField.scss";
@import "../../../css/views/elements/_SSOButtons.scss";
@import "../../../css/views/elements/_ServerPicker.scss";
@import "../../../css/views/elements/_Slider.scss";
@import "../../../css/views/elements/_Spinner.scss";
@import "../../../css/views/elements/_StyledCheckbox.scss";
@import "../../../css/views/elements/_StyledRadioButton.scss";
@import "../../../css/views/elements/_SyntaxHighlight.scss";
@import "../../../css/views/elements/_TextWithTooltip.scss";
@import "../../../css/views/elements/_ToggleSwitch.scss";
@import "../../../css/views/elements/_Tooltip.scss";
@import "../../../css/views/elements/_TooltipButton.scss";
@import "../../../css/views/elements/_Validation.scss";
@import "../../../css/views/emojipicker/_EmojiPicker.scss";
@import "../../../css/views/gifpicker/_GifPicker.scss";
@import "../../../css/views/messages/_CreateEvent.scss";
@import "../../../css/views/messages/_DateSeparator.scss";
@import "../../../css/views/messages/_EventTileBubble.scss";
@import "../../../css/views/messages/_MEmoteBody.scss";
@import "../../../css/views/messages/_MFileBody.scss";
@import "../../../css/views/messages/_MImageBody.scss";
@import "../../../css/views/messages/_MJitsiWidgetEvent.scss";
@import "../../../css/views/messages/_MNoticeBody.scss";
/* @import "../../../css/views/messages/_MStickerBody.scss"; */
@import "../../../css/views/messages/_MTextBody.scss";
@import "../../../css/views/messages/_MVideoBody.scss";
@import "../../../css/views/messages/_MessageActionBar.scss";
@import "../../../css/views/messages/_MessageTimestamp.scss";
@import "../../../css/views/messages/_MjolnirBody.scss";
@import "../../../css/views/messages/_ReactionsRow.scss";
@import "../../../css/views/messages/_ReactionsRowButton.scss";
@import "../../../css/views/messages/_RedactedBody.scss";
@import "../../../css/views/messages/_RoomAvatarEvent.scss";
@import "../../../css/views/messages/_SenderProfile.scss";
@import "../../../css/views/messages/_TextualEvent.scss";
@import "../../../css/views/messages/_UnknownBody.scss";
@import "../../../css/views/messages/_ViewSourceEvent.scss";
@import "../../../css/views/messages/_common_CryptoEvent.scss";
@import "../../../css/views/right_panel/_BaseCard.scss";
@import "../../../css/views/right_panel/_EncryptionInfo.scss";
@import "../../../css/views/right_panel/_RoomSummaryCard.scss";
@import "../../../css/views/right_panel/_UserInfo.scss";
@import "../../../css/views/right_panel/_VerificationPanel.scss";
@import "../../../css/views/right_panel/_WidgetCard.scss";
@import "../../../css/views/room_settings/_AliasSettings.scss";
@import "../../../css/views/rooms/_AppsDrawer.scss";
@import "../../../css/views/rooms/_Autocomplete.scss";
@import "../../../css/views/rooms/_AuxPanel.scss";
@import "../../../css/views/rooms/_BasicMessageComposer.scss";
@import "../../../css/views/rooms/_RoomBanner.scss";
@import "../../../css/views/rooms/_E2EIcon.scss";
@import "../../../css/views/rooms/_EditMessageComposer.scss";
@import "../../../css/views/rooms/_EntityTile.scss";
// @import "../../../css/views/rooms/_EventTile.scss";
@import "../../../css/views/rooms/_GroupLayout.scss";
@import "../../../css/views/rooms/_IRCLayout.scss";
@import "../../../css/views/rooms/_JumpToBottomButton.scss";
@import "../../../css/views/rooms/_BeeperLinkPreview.scss";
@import "../../../css/views/rooms/_LinkPreviewWidget.scss";
@import "../../../css/views/rooms/_MemberInfo.scss";
@import "../../../css/views/rooms/_MemberList.scss";
@import "../../../css/views/rooms/_MessageComposer.scss";
@import "../../../css/views/rooms/_MessageComposerFormatBar.scss";
@import "../../../css/views/rooms/_NewRoomIntro.scss";
@import "../../../css/views/rooms/_NotificationBadge.scss";
@import "../../../css/views/rooms/_PinnedEventTile.scss";
@import "../../../css/views/rooms/_Polls.scss";
@import "../../../css/views/rooms/_ReplyTile.scss";
/* @import "../../../css/views/rooms/_ReplyPreview.scss"; */
/* @import "../../../css/views/rooms/_RoomBreadcrumbs.scss"; */
/* @import "../../../css/views/rooms/_RoomBreadcrumbs2.scss"; */
/* @import "../../../css/views/rooms/_RoomDropTarget.scss"; */
@import "../../../css/views/rooms/_RoomHeader.scss";
/* @import "../../../css/views/rooms/_RoomList.scss"; */
/* @import "../../../css/views/rooms/_RoomList2.scss"; */
@import "../../../css/views/rooms/_RoomPreviewBar.scss";
/* @import "../../../css/views/rooms/_RoomSublist2.scss"; */
/* @import "../../../css/views/rooms/_RoomTile.scss"; */
/* @import "../../../css/views/rooms/_RoomTile2.scss"; */
@import "../../../css/views/rooms/_RoomUpgradeWarningBar.scss";
@import "../../../css/views/rooms/_SearchBar.scss";
@import "../../../css/views/rooms/_SendMessageComposer.scss";
@import "../../../css/views/rooms/_Stickers.scss";
/* @import "../../../css/views/rooms/_UserOnlineDot.scss"; */
@import "../../../css/views/rooms/_VoiceRecordComposerTile.scss";
@import "../../../css/views/rooms/_TopUnreadMessagesBar.scss";
@import "../../../css/views/rooms/_WhoIsTypingTile.scss";
@import "../../../css/views/settings/_AvatarSetting.scss";
@import "../../../css/views/settings/_CrossSigningPanel.scss";
@import "../../../css/views/settings/_DevicesPanel.scss";
@import "../../../css/views/settings/_E2eAdvancedPanel.scss";
@import "../../../css/views/settings/_EmailAddresses.scss";
@import "../../../css/views/settings/_IntegrationManager.scss";
@import "../../../css/views/settings/_Notifications.scss";
@import "../../../css/views/settings/_PhoneNumbers.scss";
@import "../../../css/views/settings/_ProfileSettings.scss";
@import "../../../css/views/settings/_SecureBackupPanel.scss";
@import "../../../css/views/settings/_SetIdServer.scss";
@import "../../../css/views/settings/_SetIntegrationManager.scss";
@import "../../../css/views/settings/_UpdateCheckButton.scss";
@import "../../../css/views/settings/tabs/_SettingsTab.scss";
@import "../../../css/views/settings/tabs/room/_GeneralRoomSettingsTab.scss";
@import "../../../css/views/settings/tabs/room/_RolesRoomSettingsTab.scss";
@import "../../../css/views/settings/tabs/room/_SecurityRoomSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_AppearanceUserSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_GeneralUserSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_HelpUserSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_MjolnirUserSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_NotificationUserSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_PreferencesUserSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_SecurityUserSettingsTab.scss";
@import "../../../css/views/settings/tabs/user/_VoiceUserSettingsTab.scss";
@import "../../../css/views/spaces/_SpaceBasicSettings.scss";
@import "../../../css/views/spaces/_SpaceChildrenPicker.scss";
@import "../../../css/views/spaces/_SpaceCreateMenu.scss";
@import "../../../css/views/spaces/_SpacePublicShare.scss";
@import "../../../css/views/terms/_InlineTermsAgreement.scss";
@import "../../../css/views/toasts/_AnalyticsToast.scss";
@import "../../../css/views/toasts/_NonUrgentEchoFailureToast.scss";
@import "../../../css/views/verification/_VerificationShowSas.scss";
@import "../../../css/views/voip/CallView/_CallViewButtons.scss";
@import "../../../css/views/voip/_CallContainer.scss";
@import "../../../css/views/voip/_CallPreview.scss";
@import "../../../css/views/voip/_CallView.scss";
@import "../../../css/views/voip/_CallViewForRoom.scss";
@import "../../../css/views/voip/_CallViewHeader.scss";
@import "../../../css/views/voip/_CallViewSidebar.scss";
@import "../../../css/views/voip/_DialPad.scss";
@import "../../../css/views/voip/_DialPadContextMenu.scss";
@import "../../../css/views/voip/_DialPadModal.scss";

@import "../../../css/views/voip/_VideoFeed.scss";

/* Beeper changed */
@import "./_common.scss";
@import "./views/messages/_DateSeparator.scss";
@import "./views/messages/_MEmoteBody.scss";
@import "./views/messages/_MFileBody.scss";
@import "./views/messages/_MImageBody.scss";
@import "./views/messages/_MNoticeBody.scss";
@import "./views/messages/_MStickerBody.scss";
@import "./views/messages/_MTextBody.scss";
@import "./views/messages/_MessageActionBar.scss";
@import "./views/messages/_MessageTimestamp.scss";
@import "./views/messages/_MjolnirBody.scss";
@import "./views/messages/_ReactionsRow.scss";
@import "./views/messages/_ReactionsRowButton.scss";
@import "./views/messages/_ReactionsRowButtonTooltip.scss";
@import "./views/messages/_RoomAvatarEvent.scss";
@import "./views/messages/_SenderProfile.scss";
@import "./views/messages/_TextualEvent.scss";
@import "./views/messages/_UnknownBody.scss";
@import "./views/messages/_ViewSourceEvent.scss";
@import "./views/messages/_common_CryptoEvent.scss";
@import "./views/newchat/_NewChat.scss";
@import "./views/elements/_EventListSummary.scss";
@import "./views/rooms/_BridgedIcon.scss";
@import "./views/rooms/_EventTile.scss";
@import "./views/rooms/_JumpToBottomButton.scss";
@import "./views/rooms/_TopUnreadMessagesBar.scss";
@import "./views/rooms/_ReplyPreview.scss";
@import "./views/rooms/_MessageComposer.scss";
@import "./views/rooms/_RoomTile.scss";
@import "./views/rooms/_RoomTileGroup.scss";
@import "./views/rooms/_RoomList.scss";
@import "./structures/_LeftPanel.scss";
@import "./structures/_RoomHeader.scss";
@import "./structures/_RoomView.scss";
@import "./structures/_RoomSubList.scss";
@import "./structures/_ContextualMenu.scss";
@import "./views/context_menus/_MessageContextMenu.scss";
@import "./views/context_menus/_ChatSectionContextMenu.scss";
@import "./views/verification/_VerificationShowSas.scss";
/* new room list */
@import "./structures/_LeftPanel2.scss";
@import "./structures/_ToastContainer.scss";
// @import "./structures/_RoomSearch.scss";
@import "./structures/_UserMenu.scss";
@import "./views/rooms/_RoomSublist2.scss";
@import "./views/rooms/_RoomList2.scss";
@import "./views/rooms/_RoomTile2.scss";
@import "./views/rooms/_NotificationBadge.scss";

// Beekit (lib level)
@import "../../../css/beekit/_Button.scss";
@import "../../../css/beekit/_Spinner.scss";
@import "../../../css/beekit/_Divider.scss";
@import "../../../css/beekit/_TextInput.scss";
@import "../../../css/beekit/_Icon.scss";
@import "../../../css/beekit/_Card.scss";
@import "../../../css/beekit/_Checkbox.scss";
@import "../../../css/beekit/_Select.scss";

// App-level
@import '../../../css/beeper/_LeftPanel.scss';
@import '../../../css/beeper/_RoomTile.scss';
@import '../../../css/beeper/_Tooltip.scss';
@import '../../../css/beeper/_LoggedInView.scss';

@import '../../../css/structures/beeper/_RoomToast.scss';
@import '../../../css/beekit/_Wizard.scss';
@import '../../../css/views/dialogs/_ChatNetworksDialogV2.scss';
