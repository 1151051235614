/*
Copyright 2020 Tulir Asokan <tulir@maunium.net>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_ReplyTile {
    position: relative;
    padding: 2px 0;
    font-size: $font-14px;
    line-height: $font-16px;

    &.mx_ReplyTile_audio .mx_MFileBody_info_icon::before {
        mask-image: url("$(res)/img/element-icons/speaker.svg");
    }

    &.mx_ReplyTile_video .mx_MFileBody_info_icon::before {
        mask-image: url("$(res)/img/element-icons/call/video-call.svg");
    }

    .mx_MFileBody {
        .mx_MFileBody_info {
            margin: 5px 0;
        }

        .mx_MFileBody_download {
            display: none;
        }
    }

    > a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: $primary-content;
    }

    .mx_RedactedBody {
        padding: 4px 0 2px 20px;

        &::before {
            height: 13px;
            width: 13px;
            top: 5px;
        }
    }

    // We do reply size limiting with CSS to avoid duplicating the TextualBody component.
    .mx_EventTile_content {
        $reply-lines: 2;
        $line-height: $font-22px;

        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $reply-lines;
        line-height: $line-height;

        .mx_EventTile_body.mx_EventTile_bigEmoji {
            line-height: $line-height !important;
            font-size: $font-14px !important; // Override the big emoji override
        }

        // Hide line numbers
        .mx_EventTile_lineNumbers {
            display: none;
        }

        // Hack to cut content in <pre> tags too
        .mx_EventTile_pre_container > pre {
            overflow-x: scroll;
            overflow-y: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: $reply-lines;
            padding: 4px;
        }

        .markdown-body blockquote,
        .markdown-body dl,
        .markdown-body ol,
        .markdown-body p,
        .markdown-body pre,
        .markdown-body table,
        .markdown-body ul {
            margin-bottom: 4px;
        }

        .markdown-body p {
            line-height: 2.2rem;
        }
    }

    &.mx_ReplyTile_info {
        padding-top: 0;
    }

    .mx_SenderProfile {
        font-size: $font-14px;
        line-height: $font-17px;

        display: inline-block; // anti-zalgo, with overflow hidden
        padding: 0;
        margin: 0;

        // truncate long display names
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
