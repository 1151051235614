.mx_ReplyPreview {
    box-sizing: border-box;
    position: relative;
    height: 49px;
    padding-right: 59px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    background: $primary-bg-color;
}

.mx_ReplyPreview_wrapper {
    padding-left: 49px;
    height: 49px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    cursor: default;
    &::before {
        content: url("$(res)/themes/nova-light/img/icon-reply.svg");
        position: absolute;
        left: 12px;
        top: 15px;
    }
    .mx_SenderProfile {
        margin-bottom: 3px;
        &_name {
            font-size: 13px;
            color: $accent-color;
        }
    }
}

.mx_ReplyPreview_thumbnail {
    height: 36px;
    width: 36px;
    flex-shrink: 0;
    margin-right: 7px;
    overflow: hidden;
    border-radius: 2px;
    & > img {
        object-fit: cover;
    }
}
.mx_ReplyPreview_content {
    width: 100%;
}

.mx_ReplyPreview_contentText {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}

.mx_ReplyPreview_cancel {
    position: absolute;
    right: 18px;
    top: 12px;
    cursor: default;
    z-index: 2;
    img {
        padding: 5px;
    }
}

.bp_ReplyPreview_avatar {
    margin-right: 16px;
}

.bp_ReplyPreview_inThread {
    .mx_ReplyPreview_wrapper {
        height: auto;
        padding-left: 0;
        &::before {
            display: none;
        }
    }
}