.bp_Poll {
    color: $primary-content;
}

.bp_PollTitle {
    font-weight: bold;
    font-size: 16px;
}

.bp_PollAnswer {
    margin:  16px 0 8px;

    .bp_Select {
        border: 1px solid #ffffff50;
        min-width: 100px;

        &.selected {
            border-color: #fff;
            background-color: #ffffff30;
        }
    }

    .bp_Select_wrapper {
      .checkmark {
          border-color: #ffffff50;
      }
      input:checked ~ .checkmark {
          border-color: #fff;
      }
    }
}

.bp_PollCreation {
  padding: 12px 18px;
}