:where(.bp_RoomToast_wrapper) {
  position: absolute;
  bottom: 18px;
  left: 15px;
  right: 12px;

  opacity: 0;

  transition: opacity .5s;

  &.shown {
    opacity: 1;
  }
}

.bp_RoomToast {
  background-color: $left-panel-v2-bg;
  border-radius: 8px;
  padding: 12px;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.error {
    border: 2px solid $room-toast-border;
    background-color: $room-toast-bg;
  }
}

.bp_RoomListToast_wrapper {
  height: fit-content;
  max-height: 0;

  &.shown {
    height: fit-content;
    max-height: 500px;
    transition: max-height .5s ease-in;
  }
}

.bp_RoomListToast {
  background-color: $favourites-bg-color;
  border: 1px solid $fav-icon-color;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 8px;

  &.error {
    border: 2px solid $room-toast-border;
    background-color: $room-toast-bg;
  }
}