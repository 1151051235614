.bp_Checkbox {
    input {
        display: none;
    }
  
    .checkmark {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 2px solid $bp-input-border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
  
      .bp_icon {
          display: none;
      }
    }
  
    input:checked ~ .checkmark {
      border-color: $azure-500;
  
      .bp_icon {
        display: block;
      }
    }

    label {
        // background-color: $primary-bg-color;
        border: 0.25px solid $roomtile-hover-bg-color;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        max-width: 400px;
    
        &.selected {
            border-color: $azure-500;
            // background-color: $azure-700;
            // background-blend-mode: lighten;
        }
    }
  }
  