/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

// CSS inspiration from:
// * https://www.w3schools.com/howto/howto_js_rangeslider.asp
// * https://stackoverflow.com/a/28283806
// * https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

.mx_MediaBody.mx_VoiceMessagePrimaryContainer .mx_RecordingPlayback_timelineLayoutMiddle .mx_SeekBar {
    background: none;
    height: 30px;
    left: 0;
    position: absolute;
    top: -2px;
    opacity: 0;
}

.mx_SeekBar {
    // Dev note: we deliberately do not have the -ms-track (and friends) selectors because we don't
    // need to support IE.
    background: $quaternary-content;
    height: 1px;
    position: relative;
    appearance: none; // default style override
    width: 100%;
    outline: none; // remove blue selection border
    cursor: default;

    &::-webkit-slider-thumb {
        appearance: none; // default style override

        // Dev note: This needs to be duplicated with the -moz-range-thumb selector
        // because otherwise Edge (webkit) will fail to see the styles and just refuse
        // to apply them.
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: $tertiary-content;
        cursor: default;
    }

    &::-moz-range-thumb {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: $tertiary-content;
        cursor: default;

        // Firefox adds a border on the thumb
        border: none;
    }

    // This is for webkit support, but we can't limit the functionality of it to just webkit
    // browsers. Firefox responds to webkit-prefixed values now, which means we can't use media
    // or support queries to selectively apply the rule. An upside is that this CSS doesn't work
    // in firefox, so it's just wasted CPU/GPU time.
    &::before { // ::before to ensure it ends up under the thumb
        background-color: none;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        transform: scaleX(var(--fillTo));
        transform-origin: 0 100%;
        width: 100%;
    }

    // This is firefox's built-in support for the above, with 100% less hacks.
    &::-moz-range-progress {
        background-color: $tertiary-content;
        height: 1px;
    }

    &:disabled {
        opacity: 0.5;
    }

    // Increase clickable area for the slider (approximately same size as browser default)
    // We do it this way to keep the same padding and margins of the element, avoiding margin math.
    // Source: https://front-back.com/expand-clickable-areas-for-a-better-touch-experience/
    &::after {
        bottom: -6px;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: -6px;
    }
}
