@font-face {
    font-family: 'Beeps';
    src: url('$(res)/fonts/Beeper/Beeps-3_013.otf') format('opentype');
}
@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    src: url('$(res)/fonts/Sofia_Pro/Sofia-Pro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    src: url('$(res)/fonts/Sofia_Pro/Sofia-Pro-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 600;
    src: url('$(res)/fonts/Sofia_Pro/Sofia-Pro-Semibold.ttf') format('truetype');
}
@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 700;
    src: url('$(res)/fonts/Sofia_Pro/Sofia-Pro-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    src: url('$(res)/fonts/Space_Grotesk/Space-Grotesk-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('$(res)/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('$(res)/fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('$(res)/fonts/Open_Sans/OpenSans-Semibold.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('$(res)/fonts/Open_Sans/OpenSans-SemiboldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('$(res)/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('$(res)/fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    src: url('$(res)/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    src: url('$(res)/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('$(res)/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('$(res)/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('$(res)/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}
/* the 'src' links are relative to the bundle.css, which is in a subdirectory.
 */

/* Inter unexpectedly contains various codepoints which collide with emoji, even
   when variation-16 is applied to request the emoji variant.  From eyeballing
   the emoji picker, these are: 20e3, 23cf, 24c2, 25a0-25c1, 2665, 2764, 2b06, 2b1c.
   Therefore we define a unicode-range to load which excludes the glyphs
   (to avoid having to maintain a fork of Inter). */

   $inter-unicode-range: U+0000-20e2,U+20e4-23ce,U+23d0-24c1,U+24c3-259f,U+25c2-2664,U+2666-2763,U+2765-2b05,U+2b07-2b1b,U+2b1d-10FFFF;

   @font-face {
     font-family: 'Inter';
     font-style:  normal;
     font-weight: 400;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-Regular.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-Regular.woff?v=3.18") format("woff");
   }
   @font-face {
     font-family: 'Inter';
     font-style:  italic;
     font-weight: 400;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-Italic.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-Italic.woff?v=3.18") format("woff");
   }
   
   @font-face {
     font-family: 'Inter';
     font-style:  normal;
     font-weight: 500;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-Medium.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-Medium.woff?v=3.18") format("woff");
   }
   @font-face {
     font-family: 'Inter';
     font-style:  italic;
     font-weight: 500;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-MediumItalic.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-MediumItalic.woff?v=3.18") format("woff");
   }
   
   @font-face {
     font-family: 'Inter';
     font-style:  normal;
     font-weight: 600;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-SemiBold.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-SemiBold.woff?v=3.18") format("woff");
   }
   @font-face {
     font-family: 'Inter';
     font-style:  italic;
     font-weight: 600;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-SemiBoldItalic.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-SemiBoldItalic.woff?v=3.18") format("woff");
   }
   
   @font-face {
     font-family: 'Inter';
     font-style:  normal;
     font-weight: 700;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-Bold.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-Bold.woff?v=3.18") format("woff");
   }
   @font-face {
     font-family: 'Inter';
     font-style:  italic;
     font-weight: 700;
     font-display: swap;
     unicode-range: $inter-unicode-range;
     src: url("$(res)/fonts/Inter/Inter-BoldItalic.woff2?v=3.18") format("woff2"),
          url("$(res)/fonts/Inter/Inter-BoldItalic.woff?v=3.18") format("woff");
   }
