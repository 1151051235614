.mx_LeftPanel_container {
    display: flex;
    /* LeftPanel without tag panel 190px */
    min-width: 190px;
    max-width: 550px;
    flex: 0 0 auto;
}

.mx_LeftPanel_menuRow {
    display: flex;
    align-items: center;
    .mx_SearchBox {
        flex: 1 1 0;
        min-width: 0;
        height: 32px;
        margin: 0 12px 0 0;
        background-color: #FBFBFB;
        border: 2px solid #7CCEF2;
        input {
            font-size: 13px;
            font-weight: 400;
            background-image: none !important;
            padding-left: 11px;
        }
        input::placeholder {
            color: #98a2aa;
        }
    }
    .mx_SearchBox_blurred {
        border-color: #F2F3F5;
        background-color: #F2F3F5 !important;
    }
}

.mx_LeftPanel_container.collapsed {
    min-width: unset;
    /* Collapsed LeftPanel 67px */
    flex: 0 0 66px;
}

.mx_LeftPanel_container.collapsed.mx_LeftPanel_container_hasTagPanel {
    /* TagPanel 70px + Collapsed LeftPanel 67px */
    flex: 0 0 136px;
    min-width: 260px;
}

.mx_LeftPanel_tagPanelContainer {
    flex: 0 0 70px;
    height: 100%;
}

.mx_LeftPanel_hideButton {
    position: absolute;
    top: 10px;
    right: 0px;
    padding: 8px;
    cursor: default;
}

.mx_LeftPanel {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.mx_LeftPanel .mx_AppTile_mini {
    height: 132px;
}

.mx_LeftPanel .mx_RoomList_scrollbar {
    order: 1;

    flex: 1 1 0;

    overflow-y: auto;
    z-index: 6;
}

.mx_LeftPanel .mx_BottomLeftMenu {
    order: 3;

    border-top: 1px solid $panel-divider-color;
    margin-left: 16px; /* gutter */
    margin-right: 16px; /* gutter */
    flex: 0 0 60px;
    z-index: 1;
}

.mx_LeftPanel_container.collapsed .mx_BottomLeftMenu {
    flex: 0 0 160px;
    margin-bottom: 9px;
}

.mx_LeftPanel .mx_BottomLeftMenu_options {
    margin-top: 18px;
}

.mx_BottomLeftMenu_options object {
    pointer-events: none;
}

.mx_BottomLeftMenu_options > div {
    display: inline-block;
}

.mx_BottomLeftMenu_options .mx_RoleButton {
    margin-left: 0px;
    margin-right: 10px;
    height: 30px;
}

.mx_BottomLeftMenu_options .mx_BottomLeftMenu_settings {
    float: right;
}

.mx_BottomLeftMenu_options .mx_BottomLeftMenu_settings .mx_RoleButton {
    margin-right: 0px;
}

.mx_LeftPanel_container.collapsed .mx_BottomLeftMenu_settings {
    float: none;
}

.mx_MatrixChat_useCompactLayout {
    .mx_LeftPanel .mx_BottomLeftMenu {
        flex: 0 0 45px;
    }

    .mx_LeftPanel_container.collapsed .mx_BottomLeftMenu {
        flex: 0 0 160px;
    }

    .mx_LeftPanel .mx_BottomLeftMenu_options {
        margin-top: 12px;
    }
}


.mx_LeftPanel_explore {
    flex: 0 0 50%;
    overflow: hidden;
    transition: flex-basis 0.2s;
    box-sizing: border-box;

    &.mx_LeftPanel_explore_hidden {
        flex-basis: 0;
    }

    .mx_AccessibleButton {
        font-size: 14px;
        margin: 4px 0 1px 9px;
        padding: 9px;
        padding-left: 42px;
        font-weight: 600;
        color: $notice-secondary-color;
        position: relative;
        border-radius: 4px;

        &:hover {
            background-color: $primary-bg-color;
        }

        &::before {
            cursor: default;
            mask-image: url('$(res)/img/element-icons/roomlist/explore.svg');
            mask-repeat: no-repeat;
            mask-position: center center;
            content: "";
            left: 14px;
            top: 10px;
            width: 16px;
            height: 16px;
            background-color: $notice-secondary-color;
            position: absolute;
        }
    }
}
