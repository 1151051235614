/*
Copyright 2019 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_SendMessageComposer {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: $font-14px;
    justify-content: center;
    margin-right: 6px;
    // don't grow wider than available space
    min-width: 0;

    .mx_BasicMessageComposer {
        flex: 1;
        display: flex;
        flex-direction: column;
        // min-height at this level so the mx_BasicMessageComposer_input
        // still stays vertically centered when less than 55px.
        // We also set this to ensure the voice message recording widget
        // doesn't cause a jump.
        min-height: 44px;

        .mx_BasicMessageComposer_input {            
            // this will center the contenteditable
            // in it's parent vertically
            // while keeping the autocomplete at the top
            // of the composer. The parent needs to be a flex container for this to work.
            margin: auto 0;
            // max-height at this level so autocomplete doesn't get scrolled too
            max-height: 140px;
            overflow-y: auto;

            > :first-child {
                line-height: 16px;
            }
        }
    }
}
