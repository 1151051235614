// XXX: bleurgh, what is this? These classes totally break the component
// naming scheme; it's completely unclear where or how they're being used
// --Matthew

// Double specified class names to get higher specificity than gfm.css
// and some event tile overrides
.mx_Pill.mx_Pill {
    display: inline-flex;
    vertical-align: baseline;
    border-radius: 2px;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    cursor: default;
    background: $mention-pill-bg;
    padding: 2px;
    color: $mention-pill-fg;

    .mx_BaseAvatar_center, .mx_BaseAvatar {
        display: none;
    }
}

.mx_AtRoomPill.mx_AtRoomPill,
.mx_UserPill_me.mx_UserPill_me {
    color: $at-me-pill-fg;
}

.mx_UserPill.mx_UserPill {
    padding-left: 1em;

    &::before {
        content: '@' !important;
        position: absolute;
        left: 0;
    }
}

.mx_RoomPill.mx_RoomPill, .mx_GroupPill.mx_GroupPill {
    padding-left: 0.75em;

    &::before {
        content: '#' !important;
        position: absolute;
        left: 0;
    }
}

.mx_UserPill_selected {
    background-color: $accent !important;
}

.mx_Markdown_BOLD {
    font-weight: bold;
}

.mx_Markdown_ITALIC {
    font-style: italic;
    /*
    // interestingly, *not* using the explicit italic font
    // variant seems yield better results.

    // compensate for Nunito italics being terrible
    // https://github.com/google/fonts/issues/1726
    transform: skewX(-14deg);
    display: inline-block;
    */
}

.mx_Markdown_CODE {
    padding: .2em 0;
    margin: 0;
    font-size: 85%;
    background-color: $rte-code-bg-color;
    border-radius: 3px;
}

.mx_Markdown_HR {
    display: block;
    background: $rte-bg-color;
}

.mx_Markdown_STRIKETHROUGH {
    text-decoration: line-through;
}
