/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_RoomDirectory_dialogWrapper > .mx_Dialog {
    max-width: 960px;
    height: 100%;
}

.mx_RoomDirectory_dialog {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mx_RoomDirectory {
    margin-bottom: 12px;
    color: $primary-content;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.mx_RoomDirectory_list {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mx_RoomDirectory_list .mx_RoomView_messageListWrapper {
    justify-content: flex-start;
}

.mx_RoomDirectory_listheader {
    display: block;
    margin-top: 13px;
}

.mx_RoomDirectory_searchbox {
    flex: 1 !important;
}

.mx_RoomDirectory_listheader .mx_NetworkDropdown {
    flex: 0 0 200px;
}

.mx_RoomDirectory_tableWrapper {
    overflow-y: auto;
    flex: 1 1 0;

    .mx_RoomDirectory_footer {
        margin-top: 24px;
        text-align: center;

        > h5 {
            margin: 0;
            font-weight: $font-semi-bold;
            font-size: $font-15px;
            line-height: $font-18px;
            color: $primary-content;
        }

        > p {
            margin: 40px auto 60px;
            font-size: $font-14px;
            line-height: $font-20px;
            color: $secondary-content;
            max-width: 464px; // easier reading
        }

        > hr {
            margin: 0;
            border: none;
            height: 1px;
            background-color: $header-panel-bg-color;
        }

        .mx_RoomDirectory_newRoom {
            margin: 24px auto 0;
            width: max-content;
        }
    }
}

.mx_RoomDirectory_table {
    color: $primary-content;
    display: grid;
    font-size: $font-12px;
    grid-template-columns: max-content auto max-content max-content max-content;
    row-gap: 24px;
    text-align: left;
    width: 100%;
}

.mx_RoomDirectory_roomAvatar {
    padding: 2px 14px 0 0;
}

.mx_RoomDirectory_roomMemberCount {
    align-self: center;
    color: $light-fg-color;
    padding: 3px 10px 0;

    &::before {
        background-color: $light-fg-color;
        display: inline-block;
        vertical-align: text-top;
        margin-right: 2px;
        content: "";
        mask: url("$(res)/img/feather-customised/user.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        // scale it down and make the size slightly bigger (16 instead of 14px)
        // to avoid rendering artifacts
        mask-size: 80%;
        width: 16px;
        height: 16px;
    }
}

.mx_RoomDirectory_join,
.mx_RoomDirectory_preview {
    align-self: center;
    white-space: nowrap;
}

.mx_RoomDirectory_name {
    display: inline-block;
    font-size: $font-18px;
    font-weight: 600;
}

.mx_RoomDirectory_perms {
    display: inline-block;
}

.mx_RoomDirectory_perm {
    border-radius: 10px;
    display: inline-block;
    height: 20px;
    line-height: $font-20px;
    padding: 0 5px;
    color: $accent-fg-color;
    background-color: $rte-room-pill-color;
}

.mx_RoomDirectory_topic {
    cursor: initial;
    color: $light-fg-color;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.mx_RoomDirectory_alias {
    font-size: $font-12px;
    color: $settings-grey-fg-color;
}

.mx_RoomDirectory .mx_RoomView_MessageList {
    padding: 0;
}

.mx_RoomDirectory > span {
    font-size: $font-15px;
    margin-top: 0;

    .mx_AccessibleButton {
        padding: 0;
    }
}

@media screen and (max-width: 700px) {
    .mx_RoomDirectory_roomMemberCount {
        padding: 0px;
    }

    .mx_AccessibleButton_kind_secondary {
        padding: 0px !important;
    }

    .mx_RoomDirectory_join {
        margin-left: 0px;
    }

    .mx_RoomDirectory_alias {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mx_RoomDirectory_roomDescription {
        padding-bottom: 0px;
    }

    .mx_RoomDirectory_name {
        margin-bottom: 5px;
    }

    .mx_RoomDirectory_roomAvatar {
        margin-top: 10px;
    }

    .mx_RoomDirectory_table {
        grid-template-columns: auto;
        row-gap: 14px;
        margin-top: 5px;
    }
}

.mx_RoomDirectory_listItem {
    display: contents;
}
