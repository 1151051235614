.mx_RoomTile {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    cursor: default;
    margin: 0;
    height: 62px;
    padding: 8px 10px;
    position: relative;
    user-select: none;
    align-items: stretch;
    &.mx_RoomTile_menuDisplayed, &:hover {
        background: #F2F3F5;
    }
}

.mx_RoomTile * {
    box-sizing: border-box;
}

.mx_RoomTile_selected,
.mx_RoomTile_selected:hover,
.mx_RoomTile_selected.mx_RoomTile_menuDisplayed {
    background-color: $roomtile-selected-bg-color;
    color: $accent-fg-color;
}

.mx_RoomTile_unread .mx_RoomTile_name, .mx_RoomTile_highlight .mx_RoomTile_name {
    color: $roomtile-name-color;
}
//.mx_RoomTile_name.mx_RoomTile_badgeShown {
//    color: $roomtile-name-color;
//}

//.mx_RoomTile:focus {
//    filter: none !important;
//    background-color: $roomtile-focused-bg-color;
//}

//.mx_RoomTile_menuButton {
//    display: none;
//    flex: 0 0 16px;
//    height: 16px;
//    background-image: url('$(res)/img/icon_context.svg');
//    background-repeat: no-repeat;
//    background-position: center;
//}

//.mx_RoomTile_tooltip {
//    display: inline-block;
//    position: relative;
//    top: -54px;
//    left: -12px;
//}

.mx_RoomTile_avatar {
    flex: 0;
    width: 46px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.mx_RoomTile_content {
    margin-left: 10px;
    line-height: 18px;
    display: flex;
    flex: 1;
    min-width: 0;
    vertical-align: middle;
    padding: 2px 0;
}

.mx_RoomTile_labelContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-width: 0;
}

.mx_RoomTile_name {
    font-weight: 600;
    font-size: 13px;
    color: $roomtile-name-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mx_RoomTile_eventPreview {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    color: #98a2aa;
}
.mx_RoomTile_eventSender {
    color: $accent-color;
    margin-right: 4px;
}
.mx_RoomTile_eventText {
    color: #98a2aa;
}

.mx_RoomTile_statusGroup {
    text-align: right;
    flex: 0;
    min-width: 52px;
}

.mx_RoomTile_badge {
    display: inline-block;
    height: 18px;
    min-width: 18px;
    border-radius: 9px;
    text-align: center;
    font-weight: 600;
    background: $accent-color;
    color: $accent-fg-color;
    font-size: 12px;
    font-weight: 600;
    padding: 0 4px;
}

//.mx_RoomTile_badge {
//    display: inline;
//    //flex: 0 1 content;
//    border-radius: 0.8em;
//    padding: 0 0.4em;
//    color: $roomtile-badge-fg-color;
//    font-weight: 600;
//    font-size: 12px;
//}

.mx_RoomTile_badge.mx_RoomTile_badgeMuted {
    background: #98a2aa;
}

.mx_RoomTile_notifications {
    position: absolute;
    bottom: 8px;
    right: 10px;
    display: flex;
    z-index: 2;
}


.mx_RoomTile_statusGroup .mx_MessageTimestamp {
    font-size: 13px;
    color: #98a2aa;
}

.mx_RoomTile_selected .mx_RoomTile_name,
.mx_RoomTile_selected .mx_RoomTile_eventText,
.mx_RoomTile_selected .mx_RoomTile_eventSender,
.mx_RoomTile_selected .mx_MessageTimestamp {
    color: $accent-fg-color;
}

.mx_RoomTile_selected .mx_RoomTile_badge {
    color: $accent-color;
    background: #c1e2f4;
}
//.mx_RoomTile_hasSubtext .mx_RoomTile_avatar {
//    padding-top: 0;
//    vertical-align: super;
//}

.mx_RoomTile_dm {
    display: block;
    position: absolute;
    bottom: 0;
    right: -5px;
    z-index: 2;
}

// Note we match .mx_E2EIcon to make sure this matches more tightly than just
// .mx_E2EIcon on its own
.mx_RoomTile_e2eIcon.mx_E2EIcon {
    height: 14px;
    width: 14px;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 0;
}

.nv_RoomTile_bridgedIcon {
    height: 14px;
    width: 14px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 0;
}

.collapsed {
    .mx_RoomTile {
        margin: 0;
        padding: 0;
        position: relative;
        justify-content: center;
        align-items: center;
    }

    .mx_RoomTile_name {
        display: none;
    }

    .mx_RoomTile_content {
        display: none;
    }

    .mx_RoomTile_notifications {
        .mx_RoomTile_pinned {
            display: none;
        }
    }

    .mx_RoomTile_menuButton {
        display: none;  //no design for this for now
    }
}

// toggle menuButton and badge on menu displayed
.mx_RoomTile_menuDisplayed,
    // or on keyboard focus of room tile
.mx_LeftPanel_container:not(.collapsed) .mx_RoomTile:focus-within,
    // or on pointer hover
.mx_LeftPanel_container:not(.collapsed) .mx_RoomTile:hover {
    .mx_RoomTile_menuButton {
        display: block;
    }
}

//.mx_RoomTile_unreadNotify .mx_RoomTile_badge,
//.mx_RoomTile_badge.mx_RoomTile_badgeUnread {
//    background-color: $roomtile-name-color;
//}

//.mx_RoomTile_highlight .mx_RoomTile_badge,
//.mx_RoomTile_badge.mx_RoomTile_badgeRed {
//    color: $accent-fg-color;
//    background-color: $warning-color;
//}



.mx_DNDRoomTile {
    transform: none;
    transition: transform 0.2s;
}

.mx_DNDRoomTile_dragging {
    transform: scale(1.05, 1.05);
}

.mx_RoomTile_arrow {
    position: absolute;
    right: 0px;
}

.mx_RoomTile.mx_RoomTile_transparent {
    background-color: transparent;
}

.mx_RoomTile.mx_RoomTile_transparent:focus {
    background-color: $roomtile-transparent-focused-color;
}

.mx_GroupInviteTile .mx_RoomTile_name {
    flex: 1;
}

.mx_RoomTile_pinned {
    height: 18px;
    width: 18px;
    display: inline-block;
    background: #98a2aa;
    mask: url('$(res)/themes/nova-light/img/icon-dialogs-pinned.svg');
    mask-repeat: no-repeat;
    mask-position: 50% 50%;
}

.mx_RoomTile_nameGroup {
    &::before {
        display: inline-block;
        height: 13px;
        width: 18px;
        content: '';
        background: #000;
        mask: url('$(res)/themes/nova-light/img/icon-dialogs-chat.svg');
        mask-position: bottom center;
        mask-repeat: no-repeat;
        margin: auto 3px auto auto;
    }
}

.mx_RoomTile_selected {
    .mx_RoomTile_pinned {
        background: rgba(255, 255, 255, 0.7);
    }
    .mx_RoomTile_nameGroup::before {
        background: $accent-fg-color;
    }
}

.nv_RoomList_archivedBtn .mx_RoomTile_badge {
    background: #98a2aa;
}
