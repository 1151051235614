/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_MemberList,
.mx_GroupMemberList,
.mx_GroupRoomList {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    .mx_Spinner {
        flex: 1 0 auto;
    }

    .mx_SearchBox {
        margin-bottom: 5px;
    }

    h2 {
        text-transform: uppercase;
        color: $h3-color;
        font-weight: 600;
        font-size: $font-13px;
        padding-left: 3px;
        padding-right: 12px;
        margin-top: 8px;
        margin-bottom: 4px;
    }

    .mx_AutoHideScrollbar {
        flex: 1 1 0;
    }

    .mx_RightPanel_scopeHeader {
        // vertically align with position on other right panel cards
        // to prevent it bouncing as user navigates right panel
        margin-top: -8px;
    }
}

.mx_GroupMemberList_query,
.mx_GroupRoomList_query {
    flex: 0 0 auto;
}

.mx_MemberList_chevron {
    position: absolute;
    right: 35px;
    margin-top: -15px;
}

.mx_MemberList_border {
    overflow-y: auto;

    order: 1;
    flex: 1 1 0px;
}

.mx_MemberList_query {
    height: 16px;

    // stricter rule to override the one in _common.scss
    &[type="text"] {
        font-size: $font-12px;
    }
}

.mx_MemberList_wrapper {
    padding: 10px;
}

.mx_MemberList_invite {
    flex: 0 0 auto;
    position: relative;
    background-color: $accent;
    border-radius: 4px;
    margin: 5px 9px 9px;
    display: flex;
    justify-content: center;
    color: $button-fg-color;
    font-weight: 600;
}

.mx_MemberList_invite.mx_AccessibleButton_disabled {
    background-color: $info-plinth-fg-color;
    cursor: not-allowed;
}

.mx_MemberList_invite span {
    padding: 8px 0;
    display: inline-flex;

    &::before {
        content: '';
        display: inline-block;
        background-color: $button-fg-color;
        mask-image: url('$(res)/img/element-icons/room/invite.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 20px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}

.mx_MemberList_inviteCommunity span::before {
    mask-image: url('$(res)/img/icon-invite-people.svg');
}

.mx_MemberList_addRoomToCommunity span::before {
    mask-image: url('$(res)/img/icons-room-add.svg');
}
