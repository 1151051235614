@import "./_colors.scss";

.bp_TextInput {
  box-sizing: border-box;
  padding: 5px 12px !important;
  outline: none;
  border: 0px;
  color: $bp-input-textcolor;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(.bp_TextInput_bordered) {
    background-color: $bp-input-bgcolor;
    border-radius: 50px;
  }
  
  &.bp_TextInput_bordered {
    border-radius: 8px;
    border: 1px solid $space-20;

    &:focus {
      border: 1px solid $crayola-100;
    }

    &.error {
      border: 1px solid $siren-100;
    }
  }

  color-scheme: $beeper-theme;
}

/******************************************/
/******************************************/
/******************************************/

.bp_TextInput2 {
  display: flex;
  flex: 1;
  min-width: 0;
  position: relative;
  margin: 1.5em 0 1em 0;
  border-radius: 12px;
  transition: border-color 0.25s;
  border: 2px solid $input-border-color;

  background-color: $primary-bg-color;

  &.bp_TextInput2_EvenMargins {
    margin: 1.5em 0;
  }

  &.error {
      border-color: $siren-500 !important;
  }
}

.bp_TextInput2 input {
    font-family: inherit;
    font-weight: normal;
    font-size: $font-14px;
    border: none;
    // Even without a border here, we still need this avoid overlapping the rounded
    // corners on the field above.
    border-radius: 12px;
    padding: 10px 16px;
    color: $primary-content;
    flex: 1;
    min-width: 0;
}

.bp_TextInput2:focus-within :not(.error) {
  border-color: $accent-alt;
}

.bp_TextInput2 input:focus {
    outline: 0;
}

.bp_TextInput2 input::placeholder {
    transition: color 0.25s ease-in 0s;
    color: transparent;
}

.mx_Field input:placeholder-shown:focus::placeholder {
    transition: color 0.25s ease-in 0.1s;
    color: $info-plinth-fg-color;
}

.bp_TextInput2 label {
  transition:
      font-size 0.25s ease-out 0.1s,
      color 0.25s ease-out 0.1s,
      opacity 0.25s ease-out 0.1s,
      top 0.25s ease-out 0.1s,
      margin-left 0.25s ease-out 0.1s,
      transform 0.25s ease-out 0.1s;
  color: $primary-content;
  opacity: .6;
  background-color: transparent;
  font-size: $font-14px;
  transform: translateY(0);
  position: absolute;
  left: 0px;
  margin: 7px 0 0 16px;
  padding: 2px;
  pointer-events: none; // Allow clicks to fall through to the input
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px); // 100% of parent minus margin and padding
}

.bp_TextInput2_labelAlwaysTopLeft label,
.bp_TextInput2 input:focus + label,
.bp_TextInput2 input:not(:placeholder-shown) + label {
    font-size: $font-10px;
    transform: translateY(-26px);
    pointer-events: initial;
}

.bp_TextInput2 input:focus + label {
    opacity: 1;
}

.bp_TextInput2 input:disabled,
.bp_TextInput2 input:disabled + label {
    color: $info-plinth-fg-color;
    opacity: .5;
}