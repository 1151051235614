.bp_RoomTile {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: default;
    margin: 2pt 0;
    padding: 0;
    height: 64px;
    box-sizing: border-box;

    .bp_Icon {
        margin-top: 0;
    }

    .flex {
        display: flex;
    }

    .gap-5 {
        gap: 5px;
    }

    .favourite-avatar {
        .mx_BaseAvatar_image {
            clip-path: url(#cutout);
        }
    }

    .favourite-indicator {
        position: absolute;
        top: 34px;
        right: 10px;
        z-index: 5;
        height: 13px;
        width: 13px;
        display: flex;
        justify-content: center;
        align-items: center;

        .bp_icon {
            height: 20px;
        }
    } 

    .details .row.info {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        background-color: transparent;
    }

    .outline {
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 7px 8px 7px 2px;
        transition: background 0.16s ease-in-out;
        position: relative;
    }

    .avatar {
        margin-right: 4px;
    }

    .details {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-left: 0;
        justify-content: flex-start;
        padding-top: 0;

        .row {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            flex-direction: column;

            &.block {
                display: block;
            }

            > * {
                line-height: 1.17; // ~14px at font-size: 12px
            }

            &:last-child {
                margin-bottom: 0;
                flex: 1;
                align-items: flex-start;
                padding-bottom: 0;
            }
        }
    }

    .name {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 2px 2px 0px;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        color: $room-tile-fg;
        display: flex;
        flex-direction: row;
    }

    .bridgeIcon_container {
        order: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        align-self: flex-start;
    }

    .active-indicator {
        color: #7e7f84;
        &.pin {
            transform: rotate(35deg);
        }
    }

    .bridgeIcon {
        min-width: 19px;
        margin-right: 0;
        mask-size: 19px;
        height: 15px;
    }

    .timestamp {
        align-self: flex-start;
        padding-top: 2px;
        font-weight: 500;
        white-space: nowrap;
        margin-left: 8px;
        padding-right: 2px;
        min-width: 26px;
        text-align: end;
        color: $tile-preview-color;
        font-size: 10px;
    }

    .preview {
        color: $room-tile-fg-secondary;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: -.25px;
        top: -2px;
        position: relative;

        .mss {
            .bp_icon {
                display: inline-block;
            }
        }

        > span {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: -webkit-box;
            overflow: hidden;
            white-space: revert;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: $tile-preview-color;
            word-break: break-word;
            font-size: 12.5px;
        }
    }

    &:not(.small) {
        margin-bottom: 2px;
        padding: 0 17px 0 10px;

        &.active {
            .outline {
                background-color: rgba(var(--base), 0.12);
            }
        }

        .outline {
            flex: 1;
            min-width: 0;
            display: grid;
            height: 100%;
            margin-right: 0px;
            grid-template-columns: 10px 58px 1fr auto;
            margin-left: 0;
            overflow: hidden;
            padding-bottom: 0;
            border-radius: 10px;
            padding: 6px 17px 6px 0;
            box-sizing: border-box;    
            padding-right: 4px;
            transition: revert;
            > div {
                width: 100%;
                height: 100%;
            }
            .mx_BaseAvatar,
            .mx_BaseAvatar_image {
                width: 48px;
                height: 48px;
                line-height: 48px;
                margin-right: 0;
                .mx_BaseAvatar_initial {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &.isInvite {
            .outline {
                border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;
                margin-right: 0px;
            }
            .preview {
                display: flex;
            }
            .bridgeIcon {
                align-self: flex-end;
            }
        }
    }
}