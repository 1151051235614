.nv_RoomSublist2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    padding-bottom: 15px;
    user-select: none;
    &_collapsed, &_empty {
        padding-bottom: 0;
    }
    &_collapsed:last-child .nv_RoomSublist2_headerContainer {
        margin-bottom: 0;
    }
}

.nv_RoomSublist2_headerContainer {
    height: 37px;
    .nv_RoomSublist2_sticky {
        background: #ebecee;
        border-top: 1px solid $room-list-border-color;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 43px 8px 26px;
        z-index: 2;
        position: relative;
        height: 37px;
        &_active {
            position: absolute;
        }
        &_active.nv_RoomSublist2_sticky_showScroll {
            padding-right: 41px;
            margin-right: 4px;
            width: calc(100% - 4px);
            .nv_RoomSublist2_menuButton {
                right: 12px;
            }
        }
    }
    .nv_RoomSublist2_badgeContainer {
        position: absolute;
        top: 11px;
        left: 7px;
    }
    .nv_RoomSublist2_LabelContainer {
        display: flex;
        width: 100%;
    }
    .nv_RoomSublist2_Label {
        flex-shrink: 0;
        margin-right: 9px;
        font-weight: 600;
        color: #000000;
    }

    .nv_RoomSublist2_menuButton {
        position: absolute;
        top: 6px;
        right: 16px;
        height: 27px;
        width: 27px;
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            mask-image: url('$(res)/themes/nova-light/img/icon-more.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center;
            background-color: #999999;
        }
    }
}

.nv_RoomSublist2:first-child .nv_RoomSublist2_sticky {
    border-top: none;
}

.nv_RoomSublist2_sticky {
    .nv_RoomSublist2_restRoomsSection, .nv_RoomSublist2_badgeContainer {
        visibility: hidden;
    }
}

.nv_RoomSublist2_collapsed, .nv_RoomSublist2_sticky_active {
    .nv_RoomSublist2_restRoomsSection, .nv_RoomSublist2_badgeContainer {
        visibility: visible;
    }
}

.nv_RoomSublist2_restRoomsSection {
    min-width: 0;
    width: 100%;
    //height: 18px;
    color: #000000;
    padding-right: 15px;
    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.nv_RoomSublist2_LabelContainer .nv_RoomSublist2_restRoomsSection,
.nv_RoomSublist2_restRoomsSection_inconsiderable {
    color: $room-list-preview-color;
}

.nv_RoomSublist2_footerContainer {
    display: flex;
    justify-content: space-between;
    height: 38px;
    padding: 10px 15px 9px 97px;
}

.nv_RoomSublist2_moreButton {
    flex-shrink: 0;
    margin-left: auto;
}

.nv_RoomSublist2_badgeContainer {
    .nv_RoomSublist2_backButton {
        height: 15px;
        width: 15px;
        visibility: visible;
        &::before {
            position: absolute;
            content: '';
            width: 15px;
            height: 15px;
            mask-image: url('$(res)/themes/nova-light/img/icon-dialog-back.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center;
            background-color: #999999;
        }
    }
}

.mx_RoomSublist2_contextMenu {
    padding: 20px 16px;
    width: 250px;

    hr {
        margin-top: 16px;
        margin-bottom: 16px;
        margin-right: 16px; // additional 16px
        border: 1px solid $primary-content;
        opacity: 0.1;
    }

    .mx_RoomSublist_contextMenu_title {
        font-size: $font-15px;
        line-height: $font-20px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .mx_RadioButton, .mx_Checkbox {
        margin-top: 8px;
    }
}


.nv_RoomSublist2_forTag {
    padding: 0;
    .nv_RoomTile2:last-child {
        margin-bottom: 16px;
    }
    .nv_RoomSublist2_headerContainer {
        height: 45px;
    }
    .nv_RoomSublist2_sticky {
        height: 45px;
        border-bottom: 1px solid #e6e6e6;
        background-color: #f2f3f5;
        padding-left: 35px;
    }
    .nv_RoomSublist2_menuButton {
        top: auto;
    }
    .nv_RoomSublist2_badgeContainer {
        top: auto;
        left: 12px;
    }
}
