.mx_JumpToBottomButton {
    z-index: 1000;
    position: absolute;
    // 12 because height is 50 but button is only 38 = 12+(50-38) = 24
    bottom: 12px;
    right: 17px;
    width: 42px;
    // give it a fixed height so the badge doesn't make
    // it taller and pop upwards when visible
    height: 42px;
    text-align: center;

    .mx_JumpToBottomButton_scrollDown {
        position: relative;
        height: 42px;
        border-radius: 50%;
        box-sizing: border-box;
        background: $primary-bg-color;
        cursor: default;
    
        border: 2px solid $primary-hairline-color;
        &:hover {
            background-color: $primary-hairline-color;
        }
    }
    
    .mx_JumpToBottomButton_scrollDown::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        mask: url('$(res)/themes/beeper/img/icon-jump-to-bottom.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        background: #A8A8A8;
    }
}

.mx_JumpToBottomButton_badge {
    pointer-events: none;

    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    font-weight: bold;
    font-size: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;


    color: $secondary-accent-color;
    background-color: $warning-color;
}

.mx_JumpToBottomButton_unread {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 1000;

    .mx_JumpToBottomButton_scrollDown {
        transform: translateX(-50%);
        background: #4958F5;
        color: white;
        font-size: 12px;
        font-weight: bold;
        height: 26px;
        display: flex;
        align-items: center;
        padding: 0 16px;

        &:before {
            content: none;
            // position: absolute;
            position: static;
            width: 9px;
            height: 10px;
            margin-right: 6px;
            mask: url('$(res)/themes/beeper/img/icon-jump-to-bottom-unread.svg');
            mask-repeat: no-repeat;
            mask-position: center;
            background: white;
        }
    }
}
