.mx_ChatNetworksDialogV2 {
    .bridge-settings {
        display: grid;
        grid-template-columns: 3fr 7fr;
        column-gap: 8px;
        row-gap: 8px;
        margin-bottom: 12px;
    }

  .actionbar {
      margin-top: 20px;
      display: grid;
      gap: 8px;
      grid-template-rows: 1fr;
      grid-auto-columns: max-content;
      grid-auto-flow: column;

      &.column {
          margin-top: 20px;
          grid-auto-flow: row;
      }
  }
}

.statusIcon_loading {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(var(--base), .2);
  border-radius: 100px;
}