/*
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_AccessibleButton {
    cursor: default;
    -webkit-app-region: no-drag;
}

.mx_AccessibleButton_disabled {
    cursor: default;
}

.mx_AccessibleButton_hasKind {
    padding: 7px 18px;
    text-align: center;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: $font-14px;
    border: none; // override default <button /> styles
}

.mx_AccessibleButton_kind_primary {
    color: $button-primary-fg-color;
    background-color: $accent;
    border: 1px solid $accent; // account for size loss of no border
    font-weight: 600;
}

.mx_AccessibleButton_kind_primary_outline {
    color: $accent;
    background-color: $button-secondary-bg-color;
    border: 1px solid $accent;
    font-weight: 600;
}

.mx_AccessibleButton_kind_secondary {
    color: $accent;
    font-weight: 600;
}

.mx_AccessibleButton_kind_primary.mx_AccessibleButton_disabled,
.mx_AccessibleButton_kind_primary_outline.mx_AccessibleButton_disabled {
    opacity: 0.4;
}

.mx_AccessibleButton_hasKind.mx_AccessibleButton_kind_primary_sm {
    padding: 5px 12px;
    color: $button-primary-fg-color;
    background-color: $accent;
}

.mx_AccessibleButton_kind_primary_sm.mx_AccessibleButton_disabled {
    opacity: 0.4;
}

.mx_AccessibleButton_kind_danger {
    color: $button-danger-fg-color;
    background-color: $alert;
}

.mx_AccessibleButton_kind_danger_outline {
    color: $alert;
    background-color: transparent;
    border: 1px solid $alert;
}

.mx_AccessibleButton_kind_danger.mx_AccessibleButton_disabled {
    color: $button-danger-disabled-fg-color;
    background-color: $button-danger-disabled-bg-color;
}

.mx_AccessibleButton_kind_danger_outline.mx_AccessibleButton_disabled {
    color: $button-danger-disabled-bg-color;
    border-color: $button-danger-disabled-bg-color;
}

.mx_AccessibleButton_hasKind.mx_AccessibleButton_kind_danger_sm {
    padding: 5px 12px;
    color: $button-danger-fg-color;
    background-color: $alert;
}

.mx_AccessibleButton_kind_danger_sm.mx_AccessibleButton_disabled {
    color: $button-danger-disabled-fg-color;
    background-color: $button-danger-disabled-bg-color;
}

.mx_AccessibleButton_kind_link {
    color: $accent;
}

.mx_AccessibleButton_kind_link.mx_AccessibleButton_disabled {
    opacity: 0.4;
}

.mx_AccessibleButton_kind_link_inline {
    color: $accent;
    font-size: inherit;
    padding: 0 2px;
}

.mx_AccessibleButton_kind_link_inline.mx_AccessibleButton_disabled {
    opacity: 0.4;
}

.mx_AccessibleButton_hasKind.mx_AccessibleButton_kind_link_sm {
    padding: 5px 12px;
    color: $accent;
}

.mx_AccessibleButton_kind_link_sm.mx_AccessibleButton_disabled {
    opacity: 0.4;
}

.mx_AccessibleButton_hasKind.mx_AccessibleButton_kind_confirm_sm {
    background-color: $accent;

    &::before {
        mask-image: url('$(res)/img/feather-customised/check.svg');
    }
}

.mx_AccessibleButton_hasKind.mx_AccessibleButton_kind_cancel_sm {
    background-color: $alert;

    &::before {
        mask-image: url('$(res)/img/feather-customised/x.svg');
    }
}

.mx_AccessibleButton_kind_confirm_sm,
.mx_AccessibleButton_kind_cancel_sm {
    padding: 0px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    position: relative;
    display: block;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffff;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 80%;
    }
}
