/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2019 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

$MiniAppTileHeight: 200px;

.mx_AppsDrawer {
    margin: $container-gap-width;
    margin-right: calc($container-gap-width / 2); // The left side gap is fully handled by this margin. To prohibit bleeding on webkit browser.
    margin-bottom: 0; // No bottom margin for the correct gap to the CallView below.
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;

    &.mx_AppsDrawer_maximise {
        margin-bottom: $container-gap-width;
    }

    .mx_AppsContainer_resizerHandleContainer {
        width: 100%;
        height: 10px;
        display: block;
        position: relative;
    }

    .mx_AppsContainer_resizerHandle {
        cursor: ns-resize;

        // Override styles from library, making the whole area the target area
        width: 100% !important;
        height: 100% !important;

        // This is positioned directly below frame
        position: absolute;
        bottom: 50% !important; // override from library

        // We then render the pill handle in an ::after to keep it in the handle's
        // area without being a massive line across the screen
        &::after {
            content: '';
            position: absolute;
            border-radius: 3px;

            height: 4px;
            bottom: 0;

            // Together, these make the bar 64px wide
            // These are also overridden from the library
            left: calc(50% - 32px);
            right: calc(50% - 32px);
        }
    }

    &:hover {
        .mx_AppsContainer_resizerHandle::after {
            opacity: 0.8;
            background: $primary-content;
        }

        .mx_ResizeHandle_horizontal::before {
            position: absolute;
            left: 3px;
            top: 50%;
            transform: translate(0, -50%);

            height: 64px; // to match width of the ones on roomlist
            width: 4px;
            border-radius: 4px;

            content: '';

            background-color: $primary-content;
            opacity: 0.8;
        }
    }
}

.mx_AppsContainer_resizer {
    margin-bottom: $container-gap-width;
}

.mx_AppsContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 1;
    min-height: 0;

    .mx_AppTile:first-of-type {
        border-left-width: $container-border-width;
        border-radius: 10px 0 0 10px;
    }
    .mx_AppTile:last-of-type {
        border-right-width: $container-border-width;
        border-radius: 0 10px 10px 0;
    }

    .mx_ResizeHandle_horizontal {
        position: relative;

        > div {
            width: 0;
        }
    }
}

// TODO this should be 300px but that's too large
$MinWidth: 240px;

.mx_AppsDrawer_2apps .mx_AppTile {
    width: 50%;

    &:nth-child(3) {
        flex-grow: 1;
        width: 0 !important;
        min-width: $MinWidth !important;
    }
}
.mx_AppsDrawer_3apps .mx_AppTile {
    width: 33%;

    &:nth-child(3) {
        flex-grow: 1;
        width: 0 !important;
        min-width: $MinWidth !important;
    }
}

.mx_AppTile {
    width: 50%;
    min-width: $MinWidth;
    border: $container-border-width solid $widget-menu-bar-bg-color;
    border-left-width: 5px;
    border-right-width: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: $widget-menu-bar-bg-color;
}

.mx_AppTileFullWidth {
    width: 100% !important; // to override the inline style set by the resizer
    margin: 0;
    padding: 0;
    border: $container-border-width solid $widget-menu-bar-bg-color;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: $widget-menu-bar-bg-color;
}

.mx_AppTile_mini {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: $MiniAppTileHeight;
}

.mx_AppTile .mx_AppTile_persistedWrapper,
.mx_AppTileFullWidth .mx_AppTile_persistedWrapper,
.mx_AppTile_mini .mx_AppTile_persistedWrapper {
    flex: 1;
}

.mx_AppTile_persistedWrapper div {
    width: 100%;
    height: 100%;
}

.mx_AppTileMenuBar {
    margin: 0;
    font-size: $font-12px;
    background-color: $widget-menu-bar-bg-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 8px;
}

.mx_AppTileMenuBarTitle {
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .mx_WidgetAvatar {
        margin-right: 12px;
    }
}

.mx_AppTileMenuBarTitle > :last-child {
    margin-left: 9px;
}

.mx_AppTileMenuBarWidgets {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mx_AppTileMenuBar_iconButton {
    width: 12px;
    height: 12px;
    mask-repeat: no-repeat;
    mask-position: 0 center;
    mask-size: auto 12px;
    background-color: $topleftmenu-color;
    margin: 0 5px;

    &.mx_AppTileMenuBar_iconButton_minWidget {
        mask-size: auto 10px;
        mask-image: url("$(res)/img/element-icons/minimise-collapse.svg");
    }

    &.mx_AppTileMenuBar_iconButton_maxWidget {
        mask-size: auto 10px;
        mask-image: url("$(res)/img/element-icons/maximise-expand.svg");
    }

    &.mx_AppTileMenuBar_iconButton_popout {
        mask-image: url('$(res)/img/feather-customised/widget/external-link.svg');
    }

    &.mx_AppTileMenuBar_iconButton_menu {
        mask-image: url('$(res)/img/element-icons/room/ellipsis.svg');
    }
}

.mx_AppTileBody {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    background-color: $widget-body-bg-color;
}

.mx_AppTileBody_mini {
    height: $MiniAppTileHeight;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.mx_AppTile .mx_AppTileBody,
.mx_AppTileFullWidth .mx_AppTileBody,
.mx_AppTile_mini .mx_AppTileBody_mini {
    height: inherit;
    flex: 1;
}

.mx_AppTileBody_mini iframe {
    border: none;
    width: 100%;
    height: 100%;
}

.mx_AppTileBody iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
    display: block;
}

.mx_AppPermissionWarning {
    text-align: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: $font-16px;
}

.mx_AppPermissionWarning_row {
    margin-bottom: 12px;
}

.mx_AppPermissionWarning_smallText {
    font-size: $font-12px;
}

.mx_AppPermissionWarning_bolder {
    font-weight: 600;
}

.mx_AppPermissionWarning h4 {
    margin: 0;
    padding: 0;
}

.mx_AppPermissionWarning_helpIcon {
    margin-top: 1px;
    margin-right: 2px;
    width: 10px;
    height: 10px;
    display: inline-block;
}

.mx_AppPermissionWarning_helpIcon::before {
    display: inline-block;
    background-color: $accent;
    mask-repeat: no-repeat;
    mask-size: 12px;
    width: 12px;
    height: 12px;
    mask-position: center;
    content: '';
    vertical-align: middle;
    mask-image: url('$(res)/img/feather-customised/help-circle.svg');
}

.mx_AppPermissionWarning_tooltip {
    @mixin mx_Tooltip_dark;

    ul {
        list-style-position: inside;
        padding-left: 2px;
        margin-left: 0;
    }
}

.mx_AppLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: relative;
    height: 100%;

    // match bg of border so that the cut corners have the right fill
    background-color: $widget-body-bg-color !important;
    border-radius: 8px;
}

.mx_AppLoading .mx_Spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mx_AppLoading_spinner_fadeIn {
    animation-fill-mode: backwards;
    animation-duration: 200ms;
    animation-delay: 500ms;
    animation-name: mx_AppLoading_spinner_fadeIn_animation;
}

@keyframes mx_AppLoading_spinner_fadeIn_animation {
    from { opacity: 0; }
    to { opacity: 1; }
}

.mx_AppLoading iframe {
    display: none;
}

.mx_AppsDrawer_resizing .mx_AppTile_persistedWrapper {
    z-index: 1;
}
