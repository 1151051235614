/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_RoomList {
    /* take up remaining space below TopLeftMenu */
    flex: 1;
    min-height: 0;
    overflow-y: hidden;
    padding-right: 0;
}

.mx_RoomList_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mx_RoomList .mx_ResizeHandle {
    // needed so the z-index takes effect
    position: relative;
}

/* hide resize handles next to collapsed / empty sublists */
.mx_RoomList .mx_RoomSubList:not(.mx_RoomSubList_nonEmpty) + .mx_ResizeHandle {
    display: none;
}

.mx_RoomList_expandButton {
    margin-left: 8px;
    cursor: default;
    padding-left: 12px;
    padding-right: 12px;
}

.mx_RoomList_emptySubListTip_container {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 7px;
}

.mx_RoomList_emptySubListTip {
    font-size: 13px;
    padding: 5px;
    border: 1px dashed $accent-color;
    color: $primary-fg-color;
    background-color: $droptarget-bg-color;
    border-radius: 4px;
    line-height: 16px;
}

.mx_RoomList_emptySubListTip .mx_RoleButton {
    vertical-align: -2px;
}

.mx_RoomList_headerButtons {
    position: absolute;
    right: 60px;
}

.nv_RoomList_archivedBtn {
    height: 62px;
    box-sizing: border-box;
    display: flex;
    cursor: default;
    padding: 8px 10px;
    user-select: none;
    &:hover {
        background-color: #F2F3F5;
    }
    &_icon {
        width: 46px;
        height: 46px;
        display: flex;
        border-radius: 40px;
        background-color: #bbb;
        flex-shrink: 0;
        &::before {
            width: 100%;
            display: block;
            content: '';
            mask: url('$(res)/themes/nova-light/img/icon-dialog-archived.svg');
            mask-repeat: no-repeat;
            mask-position: center center;
            background-color: #fff;
        }
    }
    &_content {
        margin-left: 10px;
        line-height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    &_label {
        font-weight: 600;
        font-size: 13px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &_description {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 13px;
        color: #98a2aa;
    }
}

.nv_RoomList_RoomList_archived {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 100;
    transform: translateX(100%);
    transition: transform 0.2s ease-out;
}

.nv_RoomList_RoomList_archivedVisible {
    transform: translateX(0);
    box-shadow: 2px 0 0 rgba(0, 0, 0, .3);
}
.nv_RoomList_RoomList_archived_header {
    height: 54px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 18px;
    user-select: none;
}
.nv_RoomList_RoomList_archived_headerIcon {
    height: 54px;
    width: 54px;
    display: flex;
    &::before {
        content: url('$(res)/themes/nova-light/img/icon-dialog-back.svg');
        margin: auto;
    }
}

.nv_RoomList_RoomList_archived_headerLabel {
    font-weight: 600;
    font-size: 15px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
// TODO
.mx_LeftPanel {
    position: relative;
}

.mx_LeftPanel_container.collapsed .nv_RoomList_archivedBtn_content,
.mx_LeftPanel_container.collapsed .nv_RoomList_RoomList_archived_headerLabel {
    display: none;
}

.mx_LeftPanel_container.collapsed {
    .nv_RoomList_RoomList_archived_headerIcon {
        width: 66px;
    }
}
