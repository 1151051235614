.nv_NotificationBadge {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        border-radius: 50%;
        content: "";
        width: 9px;
        height: 9px;
        margin: 2px;
        background: #D0D1D8;
    }
    &_unread::before, &_unread:hover::before {
        background: #4958F5;
    }
    &_mention::before, &_mention:hover::before {
        background: #FF6161;
    }
}
