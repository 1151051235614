/*
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_UserMenu {
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .mx_AccessibleButton {
        display: flex;
        align-items: center;
    }

    .mx_UserMenu_name {
        font-weight: $font-semi-bold;
        font-size: $font-15px;
        line-height: $font-24px;
        margin-left: 10px;
    }

    .mx_UserMenu_cutout .mx_BaseAvatar {
        mask-image: url('$(res)/img/element-icons/roomlist/dnd-avatar-mask.svg');
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
    }

    .mx_UserMenu_dndBadge {
        position: absolute;
        bottom: -2px;
        right: -7px;
        width: 16px;
        height: 16px;
        border-radius: 50%;

        &::before {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $alert;
            mask-image: url('$(res)/img/element-icons/roomlist/dnd.svg');
        }
    }
}

.mx_UserMenu_dnd {
    cursor: default;
    width: 24px;
    height: 24px;
    position: relative;
    display: table;

    &::before {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        background: $header-control-fg;
    }

    &.mx_UserMenu_dnd_noisy::before {
        mask-size: 16px;
        mask-image: url("$(res)/img/icon-bell-outline.svg");
    }

    &.mx_UserMenu_dnd_muted::before {
        mask-size: 16px;
        mask-image: url("$(res)/img/icon-bell-slash-outline.svg");
    }

    cursor: default;
    &:hover {
        &::before {
            background-color: var(--header-control-fg-hover);
        }
    }
}

.mx_UserMenu_contextMenu {
    width: 258px;

    &.mx_IconizedContextMenu .mx_IconizedContextMenu_optionList_red {
        .mx_AccessibleButton {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    .mx_UserMenu_contextMenu_header {
        padding: 20px;

        // Create a flexbox to organize the header a bit easier
        display: flex;
        align-items: center;

        .mx_UserMenu_contextMenu_name {
            // Create another flexbox of columns to handle large user IDs
            display: flex;
            flex-direction: column;
            width: calc(
                100% - 40px
            ); // 40px = 32px theme button + 8px margin to theme button

            * {
                // Automatically grow all subelements to fit the container
                flex: 1;
                width: 100%;

                // Ellipsize any text overflow
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .mx_UserMenu_contextMenu_displayName {
                font-weight: bold;
                font-size: $font-15px;
                line-height: $font-20px;
            }

            .mx_UserMenu_contextMenu_userId {
                font-size: $font-15px;
                line-height: $font-24px;
            }
        }

        .mx_UserMenu_contextMenu_themeButton {
            min-width: 32px;
            max-width: 32px;
            width: 32px;
            height: 32px;
            margin-left: 8px;
            border-radius: 32px;
            background-color: $theme-button-bg-color;
            cursor: default;

            // to make alignment easier, create flexbox for the image
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.mx_UserMenu_contextMenu_guestPrompts,
        &.mx_UserMenu_contextMenu_hostingLink {
            padding-top: 0;
        }

        &.mx_UserMenu_contextMenu_guestPrompts {
            display: inline-block;

            > span {
                font-weight: 600;
                display: block;

                & + span {
                    margin-top: 8px;
                }
            }

            .mx_AccessibleButton_kind_link {
                font-weight: normal;
                font-size: inherit;
                padding: 0;
            }
        }
    }

    .mx_IconizedContextMenu_icon {
        width: 16px;
        height: 16px;
        display: block;

        &::before {
            content: "";
            width: 16px;
            height: 16px;
            display: block;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background: $primary-content;
        }
    }

    .mx_UserMenu_iconHome::before {
        mask-image: url("$(res)/img/element-icons/roomlist/home.svg");
    }

    .mx_UserMenu_iconDnd::before {
        mask-image: url('$(res)/img/element-icons/roomlist/dnd.svg');
    }

    .mx_UserMenu_iconDndOff::before {
        mask-image: url('$(res)/img/element-icons/roomlist/dnd-cross.svg');
    }

    .mx_UserMenu_iconHosting::before {
        mask-image: url("$(res)/img/element-icons/brands/element.svg");
    }

    .mx_UserMenu_iconBell::before {
        mask-image: url("$(res)/img/element-icons/notifications.svg");
    }

    .mx_UserMenu_iconLock::before {
        mask-image: url("$(res)/img/element-icons/security.svg");
    }

    .mx_UserMenu_iconSettings::before {
        mask-image: url("$(res)/img/element-icons/settings.svg");
    }

    .mx_UserMenu_iconHelp::before {
        mask-image: url("$(res)/img/element-icons/help.svg");
    }

    .mx_UserMenu_iconHelpHub::before {
        mask-image: url("$(res)/img/beeper/question-mark16.svg");
    }

    .mx_UserMenu_iconGroup::before {
        mask-image: url("$(res)/img/beeper/group16.svg");
    }

    .mx_UserMenu_iconDM::before {
        mask-image: url("$(res)/img/beeper/avatar16.svg");
    }

    .mx_UserMenu_iconUpdate::before {
        mask-image: url("$(res)/img/beeper/down-arrow16.svg");
    }

    .mx_UserMenu_iconRead::before {
        mask-image: url("$(res)/img/element-icons/read.svg");
    }

    .mx_UserMenu_iconJoin::before {
        mask-image: url("$(res)/img/element-icons/roomlist/explore.svg");
    }

    .mx_UserMenu_iconInvite::before {
        mask-image: url("$(res)/img/element-icons/view-community.svg");
    }

    .mx_UserMenu_iconLink::before {
        mask-image: url("$(res)/img/element-icons/link.svg");
    }

    .mx_UserMenu_iconMessage::before {
        mask-image: url("$(res)/img/element-icons/chat-bubbles.svg");
    }

    .mx_UserMenu_iconSignOut::before {
        mask-image: url("$(res)/img/element-icons/leave.svg");
    }
}

.mx_UserMenu_CustomStatusSection {
    margin: 0 12px 8px;

    .mx_UserMenu_CustomStatusSection_field {
        position: relative;
        display: flex;

        &.mx_UserMenu_CustomStatusSection_field_hasQuery {
            .mx_UserMenu_CustomStatusSection_clear {
                display: block;
            }
        }

        > .mx_UserMenu_CustomStatusSection_input {
            border: 1px solid $accent;
            border-radius: 8px;
            width: 100%;

            &:focus + .mx_UserMenu_CustomStatusSection_clear {
                display: block;
            }
        }

        > .mx_UserMenu_CustomStatusSection_clear {
            display: none;

            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            width: 16px;
            height: 16px;
            margin-right: 8px;
            background-color: $quinary-content;
            border-radius: 50%;

            &::before {
                content: "";
                position: absolute;
                width: inherit;
                height: inherit;
                mask-image: url('$(res)/img/feather-customised/x.svg');
                mask-position: center;
                mask-size: 12px;
                mask-repeat: no-repeat;
                background-color: $secondary-content;
            }
        }
    }

    > p {
        font-size: $font-12px;
        line-height: $font-15px;
        color: $secondary-content;
        margin: 4px 0;
    }

    .mx_AccessibleButton_kind_primary_outline {
        display: block;
    }
}
