.AppControls {
    display: flex;
    height: 100%;
    max-height: 32px;
    -webkit-app-region: no-drag;
}

.AppControls-button {
    padding: 0 5px;
}

.AppControls-button,
.AppControls-button svg {
    height: 100%;
}

.AppControls-button svg {
    width: 36px;
    height: 32px;
}

.AppControls-button:hover {
    background-color: #ebecee;
    color: $app-controls-color;
}

.AppControls-close:hover {
    color: #fff;
    background-color: #d71526;
}

.AppControls-macplaceholder {
    display: block;
    width: 62px;
}

.AppControls-macbutton {
    margin: 0 3px;
}

//commented out until we get good SVG icons to replace overlays
// .AppControls:hover .AppControls-machover {
//     visibility: visible;
// }

.AppControls-macclose {
    background: #ff5c5c;
    font-size: 9pt;
    width: 11px;
    height: 11px;
    border: 1px solid #e33e41;
    border-radius: 50%;
    display: inline-block;
}

.AppControls-macclose:active {
    background: #c14645;
    border: 1px solid #b03537;
}

.AppControls-macclose:active .AppControls-macclosebutton {
    color: #4e0002;
}

.AppControls-macclosebutton {
    color: #820005;
    visibility: hidden;
    cursor: default;
}

.AppControls-macminimize {
    background: #ffbd4c;
    font-size: 9pt;
    line-height: 11px;
    margin-left: 4px;
    width: 11px;
    height: 11px;
    border: 1px solid #e09e3e;
    border-radius: 50%;
    display: inline-block;
}

.AppControls-macminimize:active {
    background: #c08e38;
    border: 1px solid #af7c33;
}

.AppControls-macminimize:active .AppControls-macminimizebutton {
    color: #5a2607;
}

.AppControls-macminimizebutton {
    color: #9a5518;
    visibility: hidden;
    cursor: default;
}

.AppControls-maczoom {
    background: #00ca56;
    font-size: 9pt;
    line-height: 11px;
    margin-left: 6px;
    width: 11px;
    height: 11px;
    border: 1px solid #14ae46;
    border-radius: 50%;
    display: inline-block;
}

.AppControls-maczoom:active {
    background: #029740;
    border: 1px solid #128435;
}

.AppControls-maczoom:active .AppControls-maczoombutton {
    color: #003107;
}

.AppControls-maczoombutton {
    color: #006519;
    visibility: hidden;
    cursor: default;
}
