/**
* Note from Annie!
* This is new Tooltip styles for BEEKIT Tooltip. This is not currently being imported anywhere
* so we don't mess up existed .bp_Tooltip stuff. 

* When we want to switch to use Beekit Tooltip, we can start using this. 
* Too much work for BE, but didn't want to lose the progress I made. 

* Updated Feb 1st 2022
*/

.bp_Tooltip {
  --tooltipBackground: $menu-bg-color;
  --tooltipText: $muted-fg-color;

  &.bp_TooltipRed {
    --tooltipBackground: $bp-button-error-bg;
    --tooltipText: $bp-button-error-textcolor;
  }

  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  padding: 8px;
  transition: opacity 0.3s;
  display: none;
  position: fixed;
  z-index: 10002; // Higher than context menu so tooltips can be used everywhere
  pointer-events: none;
  line-height: $font-14px;
  font-size: $font-12px;
  font-weight: 600;
  max-width: 200px;
  word-break: break-word;

  background-color: var(--tooltipBackground);
  color: var(--tooltipText);
  text-align: center;

  .bp_Tooltip_chevron {
      display: none;
  }

  &.bp_Tooltip_visible {
      animation: mx_fadein 0.2s forwards;
  }

  &.bp_Tooltip_invisible {
      animation: mx_fadeout 0.1s forwards;
  }
}

.bp_Tooltip_basicStyles {
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  padding: 8px;
  // transition: opacity 0.3s;
  z-index: 10002; // Higher than context menu so tooltips can be used everywhere
  line-height: $font-14px;
  font-size: $font-12px;
  font-weight: 600;

  background-color: var(--tooltipBackground, $menu-bg-color);
  color: var(--tooltipText, $muted-fg-color);
  text-align: center;
}


.bp_Tooltip_chevron {
  position: absolute;
  width: 0;
  height: 0;
  content: '';
  border-top: 6px solid transparent;
  border-right: 6px solid var(--tooltipBackground, $menu-bg-color);
  border-bottom: 6px solid transparent;
  top: -6px;
  left: 1px;

  // chevron being on the "left" side is default
  // so these classes handle the other sides

  &.bottom {
    top: 27px;
    left: 45%;
    transform: rotate(-90deg);
  }

  &.top {
    top: -9px;
    left: 45%;
    transform: rotate(90deg);
  }

  &.right {
    top: 9px;
    left: 54px;
    transform: rotate(180deg);
  }
}

.bp_Tooltip_withChevron {
  .bp_Tooltip_chevron {
      display: block;
  }
}

/**************************************************************************
***************************************************************************
***************************************************************************/

/**
* Popper Tooltip
**/ 

.bp_PopperTooltip {
  --tooltipBackground: $menu-bg-color;
  --tooltipBorder: $menu-bg-color;

  background-color: var(--tooltipBackground);
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  padding: 8px;
  transition: opacity 0.2s;

  z-index: 9999;
  line-height: $font-14px;
  font-size: $font-12px;
  font-weight: 600;
  color: $muted-fg-color;
}

.bp_PopperTooltip[data-popper-interactive='false'] {
  pointer-events: none;
}

.bp_PopperTooltipArrow {
  height: 16px;
  position: absolute;
  width: 16px;
  pointer-events: none;
}

.bp_PopperTooltipArrow::before {
  content: '';
  display: block;
  // margin: auto;
  border: 8px solid transparent;
}
  
.bp_PopperTooltip[data-popper-placement*='bottom'] .bp_PopperTooltipArrow { top: -16px; }
.bp_PopperTooltip[data-popper-placement*='bottom'] .bp_PopperTooltipArrow::before { border-bottom-color: var(--tooltipBorder); }

.bp_PopperTooltip[data-popper-placement*='top'] .bp_PopperTooltipArrow { bottom: -16px; }
.bp_PopperTooltip[data-popper-placement*='top'] .bp_PopperTooltipArrow::before { border-top-color: var(--tooltipBorder); }

.bp_PopperTooltip[data-popper-placement*='right'] .bp_PopperTooltipArrow { left: -16px; }
.bp_PopperTooltip[data-popper-placement*='right'] .bp_PopperTooltipArrow::before { border-right-color: var(--tooltipBorder); }

.bp_PopperTooltip[data-popper-placement*='left'] .bp_PopperTooltipArrow { right: -16px; }
.bp_PopperTooltip[data-popper-placement*='left'] .bp_PopperTooltipArrow::before { border-left-color: var(--tooltipBorder); }