.option-icon {
    width: 64px;
    height: 64px;
    // margin-right: 4px;
    vertical-align: bottom;
}

.beekit-wizard-step {
    display: grid;
    grid-template-rows: 40px 1fr 64px;
    width: 100%;
    height: 100%;
    color: #fff;
}

.beekit-wizard-step-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.beekit-wizard-step-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}

.beekit-wizard-question-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
