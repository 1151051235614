.bp_MainPanel {
    display: flex;
    flex-direction: column;
    background-color: $main-panel-bg;
    position: relative;
}

.bp_TitleBar {
    display: flex;
    background-color: $left-panel-v2-bg;
    flex-direction: row-reverse;
    -webkit-app-region: drag;
}