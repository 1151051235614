.nv_RoomTileGroup {
}

.nv_RoomTileGroup_label {
    background: #F2F3F5;
    color: #979797;
    font-weight: 600;
    padding: 7px 0 7px 18px;
    font-size: 13px;
}

.nv_RoomTileGroup_dragContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

.nv_RoomTileGroup_dragItem {
    height: 62px;
}
.nv_RoomTileGroup_dragItemDragging {
    .mx_RoomTile {
        background: #f2f2f2;
    }
    .mx_RoomTile_selected {
        background: $accent-color;
    }
}
