/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_BaseAvatar {
    position: relative;
    // In at least Firefox, the case of relative positioned inline elements
    // (such as mx_BaseAvatar) with absolute positioned children (such as
    // mx_BaseAvatar_initial) is a dark corner full of spider webs. It will give
    // different results during full reflow of the page vs. incremental reflow
    // of small portions. While that's surely a browser bug, we can avoid it by
    // using `inline-block` instead of the default `inline`.
    // https://github.com/vector-im/element-web/issues/5594
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1535053
    // https://bugzilla.mozilla.org/show_bug.cgi?id=255139
    display: inline-block;
    user-select: none;
    z-index: 5;

    &.mx_RoomAvatar_isSpaceRoom {
        &.mx_BaseAvatar_image, .mx_BaseAvatar_image {
            border-radius: 8px;
        }
    }
}

.mx_BaseAvatar_initial {
    position: absolute;
    left: 0;
    color: $avatar-initial-color;
    mix-blend-mode: color-burn;
    text-align: center;
    speak: none;
    pointer-events: none;
    font-weight: normal;
    z-index: 20;
    opacity: .4;
}

.mx_BaseAvatar_initial.mx_BaseAvatar_initial--colourful {
    color: hsl(var(--avatarHue, $avatar-initial-color), 100%, 32%);
    mix-blend-mode: revert;
    opacity: 1;
}

.mx_BaseAvatar_image {
    object-fit: cover;
    border-radius: 50%;
    vertical-align: top;
    background-color: $background;
}

.bp_Avatar {
    color: $space-60;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bp_Avatar_bg--dynamic {
    background: linear-gradient(
        hsl(var(--avatarHue, $avatar-initial-color), 100%, 80%),
        hsl(var(--avatarHue, $avatar-initial-color), 100%, 56%));
}

.bp_Avatar_bg {
  &_1 {
      background-color: $koolaid-20;
  }
  &_2 {
      background-color: $crayola-20;
  }
  &_3 {
      background-color: $coral-40;
  }
  &_4 {
      background-color: $petal-20;
  }
}

.bp_noteToSelf {
  border-radius: 100px;
  background-color: var(--search-input-bg-experimental);

  .bp_noteToSelf_icon {
      filter: $avatar-icon-filter;
  }
}

.mx_BaseAvatar_initial {
    height: 100%;
    .bp_icon {
        height: 100%;
    }
    .bp_icon div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .bp_Icon {
            margin-top: 0;
        }
    }
}