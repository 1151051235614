.mx_VerificationShowSas_emojiSas {
    justify-content: flex-start;
    margin-left: -16px;
}

.mx_VerificationShowSas_emojiSas_block {
    margin-left: 16px;
    width: auto;

    .mx_VerificationShowSas_emojiSas_emoji {
        font-family: 'Sofia Pro';
        font-size: 48px;
        line-height: 1;
    }
    .mx_VerificationShowSas_emojiSas_label {
        margin-top: 8px;
    }
}

.mx_VerificationShowSas_emojiSas_break {
    display: none;
}
