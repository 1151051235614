.bp_Spinner {
    display: inline-block;
    border-top-color: $quinary-content;
    border-right-color: $quinary-content;
    border-style: solid;
    border-radius: 99999px;
    border-width: 1px;
    border-bottom-color: $secondary-content;
    border-left-color: $secondary-content;
    animation: 1s linear 0s infinite normal none running spin;
    width: var(--spinner-size);
    height: var(--spinner-size);
    --spinner-size: 3rem;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
