/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_MemberInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin-top: 8px;
}

.mx_MemberInfo_name {
    align-items: center;
    display: flex;
}

.mx_MemberInfo_name > .mx_E2EIcon {
    margin-right: 0;
}

.mx_MemberInfo_cancel {
    height: 16px;
    width: 16px;
    padding: 10px 0 10px 10px;
    cursor: default;
    mask-image: url('$(res)/img/minimise.svg');
    mask-repeat: no-repeat;
    mask-position: 16px center;
    background-color: $header-panel-text-primary-color;
}

.mx_MemberInfo_name h2 {
    flex: 1;
    overflow-x: auto;
    max-height: 50px;
}

.mx_MemberInfo h2 {
    font-size: $font-18px;
    font-weight: 600;
    margin: 16px 0 16px 15px;
}

.mx_MemberInfo_container {
    margin: 0 16px 16px 16px;
}

.mx_MemberInfo .mx_RoomTile_nameContainer {
    width: 154px;
}

.mx_MemberInfo .mx_RoomTile_badge {
    display: none;
}

.mx_MemberInfo .mx_RoomTile_name {
    width: 160px;
}

.mx_MemberInfo_avatar {
    background: $tagpanel-bg-color;
    margin-bottom: 16px;
}

.mx_MemberInfo_avatar > img {
    height: auto;
    width: 100%;
    max-height: 30vh;
    object-fit: contain;
    display: block;
}

.mx_MemberInfo_avatar .mx_BaseAvatar.mx_BaseAvatar_image {
    cursor: zoom-in;
}

.mx_MemberInfo_profile {
    margin-bottom: 16px;
}

.mx_MemberInfo h3 {
    text-transform: uppercase;
    color: $input-darker-fg-color;
    font-weight: bold;
    font-size: $font-12px;
    margin: 4px 0;
}

.mx_MemberInfo_profileField {
    font-size: $font-15px;
    position: relative;
}

.mx_MemberInfo_buttons {
    margin-bottom: 16px;
}

.mx_MemberInfo_field {
    cursor: default;
    font-size: $font-15px;
    color: $primary-content;
    margin-left: 8px;
    line-height: $font-23px;
}

.mx_MemberInfo_createRoom {
    cursor: default;
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.mx_MemberInfo_createRoom_label {
    width: initial !important;
    cursor: default;
}

.mx_MemberInfo label {
    font-size: $font-13px;
}

.mx_MemberInfo label .mx_MemberInfo_label_text {
    display: inline-block;
    max-width: 180px;
    vertical-align: text-top;
}

.mx_MemberInfo input[type="radio"] {
    vertical-align: -2px;
    margin-right: 5px;
    margin-left: 8px;
}

.mx_MemberInfo_statusMessage {
    font-size: $font-11px;
    opacity: 0.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
}
.mx_MemberInfo .mx_MemberInfo_scrollContainer {
    flex: 1;
}
