.bp_ShowMoreGroups {
    padding: 8px;
    text-align: center;
    color: #ADABB5;
    font-size: 12px;
    height: 50px;
}

.bp_Channels_scrollHelper {
    overflow-y: scroll;
    height: 100%;
    padding-top: 14px;

    &::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
}

.bp_ProtocolsToggle {
    margin: 2px 6px 10px 6px;
    display: flex;
    position: relative;
    justify-content: center;

    img, .bp_Avatar {
        width: 32px;
        height: 32px;
        border-radius: 8px;
    }

    &_active {
        img, .bp_Avatar {
            border: 2px solid $left-panel-bg;
            margin: -4px;
            width: 36px;
            height: 36px;
            box-shadow: 0 0 0 1px rgba($muted-fg-color, .5);
        }
    }

    .bp_newChatButton_border {
        background-color: $bp-new-msg-border;
        width: 38px;
        height: 38px;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        .bp_newChatButton {
        border-radius: 32px;
        background: $bp-new-msg-gradient;
        background-blend-mode: multiply, normal;
        border: 0.5px solid #0B1023;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;

        .bp_newChatIcon {
            background-image: $bp-new-msg-icon;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 16px;
            height: 17px;
        }
        }
    }


.bp_ProtocolsToggle {
    opacity: 0.64;
}

.mx_AccessibleButton
  .bp_ProtocolsToggle
  .bp_ProtocolsToggle_collapsed
  > .bp_Channel_protocolIcon,
.bp_ProtocolsToggle:before {
  background: none;
}

.bp_Channel_protocol:before,
.bp_ProtocolsToggle:before {
  border: none;
}

.bp_Channel_protocol {
    background-color: $room-pill-bg;
    border: 1px solid $room-tile-bg-active;
    box-sizing: border-box;
    box-shadow: $space-item-shadow;
    border-radius: 8px;
    width: 32px;
    height: 32px;
}

    .bp_Channel_badge {
        position: absolute;
        top: -4px;
        right: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 18px;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        font-size: 10px;
        font-weight: 700;
        border-radius: 50%;
        color: white;
        border: 1px solid var(--room-tile-bg-hover);

        &.unread {
            background: #e0474f;
        }

        &_icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: -6px;
        left: -5px;
        &.warning {
            width: 20px;
            height: 20px;
            top: -8px;
            left: -8px;
            background-image: url('$(res)/img/warning-stroke.svg');
        }
        &.error {
            background-image: url('$(res)/img/x-stroke.svg');
        }
        }
    }
}

.bp_Channel_beeper, .bp_Channel_low, .bp_Channel_protocol, .bp_ProtocolsToggle, .bp_Channel_bookmarks {
    &::before {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        content: '';
        width: 32px;
        height: 32px;
        border-radius: 8px;
    }
}

.bp_Channel_beeper, .bp_Channel_low, .bp_Channel_bookmarks {
    &::before {
        background: $accent-fg-color; // ? $search-bar-input-bg $dark-100
    }

    &.bp_Channel_active {
        &::before {
            border: 2px solid $event-tile-bg;
            box-sizing: border-box;
            box-shadow: $space-item-active-shadow;
            border-radius: 8px;
            width: 32px;
            height: 32px;
            margin: 0;
        }
    }
}

.bp_Channel_protocol, .bp_ProtocolsToggle {
    &::before {
        background-color: $bp-space-filter-bg;
        border: 1px solid $bp-space-filter-border;
    }

    &.hasNetworkError {
        &::before {
            background: #FFCE5133;
        }
    }

    &.bp_Channel_active {
        &::before {
            background-color: $bp-active-space-filter-bg;
            border-color: $bp-active-space-filter-border;
            margin: -2px;
            width: 36px;
            height: 36px;
            box-shadow: 0 0 0 1px rgba($muted-fg-color, .5);
        }
    }
}

.bp_Channel_beeper, .bp_Channel_low, .bp_Channel_protocol {
    &.hasWarning {
        &::before {
            background-color: #FFCE5130;
        }
    }

    &.hasError {
        &::before {
            background-color: #E0474F30;
        }
    }
}

.bp_Channel_beeper::before {
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}
.bp_Channel_low {
    position: relative;

    .bp_Channel_lowIcon {
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;

        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.bp_Channel_bookmarks {
    position: relative;

    .bp_Channel_bookmarksIcon {
        background-size: 17px;
        background-position: center;
        background-repeat: no-repeat;

        display: flex;
        width: 100%;
        height: 100%;
        top: 2px;
        position: absolute;
    }
}

.bp_ProtocolsToggle {

    .icon {
        position: absolute;
        width: 100%;
        height: 100%;
        mask-image: url('$(res)/themes/beeper/img/icon-arrow.svg');
        mask-size: contain;
        background-color: currentColor;
    }

    // background-image: url('$(res)/themes/beeper/img/icon-arrow.svg');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    &_collapsed {
        .icon {
            transform: rotateZ(180deg);
        }
    }
}