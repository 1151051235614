.mx_DateSeparator {
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    user-select: none;
}

.mx_RoomView_dateSeparator_container .mx_DateSeparator>div,
.mx_DateSeparator > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: none;
    height: 24px;
    font-weight: 400;
    padding: 0 10px;
    font-size: 12px;
    background: $date-separator-bg;
    color: $date-separator-fg;
}
