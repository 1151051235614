.mx_EventListSummary {
    position: relative;
    font-size: 13px;
}

.mx_EventListSummary_avatars {
    display: none;
}

.mx_EventListSummary_toggle {
    color: $accent-color;
    cursor: default;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;
}

.mx_EventListSummary {
    .mx_EventTile_line {
        margin: 0;
        padding: 0;
    }
    .mx_EventTile_info {
        display: flex;
        justify-content: center;
    }
    .mx_TextualEvent.mx_EventListSummary_summary {
        font-weight: 600;
        background: #98a2aa;
        height: 25px;
        line-height: 25px;
        border-radius: 15px;
        padding: 0 12px;
        color: #ffffff;
    }
}

.mx_MatrixChat_useCompactLayout {
    .mx_EventListSummary {
        font-size: 13px;
        .mx_EventTile_line {
            line-height: 20px;
        }
    }

    .mx_EventListSummary_line {
        line-height: 22px;
    }

    .mx_EventListSummary_toggle {
        margin-top: 6px;
    }
}
