/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_Waveform {
    position: relative;
    height: 30px; // tallest bar can only be 30px
    top: 1px; // because of our border trick (see below), we're off by 1px of aligntment

    display: flex;
    align-items: center; // so the bars grow from the middle

    overflow: hidden; // this is cheaper than a `max-height: calc(100% - 4px)` in the bar's CSS.

    // A bar is meant to be a 2x2 circle when at zero height, and otherwise a 2px wide line
    // with rounded caps.
    .mx_Waveform_bar {
        width: 0; // 0px width means we'll end up using the border as our width
        border: 1px solid transparent; // transparent means we'll use the background colour
        border-radius: 2px; // rounded end caps, based on the border
        min-height: 0; // like the width, we'll rely on the border to give us height
        max-height: 100%; // this makes the `height: 42%` work on the element
        margin-left: 1px; // we want 2px between each bar, so 1px on either side for balance
        margin-right: 1px;

        // background color is handled by the parent components
    }
}
