@import "../../light/css/_light.scss";
@import "../../../css/beekit/_colors.scss";
@import "./_spacebar-icons.scss";

$top-bar-height: 45px;
$font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Apple Color Emoji", "Twemoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif, "Noto Color Emoji", "Inter";

$monospace-font-family: monospace;

// unified palette
// try to use these colors when possible
$brand-color: #A93FE9;
$accent: #4958F5;
$accent-color: #4958F5;
$accent-bg-color: $accent-color;
$notice-primary-color: #d80915;
$notice-primary-bg-color: rgba(255, 75, 85, 0.16);
$notice-secondary-color: #61708b;
$header-panel-bg-color: #F2F3F5;

$background: #F7F7F7;

$brand-linear-gradient: linear-gradient(120deg, #a93fe9 12px, #4958f5);

$app-controls-color: unset;

// typical text (dark-on-white in light skin)
$primary-fg-color: #424961;
$primary-content: $primary-fg-color;
$primary-bg-color: #ffffff;
// $accent-bg-color: #f8f8fb;
$muted-fg-color: #2e3338; // Commonly used in headings and relevant alt text
$bg-color: $primary-bg-color;
$bg-color-secondary: $primary-bg-color;
$favourites-bg-color: $background;
$favorite-tiles-bg: white;
$unreads-grouped-bg-color: #f5f5F5;
$bp-unreads-toggle-text: $dark-700;
$chat-network-content-bg: #f8f8fb;

// used for dialog box text
$light-fg-color: #999999;

// used for focusing form controls
$focus-bg-color: #dddddd;

// button UI (white-on-green in light skin)
$accent-fg-color: #ffffff;
$accent-color-50pct: rgba(3, 179, 129, 0.5);    //#03b381 in rgb
$accent-color-darker: #92caad;
$accent-color-alt: #238CF5;

$selection-fg-color: $primary-bg-color;

$focus-brightness: 105%;

// warning colours
$warning-color: $notice-primary-color; // red
$orange-warning-color: #ff8d13; // used for true warnings
// background colour for warnings
$warning-bg-color: #DF2A8B;
$info-bg-color: #2A9EDF;
$other-user-pill-bg-color: rgba(29, 170, 236, 0.1);

// pinned events indicator
$pinned-unread-color: $notice-primary-color;
$pinned-color: $notice-secondary-color;

// informational plinth
$info-plinth-bg-color: #f7f7f7;
$info-plinth-fg-color: #888;

$preview-bar-bg-color: #f7f7f7;

// left-panel style muted accent color
$secondary-accent-color: $primary-bg-color;
$tertiary-accent-color: #d3efe1;

$tagpanel-bg-color: #27303a;
$groupFilterPanel-bg-color: $tagpanel-bg-color;

// used by RoomDirectory permissions
$plinth-bg-color: $secondary-accent-color;

// used by RoomDropTarget
$droptarget-bg-color: rgba(255,255,255,0.5);

// used by AddressSelector
$selected-color: $secondary-accent-color;

// selected for hoverover & selected event tiles
$event-selected-color: $header-panel-bg-color;

// used for the hairline dividers in RoomView
$primary-hairline-color: #E4E4F7;

// used for the border of input text fields
$input-border-color: #e7e7e7;
$input-darker-bg-color: #e3e8f0;
$input-darker-fg-color: #9fa9ba;
$input-lighter-bg-color: #f2f5f8;
$input-lighter-fg-color: $input-darker-fg-color;
$input-focused-border-color: $accent-color;
$input-valid-border-color: rgba(255, 255, 255, 0.08);
$input-invalid-border-color: $warning-color;

$field-focused-label-bg-color: #ffffff;

$button-bg-color: rgba(169, 63, 233, 1);
$button-fg-color: white;

// apart from login forms, which have stronger border
$strong-input-border-color: #c7c7c7;

// used for UserSettings EditableText
$input-underline-color: rgba(151, 151, 151, 0.5);
$input-fg-color: rgba(74, 74, 74, 0.9);
// scrollbars TODO
$scrollbar-thumb-color: rgba(0, 0, 0, 0.351);
$scrollbar-thumb-color-hover: rgba(0, 0, 0, 0.51);
$scrollbar-track-color: rgba(0, 0, 0, 0.081);
$scrollbar-track-color-hover: rgba(0, 0, 0, 0.251);
// context menus
$menu-border-color: #cacfd2;
$menu-bg-color: #fff;
$menu-box-shadow-color: rgba(0, 0, 0, 0.5);
$menu-selected-color: #f5f8fa;

$avatar-initial-color: $space-60;
$avatar-bg-color: #ffffff;
$avatar-icon-filter: none;

$h3-color: #3d3b39;

$dialog-title-fg-color: #45474a;
$dialog-backdrop-color: rgba(46, 48, 51, 0.38);
$dialog-shadow-color: rgba(0, 0, 0, 0.48);
$dialog-close-fg-color: #c1c1c1;

$dialog-background-bg-color: #e9e9e9;
$lightbox-background-bg-color: #2e3338;

$imagebody-giflabel: rgba(0, 0, 0, 0.7);
$imagebody-giflabel-border: rgba(0, 0, 0, 0.2);
$imagebody-giflabel-color: rgba(255, 255, 255, 1);

$greyed-fg-color: #888;

$neutral-badge-color: #dbdbdb;

$preview-widget-bar-color: #ddd;
$preview-widget-fg-color: $greyed-fg-color;

$blockquote-bar-color: #ddd;
$blockquote-fg-color: #777;

$settings-grey-fg-color: #a2a2a2;
$settings-profile-placeholder-bg-color: #e7e7e7;
$settings-profile-overlay-bg-color: #2e3338;
$settings-profile-overlay-placeholder-bg-color: transparent;
$settings-profile-overlay-fg-color: #fff;
$settings-profile-overlay-placeholder-fg-color: #2e2f32;
$settings-subsection-fg-color: #61708b;

$voip-decline-color: #f48080;
$voip-accept-color: #80f480;

$rte-bg-color: #e9e9e9;
$rte-code-bg-color: rgba(0, 0, 0, 0.04);
$rte-room-pill-color: #aaa;
$rte-group-pill-color: #aaa;

$topleftmenu-color: $primary-fg-color;
$roomheader-color: $primary-fg-color;
$roomheader-addroom-bg-color: #91A1C0;
$roomheader-addroom-fg-color: $accent-fg-color;
$tagpanel-button-color: #91A1C0;
$roomheader-button-color: #68686D;
$groupheader-button-color: #91A1C0;
$rightpanel-button-color: #91A1C0;
$composer-button-color: #91A1C0;
$roomtopic-color: #9e9e9e;
$eventtile-meta-color: $roomtopic-color;

$composer-e2e-icon-color: #c9ced6;
$header-divider-color: #91A1C0;

// ********************

$roomtile-name-color: $primary-fg-color;
$roomtile-selected-color: $primary-fg-color; // TODO changes wrong using as notif fg color
$roomtile-notified-color: #212121;
$roomtile-selected-bg-color: #D0D1D8CC;
$roomtile-focused-bg-color: #e4e4e4;
$roomtile-hover-bg-color: #D0D1D8;
$roomtile-badge-fg-color: $accent-fg-color;
$roomtile-badge-bg-color: $accent-color; // TODO add to badge
$roomtile-title-color: $primary-fg-color;

$username-variant1-color: #c03d33;
$username-variant2-color: #4fad2d;
$username-variant3-color: #d09306;
$username-variant4-color: #168acd;
$username-variant5-color: #8544d6;
$username-variant6-color: #cd4073;
$username-variant7-color: #2996ad;
$username-variant8-color: #ce671b;

$roomtile-transparent-focused-color: rgba(0, 0, 0, 0.1);

$roomsublist-background: $secondary-accent-color;
$roomsublist-label-fg-color: $roomtile-name-color;
$roomsublist-label-bg-color: $tertiary-accent-color;
$roomsublist-chevron-color: $accent-color;

$panel-divider-color: #cacfd2;

// ********************

$widget-menu-bar-bg-color: $secondary-accent-color;

// ********************

// both $event-highlight-bg-color and $room-warning-bg-color share this value,
// so to not make their order dependent on who depends on who, have a shared value
// defined before both
$yellow-background: #fff8e3;

// event tile lifecycle
$event-encrypting-color: #abddbc;
$event-sending-color: #ddd;
$event-notsent-color: #f44;

$event-highlight-fg-color: #CCF3FF;
$event-highlight-bg-color: $yellow-background;

// event redaction
$event-redacted-fg-color: #e2e2e2;
$event-redacted-border-color: #cccccc;

// event timestamp
$event-timestamp-color: #acacac;

$copy-button-url: "$(res)/img/feather-customised/clipboard.svg";

$link-preview-text-color: $space-30;

// e2e
$e2e-verified-color: #76cfa5; // N.B. *NOT* the same as $accent-color
$e2e-unknown-color: #e8bf37;
$e2e-unverified-color: #e8bf37;
$e2e-warning-color: #ba6363;

// Tabbed views
$tab-label-fg-color: #45474a;
$tab-label-active-fg-color: #ffffff;
$tab-label-bg-color: transparent;
$tab-label-active-bg-color: $accent-color;
$tab-label-icon-bg-color: #454545;
$tab-label-active-icon-bg-color: $tab-label-active-fg-color;

// Buttons
$button-primary-fg-color: #ffffff;
$button-primary-bg-color: $accent-color;
$button-secondary-bg-color: $accent-fg-color;
$button-danger-fg-color: #ffffff;
$button-danger-bg-color: $notice-primary-color;
$button-danger-disabled-fg-color: #ffffff;
$button-danger-disabled-bg-color: #f5b6bb; // TODO: Verify color
$button-link-fg-color: $accent-color;
$button-link-bg-color: transparent;

$visual-bell-bg-color: #faa;

// Toggle switch
$togglesw-off-color: #c1c9d6;
$togglesw-on-color: $accent-color;
$togglesw-ball-color: #fff;

$progressbar-color: #2e3338;

$room-warning-bg-color: $yellow-background;

$memberstatus-placeholder-color: $roomtile-name-color;

$instruction-num-color: #131B3A;

$authpage-bg-color: #131B3A;
$authpage-modal-bg-color: rgba(255, 255, 255, 0.59);
$authpage-body-bg-color: #32384E;
$authpage-focus-bg-color: rgba(255, 255, 255, 0.05);
$authpage-lang-color: #4e5054;
$authpage-primary-color: #ffffff;
$authpage-secondary-color: rgba(113, 118, 137, 1)
;

$dark-panel-bg-color: $secondary-accent-color;
$panel-gradient: #ffffff;

$message-action-bar-bg-color: $primary-bg-color;
$message-action-bar-fg-color: $primary-fg-color;
$message-action-bar-border-color: #e9edf1;
$message-action-bar-hover-border-color: $focus-bg-color;

$reaction-row-button-bg-color: #e7e8ee;
$reaction-row-button-border-color: #e7e8ee;
$reaction-row-button-hover-border-color: $focus-bg-color;
$reaction-row-button-selected-bg-color: #ebe9ff;
$reaction-row-button-selected-border-color: $accent-color;
$reaction-row-button-error-bg-color: $siren-20;
$reaction-row-button-error-border-color: $siren-100;


$kbd-border-color: $reaction-row-button-border-color;

$tooltip-timeline-bg-color: $tagpanel-bg-color;
$tooltip-timeline-fg-color: #ffffff;

$interactive-tooltip-bg-color: #27303a;
$interactive-tooltip-fg-color: #ffffff;

$breadcrumb-placeholder-bg-color: #e8eef5;

$user-tile-hover-bg-color: $header-panel-bg-color;

// FontSlider colors
$appearance-tab-border-color: $input-darker-bg-color;

// ***** Mixins! *****

@define-mixin mx_DialogButton {
    /* align images in buttons (eg spinners) */
    vertical-align: middle;
    border: 0px;
    border-radius: 28px;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    color: $button-fg-color;
    width: auto;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    display: inline-block;
    outline: none;
}

@define-mixin mx_DialogButton_hover {
  cursor: default;
}

@define-mixin mx_DialogButton_danger {
    background-color: $accent-color;
}

@define-mixin mx_DialogButton_small {
    @mixin mx_DialogButton;
    font-size: 15px;
    padding: 0px 1.5em 0px 1.5em;
}

@define-mixin mx_DialogButton_secondary {
    // flip colours for the secondary ones
    font-weight: 600;
    border: 1px solid $accent-color ! important;
    color: $accent-color;
    background-color: $button-secondary-bg-color;
}

@define-mixin mx_Dialog_link {
    color: $accent-color;
    text-decoration: none;
}

.hljs-outer-grid {
    display: grid;
    column-gap: 8px;
}

.hljs-outer-grid-line-number {
    grid-column-start: 1;
    color: gray;
    text-align: right;
    user-select: none;
}

.hljs-outer-grid-code {
    grid-column-start: 2;
}
.hljs-outer-grid-code, .hljs-outer-grid-code * { font-family: monospace !important; }

// Slider
$slider-selection-color: $accent-color;
$slider-background-color: #c1c9d6;
// FontSlider colors
$font-slider-bg-color: rgba($input-darker-bg-color, 0.2);


// ********************

// V2 Room List
// TODO: Remove the 2 from all of these when the new list takes over

$theme-button-bg-color: #e3e8f0;

$roomlist2-button-bg-color: #fff; // Buttons include the filter box, explore button, and sublist buttons
$roomlist2-bg-color: $header-panel-bg-color;

$roomsublist2-divider-color: #e9eaeb;

$roomtile2-preview-color: #9e9e9e;
$roomtile2-default-badge-bg-color: #61708b;
$roomtile2-selected-bg-color: #FFF;

$presence-online: $accent-color;
$presence-away: orange; // TODO: Get color
$presence-offline: #E3E8F0;

$inverted-bg-color: #27303a;
$roomheader-bg-color: rgba(239, 239, 240, 0.88);
$composer-shadow-color: rgba(0, 0, 0, 0.04);
$roomlist2-header-color: $primary-fg-color;
$roomlist2-button-bg-color: #fff; // Buttons include the filter box, explore button, and sublist buttons
$roomlist2-bg-color: rgba(245, 245, 245, 0.90);
// ********************


// TODO Review & cleanup
$room-list-border-color: rgba(0, 0, 0, 0.05);
$room-list-room-tile-hover: #f0f0f0;
$room-list-preview-color: #717689;
$left-panel-bg: #F0F0F1;
$left-panel-font: Roboto, Twemoji, 'Apple Color Emoji', 'Segoe UI Emoji', Arial, Helvetica, sans-serif, 'Noto Color Emoji';

$reply-thread-bg: rgba(37, 38, 45, 0.08);
$invite-icon-color: #F69139;

$search-bar-bg: #131B3A;

$event-tile-bg: #E1E2E2;
$event-tile-fg: #35363D;

$room-header-bg: #FFFFFF;
$message-composer-bg: #F7F7F7;
$message-composer-fg: #35363D;
$message-composer-border: #949598;
$message-out-fg: #ffffff;
$message-out-secondary-fg: #D0D1D8;

$mention-pill-bg: #eaeaea;
$mention-pill-fg: #35363d;
$mention-pill-out-fg: #efeff0;
$at-me-pill-fg: $bronzer-500;
$composer-mention-pill-bg: #efeff0;


$search-bar-input-bg: #32384E;

$bp-tag-bg: $primary-bg-color;
$bp-tag-active: #717689;

$invites-baloon-bg: #fff5db;

$divider-color: #D0D1D8;
$protocol-spaces-color: #A1A4B0;

$transparent-body-bg: #ffffff85;


/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/*******************     BEEPER BEEKIT      ***********************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/

$beeper-theme: light;
$media-bg-color: $light-700;

/******************************************************************/
/***********************   INPUT   ********************************/
/******************************************************************/

$bp-input-bgcolor: $space-01;
$bp-input-hover-color: $light-800;
$bp-input-placeholder-color: $dark-70;
$bp-input-textcolor: $dark-800;
$bp-bordered-input-bgcolor: $primary-bg-color;
$bp-bordered-input-placeholder-color: $space-30;
$bp-bordered-input-textcolor: $space-50;

$bp-input-border-color: $light-500;
$bp-input-selected-bg-color: $azure-700;

/******************************************************************/
/***********************   DIVIDER   ******************************/
/******************************************************************/

$bp-divider-primary-color: $light-700;

/******************************************************************/
/***********************   BUTTON   *******************************/
/******************************************************************/

$bp-button-primary-textcolor: $light-200;
$bp-button-primary-bg: transparent;
$bp-button-primary-border: 2px solid $azure-500;

$bp-button-primary-hover-shadow: none;
$bp-button-hover-border: $azure-600;
$bp-button-hover-textcolor: $light-300;

$bp-button-error-bg: $siren-400;
$bp-button-error-textcolor: $light-900;

$bp-button-disabled-bg: $light-700;
$bp-button-disabled-textcolor: $light-600;

// old secondary
$bp-button-secondary-outline: $space-40;
$bp-button-secondary-active-outline: $space-50;
$bp-button-secondary-disabled-textcolor: $space-30;
$bp-button-secondary-disabled-outline: $space-10;

// new secondary
$bp-button-secondary-textcolor: $light-300;
$bp-button-secondary-border: $light-400;

// secondary hover
$bp-button-secondary-hover-textcolor: $light-500;
$bp-button-secondary-hover-border: $light-600;

// secondary error
$bp-button-secondary-error-border: $siren-500;

/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/**************************     BEEPER      ***********************/
/******************************************************************/
/******************************************************************/
/******************************************************************/
/******************************************************************/

/******************************************************************/
/***********************   BridgedIcon   **************************/
/******************************************************************/

$bp-bridged-icon-bg-color: #68686D;
$bp-hovered-bridged-icon-bg-color: #68686D;
$bp-selected-bridged-icon-bg-color: #68686D;

/******************************************************************/
/***********************    LeftPanel    **************************/
/******************************************************************/

$bp-muted-border: $space-40;
$bp-space-filter-bg: rgba(255, 255, 255, 0.4);
$bp-space-filter-border: transparent;
$bp-active-space-filter-bg: rgba(255, 255, 255, 0.4);
$bp-active-space-filter-border: rgba(208, 209, 216, 0.2);

$bp-new-msg-gradient: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.56) 0%, rgba(88, 94, 116, 0.56) 91.15%), #8F94A7;
$bp-new-msg-border: $space-20;
$bp-new-msg-icon: url('$(res)/img/new-message.svg');

$favourite-bg: white;
$tile-name-color: black;
$favourite-text-color: black;
$favourites-active-color: #f7f7f8;
$tile-action-hover-bg: white;
$tile-focus-bg: rgb(152 154 157);
$tile-hover-bg: rgb(216, 217, 220);
$tile-active-hover: rgb(152 154 157);
$tile-preview-color: rgb(0 0 0 / 50%);

$timestamp-color: rgb(132 128 130);
$expand-active-chats-color: #75767b;


/******************************************************************/
/*********************    Beekit Icon    **************************/
/******************************************************************/

$bp-icon-gray: #68686D;

/******************************************************************/
/************************    Composer    **************************/
/******************************************************************/

$autocomplete-selected-bg: $space-10;

// TODO restore
$left-panel-v2-bg: #f1f1f2;

$search-input-bg: #ffffff;
$search-input-border: #aeafb1;

$room-pill-bg: #F7F7F7;
$room-pill-bg-hover: #EFEFF0;
$room-pill-bg-active: #E1E2E2;
$room-pill-unread-highlight: #3884f720;

$room-tile-bg-active: #E1E2E2;
$room-tile-bg-active-transparency: #ffffff75;

$room-tile-fg: rgb(53, 54, 61);
$room-tile-fg-secondary: #68686D;

$unread-icon-color: #3884F7;
$unread-mention-icon-color: #FF976B;

$fav-icon-color: #949598;
$muted-icon-color: #949598;

$space-bar-fg: #76767B;
$space-bar-button-bg: linear-gradient(180deg, #F7F7F7 0%, #EFEFF0 100%);
$space-bar-button-bg--redesign: #ffffff;
$space-bar-filter: grayscale(1);

$space-bar-mask: black;
$badge-bg:  rgb(0 0 0);
$badge-color: rgb(255, 255, 255);
$space-bar-mask-opacity: 0.45;
$space-bar-mask-hover-opacity: 0.66;
$space-bar-bg: rgb(0 0 0 / 7%);

$send-button-fg-disabled: #949598;
$incoming-reply-thread-bg: $reply-thread-bg;

$space-item-shadow: inset 1.28333px -1.28333px 1.28333px rgba(43, 100, 188, 0.1), inset -1.28333px 1.28333px 1.28333px rgba(255, 255, 255, 0.1);
$space-item-border: 1px solid #E1E2E2;

$space-item-hover-shadow: 0px 0px 0px 2px #E1E2E2, inset 2.28px -2.28px 1.28333px rgba(43, 100, 188, 0.1), inset -0.28px -1.72px 1.28333px rgba(255, 255, 255, 0.1);
$space-item-hover-border: 2px solid #F7F7F7;

$space-item-active-shadow: 0px 0px 0px 1px #E1E2E2, inset 2.28px -2.28px 1.28333px rgba(43, 100, 188, 0.1), inset -0.28px -1.72px 1.28333px rgba(255, 255, 255, 0.1);
$space-item-active-border: 1px solid #F7F7F7;

$header-control-fg: #68686D;
$header-control-fg-hover: #828282;
$search-fg: #68686D;
$header-button-hover: #000;

$room-header-fg: #35363D;;

/******************************************************************/
/***********************    Room Toast    *************************/
/******************************************************************/

$room-toast-border: $siren-400;
$room-toast-bg: $siren-700;

/******************************************************************/
/*******************    New Chat Dropdown    **********************/
/******************************************************************/

$new-chat-bg: #ffffff;
$contact-label-bg: $light-800;
$contact-label-textcolor: $light-400;

/******************************************************************/
/*******************  New Conversation View  **********************/
/******************************************************************/

$main-split-bg: rgb(255 255 255);
$button-color: hsl(0deg 0% 0% / 33%);
$composer-bg: rgb(0 0 0 / 7%);
$compose-input-color: rgb(0, 0, 0);
$compose-send-color: hsla(220, 83%, 62%, 1);
$header-bg: #f7f3f8;
$name-text: hsl(0deg 0% 0% / 80%);
$reaction-gap-light: hsla(var(--avatar-hue, var(--default-avatar-hue)), 10%, 90%);
$reaction-gap-dark: hsla(var(--avatar-hue, var(--default-avatar-hue)), 10%, 80%);
$search-container-fg: rgb(0 0 0 / 0%);
$search-container-bg: rgb(0 0 0 / 7%);
$spacebar-badge-icon-bg: rgb(0 0 0);
$main-panel-bg: rgb(255 255 255);
$room-header-shadow: rgb(0 0 0 / 15%) 0px 1px 0px;
$body-fg: rgb(0 0 0);
$body-bg: rgb(236 232 237 / 50%);
$rooms-list-subtitle-fg: rgb(0 0 0);
$date-separator-bg: rgb(0 0 0 / 7%);
$date-separator-fg: rgb(0 0 0 / 50%);
$resize-handle-bg: rgb(0 0 0 / 15%) 1px 0px 0px 0px inset;
$transparent-resize: rgb(0 0 0 / 10%);
$left-panel-v2-bg-no-transparency: #eae8ea;
$left-panel-v2-bg-no-transparency--windows: white;
$read-receipt-fg: rgb(46 46 46);
$message-action-bg: rgba(0, 0, 0, 0.5);
$search-bg: rgb(0 0 0 / 7%);
$composer-icon-fill: hsla(0, 0%, 0%, 0.5);
$read-receipt-bg: #ededed;

:root {
    --default-avatar-hue: 200;
    --message-color-saturation: 100%;
    --message-color-lightness: 15%;
    --message-color-opacity: 1;
    --message-bg-saturation: 100%;
    --message-bg-lightness: 15%;
    --message-bg-opacity: 0.07;
    --reply-bg-opacity: 0.2;
    --reply-bg-saturation: 46%;
    --reply-bg-lightness: 81%;
    --reply-to-color-saturation: 100%;
    --reply-to-color-lightness: 10%;
    --reply-to-color-opacity: 0.8;
    --sender-name-saturation: 100%;
    --sender-name-lightness: 15%;
    --timestamp-saturation: 100%;
    --timestamp-lightness: 10%;
    --reaction-saturation: 97%;
    --reaction-lightness: 15%;
    --reaction-opacity: 0.1;
    --add-reaction-saturation: 98%;
    --add-reaction-lightness: 15%;
    --read-receipt-fg: hsla(0, 0%, 0%, 0.5);
    --read-receipt-tooltip-fg: 10%;
    --archive-background: rgb(158, 157, 162, 0.9);
    --archive-fg: black;
    --left-panel-bg: rgb(241 241 242 / 0%);
    --main-panel-bg: $main-panel-bg;
    --main-panel-transparent-bg: #1e1e1e00;
    --search-primary: black;
    --base: 0, 0, 0;
    --message-actions-fg: #d0d1d8;
    --search-bar-input-fg: #97989b;
    --search-input-bg-experimental: rgba(118, 118, 128, 0.12);
    --header-control-fg: #68686D;
    --header-control-fg-hover: #828282;
    --composer-bg: $composer-bg;

    --room-tile-bg-hover: #F7F7F7;
    --primary-content: $primary-content;
    --primary-fg-color: $primary-fg-color;
    --primary-bg-color: $primary-bg-color;
    --input-border-color: $input-border-color;
    --brand-linear-gradient: $brand-linear-gradient;
    --dialog-bg-color: $background;
    --read-check-color: #000000;
    --read-check-color-bubble: #FFFFFF;

    --tooltipText: #000;
    --tooltipBackground: $menu-bg-color;

    // SCSS var translations
    --dialog-title-fg-color: $dialog-title-fg-color;

    // Spring 2023 Space Bar in Side Panel
    --space-bar-bg: #00000007;
    --space-bar-selected: #1e1e1e50;
    --space-bar-error-icon: url("$(res)/img/x-stroke.svg");
    --floating-button-bg: white;
    --left-panel-bg-no-transparency: #eae8ea;
    --left-panel-v2-bg-no-transparency--windows: #fff;

    // Lightbox
    --lightbox-opacity: 0.95;
    --lightbox-bg-color: #2e3338;
    --lightbox-icon-color: #c1c6cd;

    // General Use Colors
    --white: #ffffff;
    --black: #000000;
    --base-100: #25262D;
    --base-150: #32343E;
    --base-200: #35363D;
    --base-300: #5C5D62;
    --base-400: #68686D;
    --base-500: #76767B;
    --base-600: #949598;
    --base-700: #E1E2E2;
    --base-800: #EFEFF0;
    --base-900: #F7F7F7;

    --siren-pale: #F1CAC9;
    --siren-full: #CE484F;
    --azure-pale: #C8D4E7;
    --azure-full: #3577ff;

    --bronzer: #F09C74;
    --luck: #54BF6F;

    // Buttons
    --button-transparent-bg: rgba(8, 8, 8, 0.05);
    --button-transparent-fg: var(--base-100);
}

.mx_MatrixChat_wrapper:not(.mx_MatrixChat_wrapper--colorful) {
    --message-color-lightness: 0;
    --reply-bg-saturation: 10%;
    --reply-bg-lightness: 70%;
    --sender-name-saturation: 10%;
    --sender-name-lightness: 0%;
    --add-reaction-saturation: 10%;
    --read-receipt-tooltip-fg: 100%;
}

.mx_MatrixChat_wrapper--transparent-main-panel {
    --message-actions-fg: #00000087;
}