.bp_UserMenu {
    height: 24px;
    width: 24px;
    position: relative;
    -webkit-app-region: no-drag;
    display: flex;
    align-items: center;
    justify-content: center;


    &::before {
        content: '';
        width: 16px;
        height: 16px;
        mask-image: url('$(res)/themes/beeper/img/icon-settings.svg');
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        background-color: $header-control-fg;
    }

    // There's problem with 10-20 topmost pixels and `cursor:pointer` (see: https://github.com/electron/electron/issues/8730)
    // So we use hover styling.
    cursor: default;
    &:hover {
        &::before {
            background-color: #aaa;
        }
    }
}
