/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_MatrixChat_wrapper--linux,
.mx_MatrixChat_wrapper--windows {
    .mx_RoomHeader {
        -webkit-app-region: unset;
    }
}

.mx_RoomHeader {
    display: flex;
    background-color: $background;
    flex-direction: column;
    position: relative;

    .bp_invisibleDraggableHeader {
        height: 15px;
    }

    .mx_RoomHeader_e2eIcon {
        height: 12px;
        width: 12px;

        .mx_E2EIcon {
            margin: 0;
            position: absolute;
            height: 12px;
            width: 12px;
        }
    }

    .mx_RoomHeader_rightActions .mx_RoomHeader_rightActions_static {
        opacity: 1;
        gap: 10px;
        display: flex;
        align-items: center;
    }

    &:hover {
        max-height: 100px;
        .mx_RoomHeader_topic,
        .mx_RoomHeader_rightActions,
        .mx_RoomHeader_rightActions span > div,
        .mx_RoomHeader_leftActions span > div {
            opacity: 1;
        }
        .mx_RoomHeader_rightActions span,
        .mx_RoomHeader_leftActions span {
            gap: 10px;
        }
        .mx_RoomHeader_centerActions.mx_RoomHeader_centerActions--topic {
            transform: scale(0.75) translateY(-15px);
        }
        
    }
}

.mx_RoomHeader_name-container { 
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.mx_RoomHeader_tooltip {
    margin-right: 0;
}

.mx_RoomHeader_wrapper {
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
    background: inherit;
    z-index: 2;

    .mx_InviteOnlyIcon_large {
        margin: 0;
    }
}

.mx_RoomHeader_spinner {
    flex: 1;
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
}

.mx_RoomHeader_textButton {
    @mixin mx_DialogButton;
    margin-right: 8px;
    margin-top: -5px;
}

.mx_RoomHeader_textButton:hover {
    @mixin mx_DialogButton_hover;
}

.mx_RoomHeader_textButton_danger {
    background-color: $alert;
}

.mx_RoomHeader_cancelButton {
    cursor: default;
    padding-left: 12px;
    padding-right: 12px;
}

.mx_RoomHeader_buttons {
    display: flex;
    -webkit-app-region: no-drag;
}

.mx_RoomHeader_info {
    display: flex;
    flex: 1;
    align-items: center;
}

.mx_RoomHeader_simpleHeader {
    line-height: $font-52px;
    color: $primary-content;
    font-size: $font-18px;
    font-weight: $font-semi-bold;
    overflow: hidden;
    margin-left: 63px;
    text-overflow: ellipsis;
    width: 100%;

    .mx_RoomHeader_cancelButton {
        float: right;
    }

    .mx_RoomHeader_icon {
        margin-left: 14px;
        margin-right: 24px;
        vertical-align: -4px;
    }
}

.mx_RoomHeader_name {
    flex: 0 1 auto;
    overflow: hidden;
    color: $room-header-fg;
    font-weight: $font-semi-bold;
    font-size: $font-14px;
    line-height: 16px;
    border-radius: 6px;
    display: flex;
    // user-select: none;

    // &:hover {
    //     background-color: $quinary-content;
    // }

    .mx_RoomHeader_nametext {
        line-height: 2;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 15px;
        color: $name-text;
    }

    .mx_RoomHeader_chevron {
        align-self: center;
        width: 16px;
        height: 16px;
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-image: url('$(res)/img/feather-customised/chevron-down.svg');
        background-color: $tertiary-content;
    }

    &[aria-expanded=true] {
        // background-color: $quinary-content;

        .mx_RoomHeader_chevron {
            transform: rotate(180deg);
        }
    }
}

.mx_RoomHeader_settingsHint {
    color: $settings-grey-fg-color !important;
}

.mx_RoomHeader_searchStatus {
    font-weight: normal;
    opacity: 0.6;
}

.mx_RoomHeader_name,
.mx_RoomHeader_avatar {
    cursor: default;
}

.mx_RoomHeader_leftActions {
    display: flex;
    padding-right: 10px;
    flex-basis: min-content;
}

.mx_RoomHeader_leftActions,
.mx_RoomHeader_leftActions {
    flex: 0;
}

.mx_RoomHeader_centerActions {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    min-width: 0;
    overflow: hidden;
    flex: 1;
}

.mx_RoomHeader_rightActions,
.mx_RoomHeader_leftActions,
.mx_RoomHeader_centerActions {
    display: flex;
    justify-content: center;
}

.mx_RoomHeader_rightActions span {
    margin-left: auto;
}

.mx_RoomHeader_leftActions span {
    margin-right: auto;
}
.mx_RoomHeader_leftActions span,
.mx_RoomHeader_rightActions span{
    display: flex;
    align-items: center;
    color: $bp-icon-gray;
    fill: $bp-icon-gray;
    will-change: gap;
    gap: 0px;
    transition-delay: 200ms;

    .mx_AccessibleButton:hover {
        color: $header-button-hover;
        fill: $header-button-hover;
    }
}
.mx_RoomHeader_centerActions,
.mx_RoomHeader_topicContainer,
.mx_RoomHeader_rightActions,
.mx_RoomHeader_leftActions,
.mx_RoomHeader_rightActions span,
.mx_RoomHeader_leftActions span,
.mx_RoomHeader_leftActions span > div,
.mx_RoomHeader_rightActions span > div,
.mx_RoomHeader,
.mx_RoomHeader_topic {
    transition: all 300ms ease 100ms;
}

.mx_RoomHeader_rightActions span > div:not(.mx_HeaderButtons),
.mx_RoomHeader_topic,
.mx_RoomHeader_leftActions span > div:not(.mx_RoomHeader_addContact) {
    opacity: 0;
}

.mx_RoomHeader_topic {
    z-index: -1;
    border-bottom: 1px solid $transparent-resize;
    padding: 2px 10px;
    color: $roomtopic-color;
    background: inherit;
    font-weight: 400;
    font-size: $font-13px;
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
    max-height: 2.4em;
    user-select: text;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

    &.mx_RoomHeader_topic--empty {
        padding: 0;
    }
}

.mx_RoomHeader_topicContainer {
    transform: translateY(-100%);
    text-align: center;
    background-color: transparent;
    z-index: 12;
    top: 100%;
    position: absolute;
    left: 0;
    right: 0;
}

.mx_RoomHeader_avatar {
    flex: 0;
    position: relative;
}

.mx_RoomHeader_avatar .mx_BaseAvatar_image {
    object-fit: cover;
}

.mx_RoomHeader_BridgedIcon {
    width: 18px;
    height: 18px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .nv_BridgedIcon {
        width: 18px;
        height: 18px;
        background-size: 18px;
    }
}

.mx_RoomHeader_button {
    position: relative;
    margin-left: 1px;
    margin-right: 1px;
    cursor: default;
    height: 32px;
    width: 32px;
    border-radius: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 4px; // center with parent of 32px
        left: 4px; // center with parent of 32px
        height: 24px;
        width: 24px;
        mask-repeat: no-repeat;
        mask-size: contain;
    }
}

.mx_RoomHeader_button::before {
    background-color: $button-color
}

.mx_RoomHeader_forgetButton::before {
    mask-image: url('$(res)/img/element-icons/leave.svg');
    width: 26px;
}

.mx_RoomHeader_appsButton::before {
    mask-image: url('$(res)/img/element-icons/room/apps.svg');
}
.mx_RoomHeader_appsButton_highlight::before {
    background-color: $accent;
}

.mx_RoomHeader_searchButton::before {
    mask-image: url('$(res)/img/element-icons/room/search-inset.svg');
}

.mx_RoomHeader_voiceCallButton::before {
    mask-image: url('$(res)/img/element-icons/call/voice-call.svg');

    // The call button SVG is padded slightly differently, so match it up to the size
    // of the other icons
    mask-size: 20px;
    mask-position: center;
}

.mx_RoomHeader_videoCallButton::before {
    mask-image: url('$(res)/img/element-icons/call/video-call.svg');
}

@media only screen and (max-width: 480px) {
    .mx_RoomHeader_wrapper {
        padding: 0;
        margin: 0;
    }
    .mx_RoomHeader {
        overflow: hidden;
    }
}
