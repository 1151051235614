.mx_MatrixChat_wrapper--windows, .mx_MatrixChat_wrapper--linux {
    .bp_LeftPanel {
        background-color: $left-panel-v2-bg-no-transparency--windows;

        &.bp_LeftPanel--transparent {
            background-color: var(--left-panel-bg);
        }
    }
}

.bp_LeftPanel {
    display: flex;
    flex-direction: column;
    width: 384px;
    min-width: 247px;
    max-width: 624px;
    background-color: $left-panel-v2-bg-no-transparency;
    user-select: none;
    padding: 0;
    overflow: hidden;

    &.bp_LeftPanel--transparent {
        background-color: var(--left-panel-bg);
    }

    .bp_LeftPanel_contentWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 100ms ease;
        transform: translateX(0px);
    }

    .bp_LeftPanel_contentWrapper--search {
        transition: transform 75ms ease;
        transform: translateX(var(--offset, -100%));
    }

    .bp_LeftPanel_content {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        overflow: auto;

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        &:first-of-type {
            display: flex;
            flex-direction: column
        }

        &:nth-of-type(2), &:last-of-type {
            margin-left: -10px;
            width: calc(100% + 6px);
        }

        &:nth-of-type(2) {
            transform: translateX(100%);
        }

        &:last-of-type {
            transform: translateX(200%);
        }
    }

    .flex-center {
        display: flex;
        align-items: center;
        height: 12px;
    }

    ._LeftPanel-action {
        cursor: default;
        width: 15px;
        padding: 0px 5px;
        display: inline-block;
        height: 10px;
        color: $search-fg;

        .bp_icon {
            height: 22px;
        }
    }

    .bp_ProtocolsToggle .icon {
        background-color: $space-bar-fg;
    }

    .rooms {
        overflow-anchor: none;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0;

        &::-webkit-scrollbar {
            display: none;
        }

        &_actions {
            display: flex;
            position: relative;
            padding: 5px 18px;

            .mx_AccessibleButton {
                position: absolute;
                right: 0;
            }
        }

        &_filters {
            align-items: baseline;
            gap: 10px;
            display: flex;
        }

        .unread_hint {
            display: flex;
            padding: 8px;
            text-align: center;
            color: rgba(var(--base), 0.5);
            font-size: 12px;
            height: 25px;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            align-items: center;
        }

        &_subtitle-container {
            background: $left-panel-v2-bg;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 0;
            margin-bottom: 0;
            width: 100%;
            z-index: 11;
            min-height: 20px;
            align-items: center;
            padding-right: 10px;
            position: sticky;
            top: var(--top, -0.5px);
            margin-left: -17px;
        }

        &_subtitle {
            color: rgb(var(--base));
            font-size: 14px;
            font-weight: 700;
            margin-left: 0;
            margin-bottom: 0;
            cursor: default;
            margin-top: 0;
            display: flex;
            gap: 5px;
            opacity: 1;
            padding-left: 38px;

            &-collapse {
                padding: 0 5px;
                opacity: 0;
            }
        }

        &_mark-read {
            background-image: url("$(res)/img/icons-sweep.svg");
            background-size: 15px;
            width: 15px;
            background-repeat: no-repeat;
            height: 11px;
            align-self: flex-end;
            cursor: default;
            padding: 5px;
            display: inline-block;
            background-position: center;
        }

        .rooms_scroll-container {
            position: relative;
            overflow: auto;
            overflow-x: hidden;
            margin-right: -6px;
            height: 100%;
            padding-bottom: 64px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .unread.rooms__section {
            > .bp_RoomTile {
                &:first-child {
                    .outline {
                        margin: 4px 4px 0px 4px;
                    }
                }
                &:last-child:not(:only-child) {
                    .outline {
                        margin: 0px 4px 4px 4px;
                    }
                }
                .outline {
                    margin: 0px 4px;
                }
            }
        }

        .favourites {
            margin-top: 0;
            margin-bottom: 0;
            z-index: 8;
            margin-right: 0;
            position: relative;
            padding: 0 10px;
            border-radius: 10px;
            position: sticky;
            top: 0;
            z-index: 8;

            &.rooms__section {
                background-color: transparent;
            }

            &__icons {
                margin-right: -6px;
            }

            &__row {
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                gap: 12px;
            }

            &__tiles {
                flex: 1;
                gap: 0px;
                row-gap: 12px;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
                border-radius: 10px;
                justify-content: space-around;
                padding-left: 0;
            }
        }
    }

        .unread.rooms__section .bp_RoomTile {
            &.active {
                .outline {
                    background: $left-panel-v2-bg;
                }
            }

            &:focus {
                .outline {
                    background: $left-panel-v2-bg;
                }
            }
        }

        .rooms__section {
            border-radius: 10px;
            background-color: $favourites-bg-color;

            &.unread {
                background-color: $unreads-grouped-bg-color;
            }
        }
    }

.bp_invisibleDraggableHeader {
    -webkit-user-select: none;
    -webkit-app-region: drag;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 1;
}

.bp_Header {
    -webkit-user-select: none;
    -webkit-app-region: drag;
    margin-top: 10px;
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .userAvatar {
        cursor: default;
    }

    .controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 0;
        gap: 7px;
        flex-direction: row-reverse;
        margin-left: auto;
        margin-right: 0px;

        .newChatButton:before {
            mask-image: url("$(res)/img/icon-pencil.svg");
            mask-size: 16px;
            mask-repeat: no-repeat;
            mask-position: center;
        }

        .mx_AccessibleButton:before {
            background-color: $header-control-fg;
        }

        .newChatButton {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                mask-image: url("$(res)/img/icon-new-chat.svg");
                mask-size: cover;
            }
        }

        .bp_UserMenu {
            cursor: default;
            width: 24px;
            height: 24px;

            &:before {
                mask-image: url("$(res)/themes/beeper/img/icon-settings-outline.svg");
            }
        }

        .mx_AccessibleButton:hover:not(.mx_AccessibleButton_disabled):before {
            background-color: $header-control-fg-hover;
        }
    }
}

.bp_LeftPanel .spaceBar {
    padding: 4px 10px;
    margin-bottom: 3px;
    color: $space-bar-fg;

    .spaceBar_plus {
        mask-image: url("$(res)/img/social/lineart/add-space.svg");
        width: 30px;
        height: 30px;
        background-color: $space-bar-mask;
        opacity: $space-bar-mask-opacity;
        background-image: none;
        mask-size: 24px;
        mask-repeat: no-repeat;
        mask-position: 4px 6px;

        &:hover {
            opacity: $space-bar-mask-hover-opacity;
        }
    }

    .grid {
        display: grid;
        grid-gap: 6px;
        grid-template-columns: repeat(auto-fill, 32px);
        // justify-content: space-between;
        position: relative;
        top: 0px;

        > * {
            min-width: 32px;
        }

        .bp_ProtocolsToggle {
            margin: 0;
        }

        .bp_ProtocolsToggle {
            grid-row-start: 1;
            grid-column-end: -2;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover .icon {
                opacity: $space-bar-mask-hover-opacity;
            }

            &:before {
                border: none;
                background: none;
            }

            .icon {
                mask-image: url("$(res)/img/social/lineart/expand.svg");
                background-color: $space-bar-mask;
                opacity: $space-bar-mask-opacity;
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .plus {
            width: 44px;
            height: 44px;
            margin: -9px;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-row-start: 1;
            grid-column-end: -1;

            &.grid-end {
                grid-column-end: -1;
            }

            .bp_icon {
                height: 16px;
            }
        }
    }

    &.collapsed {
        height: 30px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .bp_SpaceBarItem {
        position: relative;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        border: none;
        width: 32px;
        height: 32px;
        background: none;
        flex-direction: column;

        &_network {
            font-size: 6px;
            margin: 0;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            padding-bottom: 6px;
        }

        img {
            width: 20px;
            height: 20px;
            border-radius: 3px;
        }

        &:hover img,
        &:hover .bp_Avatar {
            filter: revert;
        }

        &_image {
            width: 30px;
            height: 30px;
            background-size: 24px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $space-bar-mask;
            opacity: $space-bar-mask-opacity;
            background-image: none;
            mask-size: 24px;
            mask-repeat: no-repeat;
            mask-position: center;

            &.inbox {
                mask-image: url("$(res)/img/social/lineart/smart-inbox.svg");
            }
            &.bookmarks {
                mask-image: url("$(res)/img/social/lineart/bookmarks.svg");
                background-size: 24px;
            }
            &.history {
                mask-image: url("$(res)/img/social/lineart/all-messages.svg");
                background-size: 24px;
            }
        }

        &:hover,
        &.active {
            .bp_SpaceBarItem_image {
                &.inbox {
                    mask-image: url("$(res)/img/social/lineart/smart-inbox.svg");
                }
                &.bookmarks {
                    mask-image: url("$(res)/img/social/lineart/bookmarks.svg");
                }
                &.history {
                    mask-image: url("$(res)/img/social/lineart/all-messages.svg");
                }
            }
        }

        .bp_SpaceBarItem_badge {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 18px;
            box-sizing: border-box;
            font-size: 10px;
            font-weight: 700;
            border-radius: 50%;
            border: none;
            height: 16px;
            width: 16px;
            top: -3px;
            right: -3px;
            color: $badge-color;
            background-color: $badge-bg;

            &_icon {
                width: 16px;
                height: 16px;
                position: absolute;
                top: -2px;
                right: -4px;
                left: revert;

                &.error {
                    background-image: url("$(res)/img/x-stroke.svg");
                }
            }
        }

        &.active,
        &.active:hover {
            background-color: $space-bar-bg;
            box-shadow: none;
            .bp_SpaceBarItem_image {
                opacity: 1;
            }
        }
        &:hover {
            background-color: $tile-focus-bg;
            .bp_SpaceBarItem_image {
                opacity: $space-bar-mask-hover-opacity;
            }
        }

        &:hover,
        &.active {
            .bp_SpaceBarItem_badge {
                top: -3px;
                right: -3px;
                &_icon {
                    top: -2px;
                    right: -4px;
                    left: revert;
                }
            }
        }
    }
}

.bp_LeftPanel .message-list {
    overflow: auto;
    padding-left: 5px;
    padding-bottom: 10px;
    flex: 1;

    .mx_EventTile_messageOut .mx_MessageBody,
    .mx_EventTile_messageIn .mx_MessageBody {
        grid-template-columns: 32px minmax(auto, max-content) minmax(35%, auto);
    }
    .mx_EventTile_line .mx_SenderInfo_wrapper {
        display: none;
    }

    .mx_SenderProfile_displayName {
        margin-left: 4px;
    }

    .message-tile {
        margin: 0 5px;
    }

    .mx_SenderInfo_wrapper {
        flex-wrap: wrap;
    }

    .mx_EventTile_line {
        grid-column-start: 2;
        grid-column-end: -1;
    }

    .mx_EventTile {
        list-style: none;
        width: 100%;
        padding: 0 5px;
    }
}

.read {
    flex-grow: 1;
    position: relative;
    overflow-anchor: none;
    transition: opacity 250ms ease;

    &:hover,
    .opaque,
    &:focus-within {
        .bp_RoomTile {
            opacity: 1;
            &:first-of-type {
                opacity: 1;
            }
            &:nth-of-type(2) {
                opacity: 1;
            }
            &:nth-of-type(3) {
                opacity: 1;
            }
        }
    }

    .bp_RoomTile {
        transition: opacity 250ms ease;
        opacity: 0;
        &:is(.focus-visible) {
            opacity: 1;
        }
        &:first-of-type {
            opacity: 0.25;
        }
        &:nth-of-type(2) {
            opacity: 0.2;
        }
        &:nth-of-type(3) {
            opacity: 0.15;
        }
    }
}

.mx_MatrixChat_wrapper {
    .bp_LeftPanel .spaceBar .bp_SpaceBarItem_image.bp_SpaceBarItem_image--protocol {
        background-color: revert;
        opacity: 0.9;
    }
    .bp_LeftPanel .spaceBar .bp_SpaceBarItem:hover .bp_SpaceBarItem_image.bp_SpaceBarItem_image--protocol {
        opacity: 1;
    }
    .bp_LeftPanel .spaceBar .bp_SpaceBarItem_image {
        &.bp_SpaceBarItem_image--protocol, &.bp_SpaceBarItem_image--protocol:hover {
            mask-image: none;
            image-rendering: pixelated;
        }
        &.androidsms {
            background-image: $androidsms-logo;
        }
        &.beeper {
            background-image: $beeper-logo;
        }
        &.discord {
            background-image: $discord-logo;
        }
        &.facebook {
            background-image: $facebook-logo;
        }
        &.googlechat {
            background-image: $googlechat-logo;
        }
        &.hangouts {
            background-image: $hangouts-logo;
        }
        &.imessage {
            background-image: $imessage-logo;
        }
        &.instagram {
            background-image: $instagram-logo;
        }
        &.linkedin {
            background-image: $linkedin-logo;
        }
        &.matrix {
            background-image: $matrix-logo;
        }
        &.signal {
            background-image: $signal-logo;
        }
        &.slack {
            background-image: $slack-logo;
        }
        &.telegram {
            background-image: $telegram-logo;
        }
        &.twitter {
            background-image: $twitter-logo;
        }
        &.whatsapp {
            background-image: $whatsapp-logo;
        }
        &.gmessages {
            background-image: $gmessages-logo;
        }
    }

    .bp_LeftPanel .spaceBar .bp_SpaceBarItem_image:not(.bp_SpaceBarItem_image--protocol) {
        background-color: $spacebar-badge-icon-bg !important;
    }
}

.spaceBarItemTooltip {
    padding: 4px 6px;
}
