.nv_BridgedIcon {
    width: 16px;
    height: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: revert;
    background-repeat: no-repeat;
    opacity: 1;
    mask: revert;
    transform: scale(1);
    image-rendering: crisp-edges;
    mask-image: none;
}

.nv_BridgedIcon::before {
    content: "";
    flex-shrink: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.nv_BridgedIcon_facebook {
    background-image: $facebook-logo;
}
.nv_BridgedIcon_googlechat,
.nv_BridgedIcon_hangouts {
    background-image: $googlechat-logo;
}
.nv_BridgedIcon_telegram {
    background-image: $telegram-logo;
}
.nv_BridgedIcon_whatsapp {
    background-image: $whatsapp-logo;
}
.nv_BridgedIcon_gmessages {
    background-image: $gmessages-logo;
}
.nv_BridgedIcon_instagram {
    background-image: $instagram-logo;
}
.nv_BridgedIcon_twitter {
    background-image: $twitter-logo;
}
.nv_BridgedIcon_slack {
    background-image: $slack-logo;
}
.nv_BridgedIcon_beeper {
    background-image: $beeper-logo;
}
.nv_BridgedIcon_matrix {
    background-image: $matrix-logo;
}
.nv_BridgedIcon_discord {
    background-image: $discord-logo;
}
.nv_BridgedIcon_skype {
    background-image: url("$(res)/img/social/lineart-color/skype.svg");
}
.nv_BridgedIcon_androidmessages {
    background-image: url("$(res)/img/social/lineart-color/sms.svg");
}
.nv_BridgedIcon_android-sms,
.nv_BridgedIcon_androidsms {
    background-image: $androidsms-logo;
}
// TODO different icon for SMS?
.nv_BridgedIcon_imessage,
.nv_BridgedIcon_imessagecloud,
.nv_BridgedIcon_imessage-sms,
.nv_BridgedIcon_imessage-nosip,
.nv_BridgedIcon_imessage-ios {
    background-image: $imessage-logo;
}
.nv_BridgedIcon_signal {
    background-image: $signal-logo;
    background-color: revert;
}
.nv_BridgedIcon_linkedin {
    background-image: $linkedin-logo;
    background-color: revert;
}
