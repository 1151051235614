.bp_Select_wrapper {
  input {
      display: none;
  }

  label {
      cursor: default;
      position: relative;
  }

  &.grid {
    display: grid;
    grid-template-columns: 144px 144px 144px 144px;
    grid-gap: 12px;
  }

  .checkmark {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid $bp-input-border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;

    .bp_icon {
        display: none;
    }
  }

  .radio {
    width: 18px;
    height: 18px;
    background-color: #F8F8FB;
    border: 2px solid #A1A4B0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;

    .bp_icon {
        display: none;
    }
  }

  input:checked ~ .checkmark {
    border-color: $azure-500;

    .bp_icon {
      display: block;
    }
  }

  input:checked ~ .radio {
    .bp_icon {
      display: block;
    }
  }
}

.bp_Select {
    // background-color: $primary-bg-color;
    border: 0.25px solid $roomtile-hover-bg-color;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.selected {
        border-color: $azure-500;
        // background-color: $azure-700;
        background-color: #C8D4E715;
    }

    &.disabled {
        background-color: $left-panel-bg;
        opacity: .5;
        cursor: not-allowed;
    }

    &.center {
        align-items: center;
        justify-content: center;
    }
}

/********** Deprecated */

.bp-radio {
  background-color: $primary-bg-color;
  border: 0.25px solid $roomtile-hover-bg-color;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 400px;

  &.bp-radio-disabled {
    background-color: $left-panel-bg;
  }
}