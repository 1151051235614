.reset-style, .reset-style * {
  all: revert;
}

.bp_row {
  display: flex;
  flex-direction: row;

  &.center {
    align-items: center;
  }
}

.bp_col {
  display: flex;
  flex-direction: column;
}

.mx_Dialog_buttons {    
    text-align: left;
}

.bp_VerificationPanel_QR {
    display: flex;

    .description {
        margin-left: 20px;
    }
}

// Custom header for QR modal
.bp_VerificationRequestDialog .mx_Dialog_header {
    display: none;
}

.bp_Splash {
    z-index: 10001;

    .mx_Dialog_title {
        color: #fff;
    }

    .mx_Field label {
        color: $input-border-color;
    }

    .mx_Field input,
    .mx_Field select,
    .mx_Field textarea {
        color: #fff;
    }

    .mx_Dialog button,
    .mx_Dialog input[type='submit'],
    .mx_Dialog_buttons button,
    .mx_Dialog_buttons input[type='submit'],
    .mx_AccessibleButton_hasKind:not(.mx_AccessibleButton_kind_link) {
        color: #fff;
    }

    .mx_Dialog_title {
        font-size: 32px;
        font-weight: 800;
    }

    .mx_Dialog_fixedWidth {
        max-width: 562px;
    }

    .mx_Dialog_cancelButton {
        display: none;
    }

    .mx_AccessSecretStorageDialog_recoveryKeyFeedback_valid {
        color: #999;

        &:before {
            background-color: #999;
        }
    }
    
    .mx_Dialog_background {
        background: #131B3A;
        opacity: 1;
        pointer-events: none;

        background-image: url('$(res)/themes/beeper/img/beeper-text.png');
        background-position-x: center;
        background-position-y: 20px;
        background-repeat: no-repeat;
        background-size: 120px;

        &:before, &:after {
            content: '';
            display: block;
            width: 240px;
            height: 90px;
            position: absolute;
            filter: blur(100px);
        }
        &:before {
            background: linear-gradient(
                177.05deg,
                rgb(255, 155, 113) 3.09%,
                rgb(230, 96, 206) 96.24%
            );
            left: 30%;
            bottom: 30%;
            transform: translateX(-50%) translateY(50%);
        }
        &:after {
            background: linear-gradient(
                177.05deg,
                rgb(181, 71, 233) 3.09%, 
                rgb(73, 88, 245) 96.24%
            );
            right: 30%;
            top: 30%;
            transform: translateX(50%) translateY(-50%);
        }
    } 

    

    &_framed {
        .mx_Dialog {
            background: none;
            box-shadow: none;
            padding: 0;
            overflow: visible;

            &:before, &:after {
                display: block;
                content: '';
                position: absolute;
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 240px;
                height: 120px;
                z-index: -1;
            }            

            &:before {
                background-image: url('$(res)/themes/beeper/img/balloon-left.svg');
                left: -120px;
                bottom: -75px;
                background-position-y: bottom;
            }

            &:after {
                background-image: url('$(res)/themes/beeper/img/balloon-right.svg');
                right: -120px;
                top: -45px;
            }

            .mx_Dialog_body {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(19, 27, 58, 0) 0.01%, rgba(19, 27, 58, 0.4) 100%),
                    linear-gradient(0deg, #32384E, #32384E);
                box-shadow: 0px 8px 48px rgba(19, 27, 58, 0.36);
                border-radius: 16px;
                padding: 40px;
            }
        }
    }
}

/**
 * Buttons
 */
.mx_Dialog button,
.mx_Dialog input[type='submit'],
.mx_Dialog_buttons button,
.mx_Dialog_buttons input[type='submit'],
.mx_AccessibleButton_hasKind:not(.mx_AccessibleButton_kind_link):not(.mx_AccessibleButton_kind_link_inline):not(.mx_MatrixChat_splashButtons) {
    box-shadow: inset 0px 0px 0px 2px #585E74;
    background: none;
    color: $primary-fg-color;
    border-radius: 28px;
    padding: 4px 24px;
    border: none;
}

.mx_Dialog button.mx_Dialog_primary,
.mx_Dialog input[type='submit'].mx_Dialog_primary,
.mx_Dialog_buttons button.mx_Dialog_primary,
.mx_Dialog_buttons input[type='submit'].mx_Dialog_primary,
.mx_AccessibleButton_kind_primary {
    box-shadow: inset 0px 0px 0px 2px $accent-color !important;
}

.mx_Dialog button.danger,
.mx_Dialog input[type='submit'].danger,
.mx_Dialog_buttons button.danger,
.mx_Dialog_buttons input[type='submit'].danger,
.mx_AccessibleButton_kind_danger {
    box-shadow: inset 0px 0px 0px 2px #E0474F !important;
}

// \

.mx_Dialog_buttons, .mx_SecureBackupPanel_buttonRow {
    margin-bottom: -10px;

    > * {
        margin-bottom: 10px;
        margin-right: 8px;
    }
}


.mx_TabbedView_tabLabel {
    border-radius: 12px;
}
.mx_TabbedView_maskedIcon {
    margin-right: 8px;
}
.mx_TabbedView_tabLabel_text {
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mx_QuestionDialog {
    max-width: 500px;
}

.bp_GoogleContactLoginDialog {
    > .mx_Dialog_content {
        margin-bottom: 24px;
    }

    > .mx_Dialog_buttons {
        text-align: center;
        margin-bottom: 0;

        > button {
            box-shadow: unset !important;
            padding: 0;
            margin: 0;
        }
    }
}

.bp_Dialog_small {
    width: auto;

    .mx_Dialog_content {
        margin: 1rem 0 0 0;
    }
}

.mx_MatrixChat > .mx_ResizeHandle, .mx_RightPanel_ResizeWrapper .mx_ResizeHandle {
    margin: 0px -9.5px 0px -2.5px;
    box-shadow: $transparent-resize 1px 0px 0px 0px inset;
}

.mx_MatrixChat > .mx_ResizeHandle {
    margin-right: -9px;
}

.mx_RightPanel_ResizeWrapper .mx_ResizeHandle {
    left: -3px !important;
}

.mx_MatrixChat.resizing {
    cursor: col-resize;
}

.mx_MatrixChat:not(.resizing) {
    .bp_LeftPanel {
        transition: width .2s ease-in-out;
    }
}

.mx_MatrixChat > .mx_ResizeHandle:hover, 
.mx_MatrixChat.resizing > .mx_ResizeHandle,
.mx_RightPanel_ResizeWrapper .mx_ResizeHandle:hover,
.mx_RightPanel_ResizeWrapper .mx_ResizeHandle.resizing {
    box-shadow: inset 4px 0px 0px 0px $dark-blue;
}


.bp_Spinner_block {
    margin: 30px 0;
}

@media
not screen and (-webkit-min-device-pixel-ratio: 2),
not screen and (   min--moz-device-pixel-ratio: 2),
not screen and (     -o-min-device-pixel-ratio: 2/1),
not screen and (        min-device-pixel-ratio: 2),
not screen and (                min-resolution: 192dpi),
not screen and (                min-resolution: 2dppx) { 

  /* non-Retina-specific stuff here */
  span.emoji {
    margin-right: 4px;
  }
}

.link {
  color: #4958F5;

  &:hover {
    text-decoration: underline;
  }
}

.monospace {
  font-family: monospace !important;
}

.baseText {
  color: $body-fg;
}
.line_limit {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  &.one {
    // line-height: 14px; /* fallback */
    // max-height: 28px; /* fallback */
    -webkit-line-clamp: 1;
  }

  &.two {
    // max-height: 32px; /* fallback */
    -webkit-line-clamp: 2;
  }
}