@import "./_colors.scss";

.bp_Button {
    /* align images in buttons (eg spinners) */
    vertical-align: middle;
    border: 0px;
    border-radius: 28px;
  
    /* font-family: Sofia-Pro; */ // TODO add fonts to project
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: auto;
    box-sizing: border-box;
    display: inline-block;
    outline: none;
    padding: 6px 24px;

    cursor: default;
}

.bp_ButtonPrimary {
  color: #fff;
  background: linear-gradient(120deg, #A93FE9 12px, #4958F5 502px);

  &:hover {
    box-shadow: 0px 4px 16px rgba(133, 73, 238, 0.24), 0px 2px 12px rgba(130, 74, 239, 0.24);
  }

  &.bp_ButtonActive {
    background: linear-gradient(120deg, #A93FE9 12px, #4958F5 502px);
  }

  &.bp_ButtonError {
    background: $siren-100;

    &:hover {
      box-shadow: none;
    }

    &.bp_ButtonActive {
      background: $siren-140;
    }
  }

  &.bp_ButtonDisabled {
    background: $bp-button-disabled-bg;
    color: $bp-button-disabled-textcolor;
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }
}

.bp_ButtonSecondary {
  border-color: $bp-button-secondary-outline;
  border-width: 2px !important;
  border-style: solid !important;

  font-weight: 400;

  padding: 2px 22px;

  background: transparent;
  color: $primary-fg-color;
  font-size: 14px;

  &:hover {
    box-shadow: none;
    filter: drop-shadow(0px 2px 12px rgba(37, 38, 45, 0.24))
  }

  &.bp_ButtonActive {
    border-color: $bp-button-secondary-active-outline;
  }

  &.bp_ButtonError {
    border-color: $siren-100;

    &.bp_ButtonActive {
      border-color: $siren-140;
    }
  }

  &.bp_ButtonDisabled {
    color: $bp-button-secondary-disabled-textcolor;
    border-color: $bp-button-secondary-disabled-outline;
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
      filter: none;
    }
  }

  &.bp_ButtonBlueBorder {
    border-color: $crayola-100;
    &.bp_ButtonActive {
      border-color: $crayola-140;
    }

    &.bp_ButtonDisabled {
      border-color: $crayola-120;
      background-color: rgba(0,0,0,0.2);
    }
  }
}

/************************************************************************/
/************************************************************************/
/************************************************************************/
/************************************************************************/

.bp_Button2 {
    position: relative;
    /* align images in buttons (eg spinners) */
    vertical-align: middle;
    border: 0px;
    border-radius: 28px;
  
    /* font-family: Sofia-Pro; */ // TODO add fonts to project
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: auto;
    min-height: 40px;
    box-sizing: border-box;
    display: inline-block;
    outline: none;
    padding: 6px 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;

    cursor: default;

    &.loading {
      opacity: 0.6;
    }

    .bp_Button2_text.loading {
      opacity: 0;
    }

    .bp_icon div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bp_Button2_spinner {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      &.bp_Button2_spinner--relative {
        position: relative;
        grid-gap: 0.75rem;
      }
    }
}

.bp_Button2Primary {
  background: $bp-button-primary-bg;
  color: $bp-button-primary-textcolor;
  border: $bp-button-primary-border;

  &:hover {
    border-color: $bp-button-hover-border;
    color: $bp-button-hover-textcolor;
    box-shadow: $bp-button-primary-hover-shadow;
  }

  &.error {
      border-color: $bp-button-error-bg;
      background: $bp-button-error-bg;
      color: $bp-button-error-textcolor;
      
      &:hover {
        box-shadow: none;
      }
  }

  &.disabled {
      color: $bp-button-disabled-textcolor;
      background: $bp-button-disabled-bg;
      border-color: $bp-button-disabled-textcolor;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
  }

  &.small {
      border-width: 1px;
      padding: 8px 16px;
      font-size: 12px;
      height: 32px;
  }
}

.bp_Button2Secondary {
  background: transparent;
  color: $bp-button-secondary-textcolor;
  border: 2px solid $bp-button-secondary-border;

  &:hover {
    border-color: $bp-button-secondary-hover-border;
    color: $bp-button-secondary-hover-textcolor;
  }

  &.error {
      border-color: $bp-button-secondary-error-border;
  }

  &.disabled {
      color: $bp-button-disabled-textcolor;
      border-color: $bp-button-disabled-textcolor;
      cursor: not-allowed;
  }

  &.small {
      border-width: 1px;
      padding: 8px 16px;
      font-size: 12px;
      height: 32px;
  }
}

.bp_ButtonPurple {
    background: linear-gradient(120deg, #A93FE9 12px, #4958F5 502px);
    color: $dark-100;
    border: 2px solid transparent;

    &:hover {
        color: $light-900;
        border-color: transparent;
        box-shadow: 0px 4px 16px rgba(133, 73, 238, 0.24), 0px 2px 12px rgba(130, 74, 239, 0.24);
    }

    &.disabled {
        background-color: $dark-600;
        color: $dark-700;
        border-color: $dark-700;
        cursor: not-allowed;
    }
}

.bp_TextButton {
  color:#4958F5;
  text-decoration-color: #4958F5;
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  &:hover {
    text-decoration-line: underline;
  }
  &.disabled {
    color: $bp-button-disabled-textcolor;
    &:hover {
      text-decoration-line: none;
    }
  }
}

.textButtonHover {
    color:#fff;
    text-decoration-color: #fff;
    text-decoration-line: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      text-decoration-line: underline;
    }
    &.disabled {
      color: $bp-button-disabled-textcolor;
      &:hover {
        text-decoration-line: none;
      }
    }
}

///////// Authentication Button
/// // TODO - move these to css modules, and/or move them into the theme file instead of here. 
/// // annie why did you even put these styles here? smh 

.bp_Authentication {
  .bp_TextButton {
      justify-content: center;
      color: #fff;
      background-color: #ffffff10;
      padding: 4px 6px;
      width: fit-content;
      height: fit-content;
      border-radius: 6px;
      &:hover {
          background-color: #ffffff15;
          text-decoration: none;
      }
      &:active {
          background-color: #ffffff25;
      }
  }
  
}