.mx_GifPicker {
  width: 345px;
  height: 450px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.mx_GifPicker_scrollPanel {
  overflow-y: scroll;
  flex: 1 1 0;
  max-height: 450px;
  width: 100%;
}

.mx_GifPicker_initialNotice {
  text-align: center;
  margin: auto;
}

.mx_GifPicker_thumbnail video {
  border: 2px solid $input-darker-bg-color;
  border-radius: 6px;
  margin-bottom: 0.8em;
  cursor: default;
  width: 100%;
  height: auto;

  &:hover {
      border-color: $strong-input-border-color;
  }
}