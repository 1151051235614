.mx_MessageComposer {
    background: transparent;
    border: none;
    border-radius: 0;
    margin: 0 !important;
    position: relative;
    padding: 0 16px 0 16px;

    // Hide waveform on smaller screens so it looks good
    &--narrow {
      .mx_Waveform {
        width: 0;
        overflow: hidden;
      }
    }

    .mx_ReplyPreview {
        margin-bottom: 8px;
    }

    &_inThread {
        .mx_ReplyPreview {
            margin-bottom: 0;
        }
        // border-top: 1px solid rgba($divider-color, 0.32) !important;
    }
}
.mx_MessageComposer_wrapper {
    padding: 0 16px;
    padding-left: 5px;
    background: var(--chatview__composer__backgroundcolor);
    border-color: var(--chatview__composer__bordercolor);
    border-radius: var(--chatview__composer__borderradius);
    border-width: var(--chatview__composer__borderwidth);
    border-style: var(--chatview__composer__borderstyle);

    .mx_BasicMessageComposer .mx_BasicMessageComposer_input.mx_BasicMessageComposer_input_shouldShowPillAvatar {
      color: $compose-input-color;
    }

    .mx_MessageComposer_send {
      background: $compose-send-color;
      background-clip: text;
    }
}
.mx_MessageComposer_e2eIcon.mx_E2EIcon {
    left:5px;
}

.mx_MessageComposer_send {
    background: $brand-linear-gradient;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: $font-14px;
    font-weight: 600;
    line-height: 16px;
    margin-left: 6px;

    &.mx_AccessibleButton_disabled {
        background-image: none;
        background-color: $button-color;
    }
}

.bp_PotentialLinkPreview {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  margin-bottom: 8px;
  padding-left: 16px;

  .mx_ReplyPreview_cancel {
    position: relative;
    top: unset;
  }

  .bp_PotentialLinkPreviewImage {
    background-color: #bbb;
    border-radius: 8px;
    width: 100px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    object-fit: cover;
  }

  .bp_LinkPreview_Image {
    border-radius: 8px;
  }
}