.nv_RoomTile2 {
    position: relative;
    padding: 4px 8px;
    padding-left: 0;
    display: flex;
    border-radius: 8px;

    &:hover {
      background-color: $roomtile-hover-bg-color;
      transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
      transition-duration: 0.3s;
    }

    .mx_DecoratedRoomAvatar {
      margin: 0 6px 0 0;
    }

    &.isUnread {
        .nv_RoomTile2_content .nv_RoomTile2_nameContainer .nv_MessageTimestamp {
            color: #5280FF;
            font-weight: 700;
        }
    }

    &.isMuted {
        &.isUnread {
            .nv_RoomTile2_content .nv_RoomTile2_nameContainer .nv_MessageTimestamp {
                color: #5280FF;
                font-weight: 700;
            }
        }
        .bp_RoomTile2_unreadCountCircle {
            background: #A1A4B0;

            display: flex;
            align-items: center;
            justify-content: center;


        }
    }

    &.hasMentions {
        .bp_RoomTile2_unreadCountCircle {
            background: #FF9B71;
            color: #995D44; 
        }

        .nv_RoomTile2_content .nv_RoomTile2_nameContainer .nv_MessageTimestamp {
            color: #FF9B71;
        }
    }

    &.nv_RoomTile2_favourite {

        &.isUnread, &.hasMentions {
            .nv_RoomTile2_nameContainer::before {
                display: inline-block;
                content: '';
                width: 8px;
                height: 8px;
                min-width: 8px;
                border-radius: 4px;                
                margin-right: 4px;
            }
            .nv_RoomTile2_nameContainer::after {
                display: block;
                content: '';
                max-width: 2px;
                height: 1px;
                flex: 1;
            }
        }
        &.isUnread .nv_RoomTile2_nameContainer::before {
            background: #5280FF;
        }
        &.hasMentions .nv_RoomTile2_nameContainer::before {
            background: #FF9B71;
        }
    }
}

.bp_RoomTile2_unreadCountCircle {
    display: inline-flex;
    width: 16px;
    min-width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background: #4958F5;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}

.mx_DecoratedRoomAvatar {
  position: relative;
  // min-width: 32px;
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 3px solid transparent;
  border-radius: 50%;
  padding: 3px;
  margin: -6px;

  &.isFavorite {
    margin: 0;

    &.isUnread {
        border-color: $dark-blue; // #5280FF
    }

    &.isMuted {
        border-color: $bp-muted-border;
    }

    &.hasMentions {
        border-color: $coral-100; // #FF9B71
    }
  }
}

.mx_DecoratedRoomAvatar .nv_NotificationBadge {
    position: absolute;
    bottom: 3px;
    left: 6px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $primary-bg-color;

    &::before {
        height: 4px;
        width: 4px;
        z-index: 1;
    }
}

// .nv_RoomTile2_unread {
//     background-color: $primary-bg-color;
// }

.nv_RoomTile2_active, .nv_RoomTile2_unread.nv_RoomTile2_active {
    background-color: $roomtile-selected-bg-color;
    color: $roomtile-title-color;
    
    &:hover {
      background-color: $roomtile-selected-bg-color;
    }

    .mx_DecoratedRoomAvatar .nv_NotificationBadge {
        background-color: $roomtile-selected-bg-color;
    }
}

.nv_RoomTile2_selected .mx_DecoratedRoomAvatar {
    &::after {
        content: "";
        width: 42px;
        height: 42px;
        grid-column: 1;
        grid-row: 1;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #00cc73;
        border-radius: 50%;
    }

    &::before {
        content: "";
        width: 17px;
        height: 14px;
        grid-column: 1;
        grid-row: 1;
        mask-image: url("$(res)/img/feather-customised/check.svg");
        mask-size: 100%;
        mask-repeat: no-repeat;
        position: absolute;
        top: 14px;
        left: 12px;
        background-color: #ffffff; // this is fine without a var because it's for both themes
        z-index: 1;
    }

    .nv_BridgedIcon {
        z-index: 2;
    }
}

.nv_RoomTile2_bridgedIcon_selected {
    background-color: #00cc73;
    border-radius: 50%;
    &::before {
        background-image: none !important;
        content: "";
        width: 17px;
        height: 14px;
        grid-column: 1;
        grid-row: 1;
        mask-image: url("$(res)/img/feather-customised/check.svg");
        mask-size: 100%;
        mask-repeat: no-repeat;
        background-color: #ffffff; // this is fine without a var because it's for both themes
        z-index: 1;
    }
}

.nv_RoomTile2_badgeContainer {
    position: absolute;
    left: 15px;
    top: 19px;
}

.bp_RoomTile2_contextButtonWrapper {
    background-color: rgba(219, 222, 253, 1);
    min-width: 16px;
    width: 16px;
    height: 16px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .bp_RoomTile2_contextButton {
        width: 16px;
        height: 16px;
        background-color: rgba(73, 88, 245, 1);
        mask: url('$(res)/themes/beeper/img/icon-more.svg');
        mask-size: cover;
    }
}

.nv_RoomTile2_content {
    // margin-left: 8px;
    display: flex;
    min-width: 0;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .nv_RoomTile2_nameContainer {
        flex: 1;
        display: flex;
        max-height: 18px;
        line-height: 14px;
        align-items: center;
        max-width: 100%;

        .nv_RoomTile2_bridgedIcon {
            width: 16px;
            height: 16px;
            margin-left: 6px;
        }

        .bp_RoomTile2_contextButtonWrapper {
          margin-left: 4px;
        }

        .nv_RoomTile2_name {
            line-height: 2em;
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            
            &HasUnreadEvents {
                font-weight: 700;
            }
            &Favourite {
                flex: unset;
            }
        }
        .nv_RoomTile2_nameWithPreview {
            > img {
                height: 8px;
                margin-left: 4px;
            }
        }
        .nv_MessageTimestamp {
            font-weight: 400;
            position: relative;
            margin-left: 8px;
            font-size: 10px;
            color: $room-list-preview-color;
        }

        .nv_RoomTile2_favouriteIcon, .nv_RoomTile2_inviteIcon {
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;

            &::before {
                content: "";

                margin: 0;
                border-radius: 0;
                width: 12px;
                height: 12px;
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: center;
            }
        }
        .nv_RoomTile2_favouriteIcon::before {
            mask-image: url('$(res)/themes/beeper/img/icon-star.svg');
            background-color: #D0D1D8;
            width: 8px;
            height: 8px;
        }
        .nv_RoomTile2_inviteIcon::before {
            mask-image: url('$(res)/themes/beeper/img/icon-invite.svg');
            background-color: $invite-icon-color;
        }

        .nv_RoomTile2_favouriteIcon {
            flex: 1;
            justify-content: flex-start;
        }
    }

    .nv_RoomTile2_favouriteIcon {
        width: 8px;
        height: 8px;
    }

    .nv_RoomTile2_messagePreview {
        overflow: hidden;
        color: $room-list-preview-color;
        font-size: 12px;
        line-height: 14px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        margin-left: 4px;
    }
    .nv_RoomTile2_messagePreview > img {
        height: 10px;
        margin-right: 2px;
    }
}

.nv_RoomTile2_smallRow {
    padding: 10px 12px;

    .nv_RoomTile2_content {
        margin: 3px 0 3px 14px;
        flex-direction: row;
        justify-content: flex-start;
        overflow: hidden;
    }
    .nv_RoomTile2_nameWithPreview {
        > img {
            height: 8px;
            margin-left: 4px;
        }
    }
    .nv_BridgedIcon {
        filter: none;
        position: static;
        flex-shrink: 0;
    }
    .nv_RoomTile2_badgeContainer {
        left: 7px;
        top: 7px;
    }
}

.mx_RoomTile2_contextMenu .mx_IconizedContextMenu_icon {
    position: relative;
    width: 16px;
    height: 16px;

    &::before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        background: $primary-fg-color;
    }
}

.nv_RoomTile2_contextMenu_activeRow {
    &.mx_AccessibleButton, .mx_AccessibleButton, .mx_IconizedContextMenu_label {
        color: $accent-color !important; // !important to override styles from context menu
    }

    .mx_IconizedContextMenu_icon::before {
        background-color: $accent-color;
    }
}

.nv_RoomTile2_iconStar::before {
    mask-image: url("$(res)/themes/beeper/img/icon-heart.svg");
}

.nv_RoomTile2_iconArchive::before {
    mask-image: url("$(res)/img/beeper/low-priority16.svg");
}

.nv_RoomTile2_iconUnarchive::before {
    mask-image: url("$(res)/img/beeper/inbox16.svg")
}

.nv_RoomTile2_iconPin::before {
    mask-image: url('$(res)/themes/beeper/img/icon-pin.svg');
}

.nv_RoomTile2_iconCopyLink::before {
    mask-image: url('$(res)/img/element-icons/link.svg');
}

.nv_RoomTile2_iconSettings::before {
    mask-image: url('$(res)/img/element-icons/settings.svg');
}

.nv_RoomTile2_iconSignOut::before {
    mask-image: url('$(res)/img/element-icons/leave.svg');
}

.nv_RoomTile_iconBellCrossed::before {
    mask-image: url("$(res)/themes/beeper/img/icon-mute.svg");
}

.nv_RoomTile_iconCheckmark::before {
    mask-image: url("$(res)/themes/beeper/img/icon-bubble-tick.svg");
    mask-size: 20px !important;
}

.nv_RoomTile_iconMarkUnread::before {
    mask-image: url("$(res)/themes/beeper/img/icon-mark-unread.svg");
    mask-size: 20px !important;
}

.nv_RoomTile2_iconUnstar::before {
    mask-image: url("$(res)/themes/beeper/img/icon-heart-cross.svg");
}

.nv_RoomTile2_contextMenu_redRow {
    .nv_AccessibleButton, .mx_IconizedContextMenu_label {
        color: $warning-color !important; // !important to override styles from context menu
    }
    .mx_IconizedContextMenu_icon::before {
        background-color: $warning-color;
    }
}

.nv_RoomTile2_iconDelete::before {
    mask-image: url("$(res)/img/icon-trash.svg");
}

.nv_RoomTile_iconUnpin::before {
    mask-image: url("$(res)/themes/beeper/img/icon-unpin.svg");
    padding-top: 1px;
}

.nv_RoomTile_iconPin::before {
    mask-image: url("$(res)/themes/beeper/img/icon-pin-upright.svg");
    padding-top: 1px;
}

.nv_RoomTile_iconChecked::before {
    mask-image: url("$(res)/themes/beeper/img/icon-checkmark.svg");
}

.nv_RoomTile_iconUnmute::before {
    mask-image: url("$(res)/themes/beeper/img/icon-unmute.svg");
}

// .nv_RoomTile2 .mx_BaseAvatar_image {
//     border-radius: 8px;
// }

.nv_RoomTile2_dm .mx_BaseAvatar_image {
    border-radius: 50%;
}

.nv_RoomTile2_invite {
    // background: #fff5db;
}

.nv_RoomTile2_favourite {
    max-width: 120px;
    flex-direction: column;
    // padding: 8px;
    // padding-top: 14px;
    padding: 0;
    padding: 6px;
    border-radius: 8px !important;
    align-items: center;


    .nv_NotificationBadge {
        position: absolute;
        right: 2px;
        top: 2px;
    }
    
    .bp_RoomTile2_contextButtonWrapper {
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 4px;

    }

    .mx_BaseAvatar_image {
        border-radius: 50%;
    }

    .mx_BaseAvatar_initial {
        display: flex;
        // width: 100% !important;
        // height: 100%;
        align-items: center;
        justify-content: center;
    }

    .nv_RoomTile2_content {
        margin-top: 5px;
        margin-left: 0;
        flex: 1;
    }

    .nv_RoomTile2_nameContainer {
        justify-content: center;

        .nv_NotificationBadge {
            width: auto;
            height: auto;

            &::before {
                width: 8px;
                height: 8px;
                margin: 0;
                margin-right: 4px;
            }
        }

        .nv_RoomTile2_name {
            font-size: 12px;
            line-height: inherit;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
