/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

// Dev note: there's no actual component called <PlaybackContainer />. These classes
// are shared amongst multiple voice message components.

// Container for live recording and playback controls
.mx_MediaBody.mx_VoiceMessagePrimaryContainer {
    // The waveform (right) has a 1px padding on it that we want to account for, otherwise
    // inherit from mx_MediaBody
    padding-right: 11px;

    // Cheat at alignment a bit
    display: flex;
    align-items: center;

    contain: content;

    .mx_Waveform {
        .mx_Waveform_bar {
            background-color: $quaternary-content;
            height: 100%;
            /* Variable set by a JS component */
            transform: scaleY(max(0.05, var(--barHeight)));

            &.mx_Waveform_bar_100pct {
                // Small animation to remove the mechanical feel of progress
                transition: background-color 250ms ease;
                background-color: $secondary-content;
            }
        }
    }

    .mx_Clock {
        width: $font-42px; // we're not using a monospace font, so fake it
        padding-right: 6px; // with the fixed width this ends up as a visual 8px most of the time, as intended.
        padding-left: 8px; // isolate from recording circle / play control
    }

    &.mx_VoiceMessagePrimaryContainer_noWaveform {
        max-width: 162px; // with all the padding this results in 185px wide
    }
}

.mx_RecordingPlayback_timelineLayoutMiddle {
    position: relative;
}