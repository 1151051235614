/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_MImageBody {
    display: block;
}

.mx_MImageBody_thumbnail_container {
    // Prevent the padding-bottom (added inline in MImageBody.js) from
    // affecting elements below the container.
    overflow: hidden;

    // Make sure the _thumbnail is positioned relative to the _container
    position: relative;
}

.mx_MImageBody_thumbnail_spinner {
    position: static;
}

// Inner img and TintableSvg should be centered around 0, 0
.mx_MImageBody_thumbnail_spinner > * {
    transform: translate(-50%, -50%);
}

.mx_MImageBody_gifLabel {
    position: absolute;
    display: block;
    top: 0px;
    left: 14px;
    padding: 0 5px;
    border-radius: 7px;
    background: $imagebody-giflabel;
    border: 2px solid $imagebody-giflabel-border;
    color: $imagebody-giflabel-color;
    pointer-events: none;
}

.mx_HiddenImagePlaceholder {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    // To center the text in the middle of the frame
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    cursor: default;
    background-color: $header-panel-bg-color;

    .mx_HiddenImagePlaceholder_button {
        color: $accent-color;

        span.mx_HiddenImagePlaceholder_eye {
            margin-right: 8px;

            background-color: $accent-color;
            mask-image: url('$(res)/img/feather-customised/eye.svg');
            display: inline-block;
            width: 18px;
            height: 14px;
        }

        span:not(.mx_HiddenImagePlaceholder_eye) {
            vertical-align: text-bottom;
        }
    }
}

.mx_EventTile:hover .mx_HiddenImagePlaceholder {
    background-color: $primary-bg-color;
}
