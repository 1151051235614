/*
Copyright 2019 New Vector Ltd.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

// Not actually a component but things shared by settings components
.mx_UserSettingsDialog,
.mx_RoomSettingsDialog,
.mx_SpaceSettingsDialog,
.mx_SpacePreferencesDialog {
    width: 90vw;
    max-width: 1000px;
    // set the height too since tabbed view scrolls itself.
    height: 80vh;

    .mx_TabbedView {
        top: 65px;
    }

    .mx_TabbedView .mx_SettingsTab {
        box-sizing: border-box;
        min-width: 580px;
        padding-right: 100px;
        display: flex;
        flex-direction: column;

        // Put some padding on the bottom to avoid the settings tab from
        // colliding harshly with the dialog when scrolled down.
        padding-bottom: 100px;
    }
}

.mx_ChatNetworksDialog_wrapper {
    align-items: flex-end;
}

.mx_ChatNetworksDialog {
    // width: 90vw;
    // max-width: 1000px;
    // set the height too since tabbed view scrolls itself.
    // height: 92vh;
    padding: 0;
    // margin: -20px;
    height: 100%;
    width: 100%;
}
.mx_ChatNetworksDialog .mx_Dialog_cancelButton {
    right: 20px;
    top: 18px;
}

// This is the override for mx_Dialog, which wraps mx_ChatNetworksDialog
.mx_ChatNetworks_Dialog {
    padding: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: unset;
    width: 90vw;
    height: 92vh;
    background: $primary-bg-color;

    &.inOnboarding {
      width: 100%;
      height: 100%;
      border-radius: 0;
      max-height: unset;
    }
}

.ChatNetworksDialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $primary-fg-color;

    &.inOnboarding {
      width: 100%;
      color: #fff;
      overflow: hidden;
    }
}

.inOnboarding {
  .statusIcon_loading {
      border-color: #ffffff50;
  }

  .tab:hover {
    background: #ffffff25 !important;
  }
}

.infoIcon {
    mask-image: url('$(res)/themes/beeper/img/icon-info.svg');
    mask-size: contain;
    background-color: #717689;
    width: 15px;
    height: 15px;
    display: inline-block;
}

.ChatNetworksDialog-leftbar {
    width: 30%;
    height: 100%;
    background: $primary-bg-color;
    overflow-y: scroll;

    &.inOnboarding {
      background: transparent;
      margin-top: 85px;
    }
}

.ChatNetworksDialog-leftbar .tabbar .tab {
    padding: 12px 16px 12px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    height: 24px;

    &.disabled {
      cursor: not-allowed;
    }

    // box-shadow: 24px 1px 0px #f0f0f1;
}
.ChatNetworksDialog-leftbar .tabbar .tab.selected:hover,
.ChatNetworksDialog-leftbar .tabbar .tab.selected {
    background: #4958f5;
}
.ChatNetworksDialog-leftbar .tabbar .tab:hover {
    background: #747f8d26;
    &.disabled {
        background: unset;
    }
}
.ChatNetworksDialog-leftbar .tabbar .tab .network-icon {
    height: 18px;
    flex: 0 0 24px;
    margin-right: 12px;
}
.ChatNetworksDialog-leftbar .tabbar .tab .name {
    margin-right: auto;
    font-weight: 700;
    font-size: 16px;
}
.ChatNetworksDialog .status-icon {
    height: 16px;
    color: #747f8d61;
}
.ChatNetworksDialog-leftbar .tabbar .tab.selected .status-icon {
    color: #fff;
}
.ChatNetworksDialog .status-icon.warn,
.ChatNetworksDialog-leftbar .tabbar .tab.selected .status-icon.warn {
    color: #ffad61;
}
.ChatNetworksDialog .status-icon.error,
.ChatNetworksDialog-leftbar .tabbar .tab.selected .status-icon.error {
    color: #ff6363;
}
.ChatNetworksDialog .whatsapp-warn {
    margin-right: 6px;
}

.ChatNetworksDialog-content {
    width: 70%;
    height: 100%;
    padding: 0 30px;
    color: $primary-fg-color;
    background-color: $chat-network-content-bg;

    &.inOnboarding {
      background-color: transparent;
      color: #fff !important;
    }

    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    box-shadow: 0px 4px 24px rgba(10, 38, 46, 0.08);

    .center {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bp_Channel_beeper {
        width: 32px;
        height: 32px;
        background-image: url('$(res)/img/beeper.svg');
    }

    .network-icon {
        height: 32px;
        flex: 0 0 24px;
        margin-right: 12px;
    }
}

.network-icon {
    height: 32px;
    width: 32px;
    flex: 0 0 24px;
    margin-right: 12px;
}

.ChatNetworksDialog-content .panel-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    width: 300px;
    margin-bottom: 24px;

    &.panel-title-standout {
      width: fit-content !important;
      line-height: 38.4px;

      & > * {
        display: block;
      }
    }
}

.ChatNetworksDialog-content .facebook-banner {
    width: 100%;
    height: 160px;
    margin-bottom: 80px;
    background-size: cover;
    background-position: center center;
    position: relative;
    border-radius: 8px;
}
.ChatNetworksDialog-content .facebook-profile {
    position: absolute;
    bottom: -50px;
    left: 30px;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    border: 4px white solid;
    background-size: cover;
    background-position: center center;
}

.messenger-gradient {
  background: radial-gradient(108.96% 108.96% at 19.25% 99.47%, #0099ff 0%, #a033ff 60.98%, #ff5280 93.48%, #ff7061 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.facebook-gradient {
    background: radial-gradient(108.96% 108.96% at 19.25% 99.47%, #0099ff 0%, #a033ff 60.98%, #ff5280 93.48%, #ff7061 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

.instagram-gradient {
    background: linear-gradient(45deg, #b31f88, #d03158);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

.ChatNetworksDialog-content .facebook-screen-name {
    font-size: 12px;
    color: #757575;
}

.ChatNetworksDialog-content .twitter-banner {
    width: 100%;
    height: 160px;
    margin-bottom: 80px;
    background-size: cover;
    background-position: center center;
    position: relative;
    border-radius: 8px;
}
.ChatNetworksDialog-content .twitter-profile {
    position: absolute;
    bottom: -50px;
    left: 30px;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    border: 4px white solid;
    background-size: cover;
    background-position: center center;
}
.ChatNetworksDialog-content .twitter-screen-name {
    font-size: 12px;
    color: #757575;
}

.ChatNetworksDialog-content .telegram-phone {
    font-size: 12px;
    color: #757575;
    margin-left: 10px;
}
.ChatNetworksDialog-content .instagram-username {
    font-size: 12px;
    color: #757575;
    margin-left: 10px;
}
.ChatNetworksDialog-content .slack-username {
    font-size: 12px;
    color: #757575;
    margin-left: 10px;
}

.bp_Lifeboat {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: space-between;
    width: inherit;
}

.imessage-startup-alert {
    .bp_Lifeboat {
        position: absolute;
        bottom: 0;
        margin-bottom: 25px;
    }
}

.ChatNetworksDialog-content-imessage {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    details.logs {
        overflow: hidden;

        pre {
            overflow: scroll;
            font-size: 12px !important;
            height: 90%;

            .line {
                &.level-info {
                    color: blue;
                }
                &.level-warn {
                    color: orange;
                }
                &.level-error {
                    color: red;
                }
                &.level-fatal {
                    color: purple;
                }
            }
        }
    }
}

.ChatNetworksDialog .login,
.ChatNetworksDialog input[type='submit'].login {
    padding: 12px 20px;
    border-radius: 6px;
    margin: 10px 0;
    color: white;
    font-size: 1.6rem;
    border: none;
}

.error-pill {
    color: rgb(221, 61, 61);
    border: 1px solid rgb(221, 61, 61);
    border-radius: 6px;
    padding: 6px 10px;
    display: inline-block;

    &.warning {
        color: $shine-100;
        border-color: $shine-100;
    }

    &.new-design {
        // flex: 1;
        display: flex;
        padding: 18px 24px;
        flex-direction: row;
        justify-content: flex-start;
    }

    &.figma {
      background: $primary-bg-color;
      border: 1px solid $siren-100;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
    }
}
.ChatNetworksDialog .input {
    padding: 12px 20px !important;
    border-radius: 6px;
    margin: 10px 0;
    border: 2px solid $input-border-color !important;
    font-size: 1.6rem !important;
    display: block;
    margin: 0;
    outline: none;
    transition: 150ms border-color;
    background-color: $bp-input-bgcolor !important;
    font-weight: normal;
    color: $bp-input-textcolor;
    font-family: Arial, Helvetica, sans-serif;

    &[type=number].no-spin {
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
    }
}

.ChatNetworksDialog .phoneDropdownButton {
  background-color: $input-border-color !important;

  .selected-flag {
    &:hover, &:focus, &.open {
        background-color: $bp-input-hover-color !important;
    }
  }
}

.ChatNetworksDialog .searchInput {
  margin: 0;
  background-color: $bp-input-bgcolor !important;
  padding: 1px !important;
}

.ChatNetworksDialog .countryDropdown {
  background-color: $bp-input-bgcolor !important;
}

.react-tel-input .country-list {
  border-radius: 6px !important;
}

.react-tel-input .country-list .country:hover {
  background-color: #747f8d26 !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: $input-border-color !important;
}

.ChatNetworksDialog .bp_ButtonBlueBorder {
    &.bp_ButtonDisabled {
        color: rgba(255, 255, 255, 0.6);
    }
}
.ChatNetworksDialog .input:focus {
    // margin: -1px;
    border-color: $input-focused-border-color;
}

.ChatNetworksDialog .login.facebook {
    background-color: #0a7cff;
}
.ChatNetworksDialog .login.facebook:hover {
    background-color: #4d4dff;
}
.ChatNetworksDialog .login.googlechat {
    background-color: #0f9d58;
}
.ChatNetworksDialog .login.googlechat:hover {
    background-color: #23aa69;
}
.ChatNetworksDialog .login.discord {
    background-color: #7289da;
}
.ChatNetworksDialog .login.discord:hover {
    background-color: #677bc4;
}
.ChatNetworksDialog .login.instagram {
    background-image: linear-gradient(45deg, #b31f88, #d03158);
}
.ChatNetworksDialog .login.instagram:hover {
    background-image: linear-gradient(45deg, #d03158, #b31f88);
}
.ChatNetworksDialog .login.skype {
    background-color: #1da1f2;
}
.ChatNetworksDialog .login.skype:hover {
    background-color: #0078ca;
}
.ChatNetworksDialog .login.slack {
    background-color: #4a154b;
}
.ChatNetworksDialog .login.slack:hover {
    background-color: rgba(74, 21, 75, 0.9);
}
.ChatNetworksDialog .input.telegram {
    width: 300px;
    height: unset;

    &.phoneInput {
        padding: 10px 20px 10px 48px !important;
    }
}
.ChatNetworksDialog .input.telegram:hover,
.ChatNetworksDialog .input.telegram:focus {
    border-color: #5782a3;
}
.ChatNetworksDialog .login.telegram {
    background-color: #5782a3;
}
.ChatNetworksDialog .login.telegram:hover {
    background-color: #456f92;
}
.ChatNetworksDialog .login.twitter {
    background-color: #1da1f2;
}
.ChatNetworksDialog .login.twitter:hover {
    background-color: #1a91da;
}
.ChatNetworksDialog .login.whatsapp {
    background-color: #00bfa5;
}
.ChatNetworksDialog .login.whatsapp:hover {
    background-color: #00d1b5;
}
.ChatNetworksDialog .login.signal {
    background-color: #4c83f2;
}
.ChatNetworksDialog .login.signal {
    background-color: #4c83f2;
}
.ChatNetworksDialog .login.amp {
    background-color: #1a73e8;
}
.ChatNetworksDialog .login.amp:hover {
    background-color: #10458c;
}

.ChatNetworksDialog .shutdown-bridge {
    cursor: default;
    margin-left: 10px;
    font-size: 12px;
}

.ChatNetworksDialog .bridge-info {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 11px;
    color: #b1b1b1;
    max-width: 64%;
    max-height: 45%;
}
.ChatNetworksDialog .bridge-info:hover {
    overflow: scroll;
}
.ChatNetworksDialog .bridge-info .info {
    display: none;
}
.ChatNetworksDialog .bridge-info:hover .pi {
    display: none;
}
.ChatNetworksDialog .bridge-info:hover .info {
    display: block;
    display: inline;
    margin-left: 10px;
}

.ChatNetworksDialog .puppet-list {
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}
.ChatNetworksDialog .puppet {
    border-radius: 3px;
    padding: 4px 13px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.ChatNetworksDialog .puppet .logout {
    margin-left: 10px;
}

.ChatNetworksDialog .spinner {
    margin-top: 30px;
    height: 24px;
}

.ChatNetworksDialog .puppet-spinner {
    height: 16px;
}

.ChatNetworksDialog-content .instructions {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 280px;
    margin-right: 24px;

    .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 8px;

        .num {
            font-weight: bold;
            margin-right: 12px;
        }
    }
}

.ChatNetworksDialog .bp-qr-wrapper {
    width: 280px;
    height: 280px;
    border-radius: 8px;
    border: 0.25px solid #d0d1d8;
    background-color: $bp-input-bgcolor;
    padding: 12px;
    display: flex;
    flex: 0 0 280px;
}

.ChatNetworksDialog .bp-qr-reload {
    background-color: $bp-input-bgcolor;
    border-radius: 4px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.bp_DiscordServerDropdown {
  min-width: 200px;
  margin: 0 16px;
}

.bp_featureSupportTable {
  & > p {
    font-size: 14px;
    line-height: 20px;
  }

  .boxWrapper {
    display: flex;
    flex-flow: row;
  }

  .box {
    width: 208px;
    height: min-content;
    background: $primary-bg-color;
    border: 0.25px solid $roomtile-hover-bg-color;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .bp_featureRow {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 16px;

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }

    .bp_icon {
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 18px;

      & > :first-child {
        display: flex;
      }
    }
  }
}

.bp_BridgeTileToggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $bp-divider-primary-color;

  .chatNetworks {
      margin: 6px;
  }
}
