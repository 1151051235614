// thoughts from annie
// this works for some reason? 
// it's not how the scss website says to use mixins? https://sass-lang.com/documentation/at-rules/mixin
// even the linter is mad about it? 
// but it works? 
// so I guess it's @define-mixin combined with @mixin to actually use it
@define-mixin general-clickable-item {
  border-radius: 8px;
  cursor: default;

  &.active, &:hover {
    background-color: var(--room-tile-bg-hover);
    &.nohover {
      background-color: unset;
    }
  }
}

.bp_newChatButton {
  border-radius: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  &.active, &:hover {
    background-color: $focus-bg-color;
  }
}

.newChatArrow { 
  transform: rotate(180deg);
  position: absolute;
  color: $new-chat-bg;
  z-index: 1005;
}

.contactLabel {
    background-color: $contact-label-bg;
    border-radius: 4px;
    padding: 0 4px;
    margin-right: 4px;
    color: $contact-label-textcolor
}

.bp_newChatDropdown {
    background-color: $new-chat-bg;
    box-shadow: 0px 10px 44px rgba(0, 0, 0, 0.16);
    border: 1px solid #ffffff20;
    padding: 10px 6px;
    width: 280px;
    height: fit-content;
    border-radius: 12px;
    position: absolute;
    z-index: 999;
    transform: scale(0);
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    text-align: left;
    max-height: 500px;

    &.show {
        animation: growOut 225ms ease-in-out forwards;
        transform-origin: 30px 0px;
    }

    &.hide {
      animation: shrinkIn 100ms ease-in-out forwards;
      transform-origin: 30px 0px;
 
    }

    .contactItem {
        @mixin general-clickable-item;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;

        .contactTitle {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 2px;
        }
        .contactSubtitle {
            font-size: 12px;

            .label {
              background-color: $contact-label-bg;
              border-radius: 4px;
              padding: 0 4px;
              margin-right: 4px;
              color: $contact-label-textcolor
            }
        }

        .contactTitle, .contactSubtitle {
            word-break: break-all;
        }

        .contactLoaderColor {
            background-color: $bp-input-hover-color;
            opacity: 1;
            transition: opacity 1s ease-in-out;

            &.fade {
                opacity: .3;
            }
        }
    }

    // Animations!
    @keyframes growOut {
        0% {
            transform: scale(0);
            opacity: .5;
        }
        80% {
            transform: scale(1.085);
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes shrinkIn {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(0);
            opacity: .5;
        }
    }
}

#newChatUserSearchInput, #confirmConnectionSearchInput {
  padding: 6px;
  border-radius: 6px;
  background-color: $bp-input-hover-color;
  color: $bp-input-textcolor;
  border: none;
  margin: 0 4px 4px;
  // width: 280px;

  input {
    all: unset;
    width: 100%;
  }
}

.loadGoogleContactsButton, .newContactItem {
    @mixin general-clickable-item;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
}

.contactError {
  text-align: center;
  font-size: 12px;
  padding: 6px 12px;
  background-color: $siren-500;
  border-radius: 8px;
  margin: 6px 0;
  color: #fff;
  word-break: break-word;
}

.bp_newChat_contactDetail {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
  }

  .backButton {
    @mixin general-clickable-item;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 6px;
  }

  .contactDetailItem {
    @mixin general-clickable-item;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
  }

  .networksLoader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}