/*
Copyright 2015, 2016 OpenMarket Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_RoomStatusBar:not(.mx_RoomStatusBar_unsentMessages) {
    margin-left: 65px;
    min-height: 50px;
}

.mx_RoomStatusBar_typingIndicatorAvatars {
    width: 52px;
    margin-top: -1px;
    text-align: left;
}

.mx_RoomStatusBar_typingIndicatorAvatars .mx_BaseAvatar_image {
    margin-right: -12px;
    border: 1px solid $background;
}

.mx_RoomStatusBar_typingIndicatorAvatars .mx_BaseAvatar_initial {
    padding-left: 1px;
    padding-top: 1px;
}

.mx_RoomStatusBar_typingIndicatorRemaining {
    display: inline-block;
    color: #acacac;
    background-color: #ddd;
    border: 1px solid $background;
    border-radius: 40px;
    width: 24px;
    height: 24px;
    line-height: $font-24px;
    font-size: 0.8em;
    vertical-align: top;
    text-align: center;
    position: absolute;
}

.mx_RoomStatusBar_scrollDownIndicator {
    cursor: default;
    padding-left: 1px;
}

.mx_RoomStatusBar_unreadMessagesBar {
    padding-top: 10px;
    color: $alert;
    cursor: default;
}

.mx_RoomStatusBar_connectionLostBar {
    display: flex;

    margin-top: 19px;
    min-height: 58px;
}

.mx_RoomStatusBar_unsentMessages {
    > div[role="alert"] {
        // cheat some basic alignment
        display: flex;
        align-items: center;
        min-height: 70px;
        margin: 12px;
        padding-left: 16px;
        background-color: $header-panel-bg-color;
        border-radius: 4px;
    }

    .mx_RoomStatusBar_unsentBadge {
        margin-right: 12px;

        .mx_NotificationBadge {
            // Override sizing from the default badge
            width: 24px !important;
            height: 24px !important;
            border-radius: 24px !important;

            .mx_NotificationBadge_count {
                font-size: $font-16px !important; // override default
            }
        }
    }

    .mx_RoomStatusBar_unsentTitle {
        color: $alert;
        font-size: $font-15px;
    }

    .mx_RoomStatusBar_unsentDescription {
        font-size: $font-12px;
    }

    .mx_RoomStatusBar_unsentButtonBar {
        flex-grow: 1;
        text-align: right;
        margin-right: 22px;
        color: $muted-fg-color;

        .mx_AccessibleButton {
            padding: 5px 10px;
            padding-left: 30px; // 18px for the icon, 2px margin to text, 10px regular padding
            display: inline-block;
            position: relative;

            &:nth-child(2) {
                border-left: 1px solid $resend-button-divider-color;
            }

            &::before {
                content: '';
                position: absolute;
                left: 10px; // inset for regular button padding
                background-color: $muted-fg-color;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: contain;
                width: 18px;
                height: 18px;
                top: 50%; // text sizes are dynamic
                transform: translateY(-50%);
            }

            &.mx_RoomStatusBar_unsentCancelAllBtn::before {
                mask-image: url('$(res)/img/element-icons/trashcan.svg');
            }

            &.mx_RoomStatusBar_unsentResendAllBtn {
                padding-left: 34px; // 28px from above, but +6px to account for the wider icon

                &::before {
                    mask-image: url('$(res)/img/element-icons/retry.svg');
                }
            }
        }

        .mx_InlineSpinner {
            vertical-align: middle;
            margin-right: 5px;
            top: 1px; // just to help the vertical alignment be slightly better

            & + span {
                margin-right: 10px; // same margin/padding as the rightmost button
            }
        }
    }
}

.mx_RoomStatusBar_connectionLostBar img {
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    float: left;
}

.mx_RoomStatusBar_connectionLostBar_title {
    color: $alert;
}

.mx_RoomStatusBar_connectionLostBar_desc {
    color: $primary-content;
    font-size: $font-13px;
    opacity: 0.5;
    padding-bottom: 20px;
}

.mx_RoomStatusBar_resend_link {
    color: $primary-content !important;
    text-decoration: underline !important;
    cursor: default;
}

.mx_RoomStatusBar_typingBar {
    height: 50px;
    line-height: $font-50px;

    color: $primary-content;
    opacity: 0.5;
    overflow-y: hidden;
    display: block;
}

.mx_MatrixChat_useCompactLayout {
    .mx_RoomStatusBar:not(.mx_RoomStatusBar_unsentMessages) {
        min-height: 40px;
    }

    .mx_RoomStatusBar_indicator {
        margin-top: 10px;
    }

    .mx_RoomStatusBar_typingBar {
        height: 40px;
        line-height: $font-40px;
    }
}
