.bp_card {
    background-color: $background;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(19, 27, 58, 0) 0.01%, rgba(19, 27, 58, 0.4) 100%), #32384E;
    color: $primary-fg-color;
    position: relative;
    box-shadow: 2px 15px 30px 0 rgb(0 0 0 / 48%);
    border-radius: 20px;
    padding: 24px;
}

.bp_card-content {
    overflow: auto;
    height: 100%;
    width: 100%;
}

.bp_card-small {
    width: 300px;
    height: 200px
}

.bp_card-medium {
    width: 500px;
    height: 400px;
}

.bp_card-large {
    width: 800px;
    height: 650px;
}

.bp_card-dynamic {
    width: fit-content;
    height: fit-content;
}