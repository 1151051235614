.mx_EventTile_message .mx_EventTile_line:not(.mx_MImageBody, .mx_MVideoBody),
.mx_EventTile_message .mx_MTextBody.mx_EventTile_content,
.mx_EventTile_messageOut .mx_EventTile_line .mx_EventTile_content {
    max-width: revert;
}

.mx_EventTile_content-caption {
    max-width: 400px !important;
}

.mx_EventTile_message .mx_EventTile_line .mx_MImageBody .mx_MImageBody_thumbnail,
.mx_Blurhash {
    max-height: min(50vh, 480px);
    object-fit: cover;
    object-position: center;
    margin: 0 auto;
}
.mx_EventTile_line--caption {
    position: static;
}
.mx_EventTile_reactionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    gap: 5px;
}
.bp_SendIndicator-tooltip {
    display: inline-block;
    color: $message-out-fg;
    line-height: 0;
    width: 16px;
}
.mx_MessageTimestamp-media-container .bp_SendIndicator {
    margin-left: 0;
}
.bp_SendIndicator {
    margin-left: 5px;
}
.mx_EventTile_video .mx_EventTile_reactionsContainer {
    padding-left: 8px;
    width: calc(100% - 20px);
}
.mx_EventTile_message .mx_EventTile_line :not(.mx_MImageBody, .mx_MVideoBody) {
    max-width: revert;
}
.mx_EventTile_line {
    .mx_MessageReply,
    .mx_ReplyChain_wrapper,
    .mx_ReplyChain_wrapper div {
        max-width: 100%;
    }
    .mx_ReplyTile a {
        max-width: revert;
    }
}
.mx_EventTile_messageOut {
    .mx_BaseAvatar {
        border-color: hsla(0, 0%, 100%, 0.15);
    }
    .mx_MImageBody + .mx_ReactionsRow .mx_ReactionsRowButton .mx_BaseAvatar {
        border-color: $reaction-gap-dark;
    }
    .mx_ReactionsRow {
        justify-content: flex-start;
    }
    .mx_EventTile_reactionsContainer {
        background-color: #3577ff;
    }
}
.mx_EventTile_image {
    .mx_EventTile_line--reply-chain {
        padding: 4px 8px !important;   
        max-width: 375px !important;
        background-color: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--message-bg-saturation),
            var(--message-bg-lightness),
            var(--message-bg-opacity)
        ) !important;
        .mx_MImageBody {
            background-color: hsla(
                var(--avatar-hue, var(--default-avatar-hue)),
                var(--message-bg-saturation),
                var(--message-bg-lightness),
                var(--message-bg-opacity)
            );
        }
        &.mx_EventTile_line--self  {
            background-color: #3577ff !important;
            .mx_MImageBody {
                background-color: #3577ff !important;
            }
        }
    }
    .mx_MImageBody-container .mx_SenderInfo_wrapper {
        padding: 0 5px;
    }
    .mx_EventTile_reactionsContainer {
        .mx_ReactionsRow {
            margin-left: 5px;
        }

        .mx_MessageTimestamp {
            margin-right: 10px;
        }
    }
}
.mx_ReactionsRowButton_selected .mx_BaseAvatar {
    border-color: #6996f2;
}
.mx_EventTile_messageIn {
    .mx_MTextBody + .mx_ReactionsRow .mx_ReactionsRowButton:not(.mx_ReactionsRowButton_selected) .mx_BaseAvatar,
    .mx_EventTile_caption .mx_ReactionsRow .mx_ReactionsRowButton:not(.mx_ReactionsRowButton_selected) .mx_BaseAvatar {
        border-color: $reaction-gap-dark;
    }
    .mx_ReactionsRowButton:not(.mx_ReactionsRowButton_selected) .mx_BaseAvatar {
        border-color: $reaction-gap-light;
    }
}
.mx_ReactionsRow {
    border-radius: 17px;
    gap: 5px;

    .mx_ReactionsRow_addReaction {
        border-radius: 17px;
        width: 36px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        .bp_icon {
            height: 16px;
        }

        svg {
            margin-top: 0;
        }
    }

    .mx_ReactionsRowButton {
        padding-right: 2px;
    }

    .mx_ReactionsRowButton {
        height: 25px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border: none;
        box-shadow: none;
        border-radius: 17px;
        margin-right: 0;
        background-color: transparent;

        .mx_ReactionsRowButton_content {
            font-size: 20px;
        }

        .mx_ReactionsRowButton_count {
            height: auto;
            padding-right: 12px;
        }

        .bp_EventListSummary_avatars {
            display: flex;
            .mx_BaseAvatar {
                margin-right: -5px;
                border-radius: 9999px;
                border-width: 1px;
                border-style: solid;
                &:first-child {
                    z-index: 3;
                }
                &:nth-child(2) {
                    z-index: 2;
                }
                &:last-child {
                    z-index: 1;
                }
            }
        }
    }
}
.mx_EventTile_line--reply-chain {
    .mx_SenderInfo_wrapper {
        order: -1;
    }

    .mx_MTextBody {
        width: 100%;
    }
}
.mx_EventTile_message .mx_SenderProfile,
.mx_EventTile_sticker .mx_SenderProfile {
    color: revert;
}
.mx_SenderProfile_displayName {
    font-weight: bold;
    color: hsla(
        var(--avatar-hue, var(--default-avatar-hue)),
        var(--sender-name-saturation),
        var(--sender-name-lightness),
        0.5
    );
}
.mx_EventTile_file.mx_EventTile_message .mx_MessageTimestamp {
    bottom: 0;
}
.mx_EventTile_message .mx_MessageTimestamp:not(.mx_MessageReadReceipt),
.mx_EventTile_sticker .mx_MessageTimestamp {
    position: relative;
    font-size: 10px;
    margin-left: 5px;
}
.mx_ReplyTile .mx_MTextBody {
    padding-right: 0;
}
.mx_EventTile_messageOut {
    .mx_MessageTimestamp {
        color: hsla(0, 0%, 100%, 0.5);
    }
}
.mx_EventTile_messageOut {
    .mx_ReactionsRowButton,
    .mx_ReactionsRow_addReaction {
        background-color: hsla(0, 0%, 100%, 0.15);
        color: white;
    }
}
.mx_MessageTimestamp-media-container {
    background: rgba(140, 140, 140, 0.4);
    backdrop-filter: blur(5px);
    padding: 0 5px;
    border-radius: 12px;
    .mx_MessageTimestamp {
        color: #ffffff;
        opacity: 0.6;
        margin-left: 0 !important;
    }
}
.mx_EventTile_messageIn {
    &.mx_EventTile_video {
        .mx_SenderInfo_wrapper + .mx_MVideoBody .mx_MVideoBody {
            border-radius: 0;
        }
        .mx_EventTile_reactionsContainer {
            width: 100%;
            .mx_MessageTimestamp {
                margin-right: 15px;
            }
        }
        .mx_EventTile_caption .mx_SenderInfo_wrapper {
            padding: 0;
        }
        .mx_SenderInfo_wrapper {
            width: 100%;
            padding: 0 5px;
        }
    }
    .mx_ReactionsRowButton,
    .mx_ReactionsRow_addReaction,
    .mx_MImageBody-container + .mx_EventTile_reactionsContainer,
    &.mx_EventTile_video .mx_EventTile_reactionsContainer,
    .mx_MImageBody-container .mx_SenderInfo_wrapper,
    &.mx_EventTile_video .mx_EventTile_line:not(.mx_EventTile_line--caption) .mx_SenderInfo_wrapper {
        background-color: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--reaction-saturation),
            var(--reaction-lightness),
            var(--reaction-opacity)
        );
        color: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--add-reaction-saturation),
            var(--add-reaction-lightness),
            1
        );

        &.mx_ReactionsRowButton_selected {
            background-color: #4d82ee;
            color: white;
        }
    }
    &.mx_EventTile_sticker {
        .mx_MessageTimestamp {
            position: absolute;
            right: 5px;
            bottom: 5px;
            backdrop-filter: blur(5px);
            padding: 0 5px;
            border-radius: 12px;
            background: rgba(140, 140, 140, 0.4);
            color: #ffffff;
            opacity: 0.6;
        }
    }
    .mx_MessageTimestamp-media-container .mx_MessageTimestamp {
        color: #ffffff;
    }
    .mx_MessageTimestamp {
        color: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--timestamp-saturation),
            var(--timestamp-lightness),
            0.5
        );
    }
    &.mx_EventTile_linkPreview .mx_MessageBody .mx_EventTile_avatar {
        grid-row: 2 / 4;
    }
    .mx_SenderInfo_wrapper {
        margin-top: 0;
    }
}

.mx_EventTile {
    max-width: 100%;
    position: relative;
    .mx_MFileBody::before {
        background-color: $accent-color;
    }
}

.mx_EventTile_reply {
    cursor: default;
    user-select: none;
}

.mx_MessageBody {
    align-items: center;
    display: flex;
}

.mx_EventTile_sidepad {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.mx_EventTile_message {
    .mx_EventTile_line {
        // Keep in sync with packages/matrix-react-sdk/src/components/views/messages/MVideoBody.tsx#260
        .mx_MImageBody {
            background: $event-tile-bg;
            * {
                max-width: unset;
            }

            &.sticker {
              background: transparent;
            }
        }

        [data-mx-emoticon] {
            height: 16px;
            position: relative;
            top: 2px;
        }

        .mx_ReplyTile {
            .mx_MImageBody {
                background: transparent;
                * {
                    max-width: unset;
                }
            }
        }
    }

    .mx_EventTile_controls {
        visibility: hidden;
        display: flex;
        flex: 1;
        a {
            background: var(--message-actions-fg);
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background: #4958f5;
            }
        }
        a.mx_EventTile_replyButton {
            mask: url("$(res)/themes/beeper/img/icon-reply.svg");
        }
        a.mx_EventTile_emojiButton {
            mask: url("$(res)/themes/beeper/img/icon-emoji.svg");
        }
        a.mx_EventTile_moreButton {
            mask: url("$(res)/themes/beeper/img/icon-more.svg");
        }
    }

    &:hover,
    &.mx_EventTile_active {
        .mx_EventTile_controls {
            visibility: visible;
        }
    }
}

.mx_EventTile_message {
    .mx_MessageReply {
        width: 100%;
        .mx_ReplyChain_wrapper {
            width: 100%;
        }
    }
}

.mx_EventTile_messageIn {
    .mx_MessageBody {
        width: 100%;
        display: grid;
        grid-template-columns: 32px fit-content(calc(var(--room-view-width) - 37px - 6px - 50px)) minmax(15%, auto);

        .mx_SenderInfo_wrapper {
            grid-column: 2 / 4;
        }

        .mx_EventTile_avatar {
            margin-right: 4px;
            grid-row: 1 / 4;
            height: 28px;
            align-self: flex-end;
        }

        .mx_EventTile_line {
            grid-column: 2 / 3;
            align-items: flex-start;
        }

        .mx_EventTile_footer {
            grid-column: 2 / 3;
        }
        .mx_ReactionsRow,
        .bp_LinkPreview {
            grid-column: 2 / 4;
            justify-content: flex-start;
        }
    }

    &.mx_EventTile_dm {
        .mx_MessageBody {
            grid-template-columns: 0px fit-content(calc(var(--room-view-width) - 37px - 6px - 50px)) minmax(15%, auto);
        }
    }

    .mx_EventTile_sidepad {
        margin-left: 6px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
    }

    .mx_SenderInfo_wrapper {
        .mx_SenderInfo_name {
            font-weight: 600;
        }
    }

    .mx_EventTile_edited {
        margin-right: 4px;
    }

    .mx_MessageReply {
        display: flex;
        align-items: flex-start;
        grid-column: 2 / 3;
    }

    .mx_EventTile_readAvatars {
        margin-left: 4px;
    }

    .mx_ReplyChain {
        background: $incoming-reply-thread-bg;
    }
}

.mx_EventTile_messageOut {
    .mx_MessageBody {
        display: grid;
        grid-template-columns: fit-content(24px) fit-content(calc(var(--room-view-width) - 37px - 6px - 50px)) minmax(15%, auto);
        // grid-template-columns: minmax(auto, max-content);
        direction: rtl;

        > * {
            direction: ltr;
        }
    }

    .mx_EventTile_avatar {
        display: none;
    }
    .mx_EventTile_edited {
        margin-left: 4px;
    }

    .mx_EventTile_sidepad {
        flex: 1;
        margin-right: 4px;
    }

    .mx_EventTile_controls {
        justify-content: flex-end;
    }

    .mx_ReactionsRow,
    .bp_LinkPreview {
        grid-column: 1 / 4;
        justify-content: flex-end;
    }
    .mx_EventTile_status {
        flex-direction: row-reverse;

        .mx_EventTile_readAvatars {
            margin-right: 4px;
        }
    }

    .mx_MessageReply {
        display: flex;
        flex-direction: row-reverse;
    }

    .mx_LinkPreviewWidget {
        color: $message-out-secondary-fg;
    }
}

.mx_EventTile_body {
    font-size: 14px;

    &.mx_EventTile_bigEmoji {
        font-size: 48px !important;
        line-height: 57px !important;
        
        [data-mx-emoticon] {
            height: 48px;
        }

        & + .mx_EventTile_reactionsContainer {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
            padding: 0 5px;
        }
    }
}

.mx_EventTile_bigEmoji + .mx_EventTile_reactionsContainer {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 0 5px;
}

.mx_EventTile_emoji,
.mx_EventTile_sticker {
    .mx_EventTile_line {
        background: transparent !important;
    }
}

.mx_EventTile_message,
.mx_EventTile_sticker {
    margin-bottom: 2px;
    .mx_EventTile_line {
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        padding: 4px 8px;
    }

    &.mx_EventTile_messageOut .mx_MessageBody .mx_EventTile_line {
        border-radius: 12px 4px 4px 12px;
    }

    &.mx_EventTile_messageIn .mx_MessageBody .mx_EventTile_line {
        border-radius: 4px 12px 12px 4px;
    }

    &.mx_EventTile_last,
    &.mx_EventTile_lastInGroup,
    &.mx_EventTile_lastInSection {
        .mx_EventTile_line {
            border-bottom-left-radius: 12px !important;
            border-bottom-right-radius: 12px !important;
        }
    }

    &.mx_EventTile_firstInGroup .mx_EventTile_line {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }

    .mx_Spinner_icon {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mx_EventTile_content {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;

        .hljs {
            display: inline !important;
        }
        .mx_MImageBody-container,
        .mx_MImageBody_thumbnail {
            background: none;
        }
        &-caption {
            .mx_MImageBody-container {
                max-width: revert;
            }
            .mx_MImageBody {
                margin-inline: auto;
                margin-bottom: 6px;

                .mx_MImageBody_thumbnail {
                    border-radius: 0;
                }
            }
        }
    }
    &.mx_EventTile_messageOut .mx_EventTile_line {
        margin-left: 0;
    }
    .mx_SenderInfo_wrapper {
        display: flex;
        align-items: center;
        font-size: 12px;
        .mx_MessageTimestamp {
            margin-left: 4px;
        }
    }
    .mx_SenderProfile {
        display: inline;
        font-weight: 600;
        cursor: default;
    }
    .mx_EventTile_avatar {
        cursor: default;
        user-select: none;
        align-self: flex-start;
    }

    .mx_ReplyChain {
        margin-bottom: 4px;

        .mx_DateSeparator {
            display: none;
        }
        .mx_EventTile_message {
            flex-direction: column;
        }
    }

    .mx_ReplyChain_header {
        font-size: 12px;

        a {
            text-decoration: underline;
        }
    }
}
.mx_EventTile_continuation {
    .mx_SenderProfile_wrapper {
        margin-bottom: 0;
    }
}
.mx_ReplyChain {
    background: $reply-thread-bg;
    padding: 4px 8px;
    border-radius: 8px;

    .nv_DateSeparator {
        display: none;
    }
}

.bp_ReplyThread_replyTo {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.bp_ReplyThread_replyToIcon {
    mask: url("$(res)/themes/beeper/img/icon-replied-to.svg");
    background: currentColor;
    display: inline-block;
    width: 14px;
    height: 14px;
}

.mx_EventTile_lastInSection:not(.mx_EventTile_emoji, .mx_EventTile_image, .mx_EventTile_file, .mx_EventTile_sticker, .mx_EventTile_video)
    .mx_EventTile_line:before {
    position: absolute;
    bottom: -0px;
    width: 11px;
    height: 14px;
    display: inline-block;
    content: "";
    mask: url("$(res)/img/message-tail.svg") no-repeat 50% 50%;
    mask-size: cover;
}

.mx_EventTile_messageOut {
    .mx_MessageBody {
        justify-content: flex-start;
        flex-direction: row-reverse;
    }
    &.mx_EventTile_lastInSection .mx_EventTile_line:before {
        background-color: #3577ff;
        right: -5px;
        mask-size: cover;
    }
}

.mx_EventTile_messageIn {
    &.mx_EventTile_lastInSection .mx_EventTile_line:before {
        background-color: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--message-bg-saturation),
            var(--message-bg-lightness),
            var(--message-bg-opacity)
        );
        left: -5px;
        transform: rotateY(180deg);
    }
}

.mx_EventTile_messageIn {
    .mx_EventTile_line {
        background: $event-tile-bg;
        color: $event-tile-fg;
    }

    .mx_EventTile_line {
        background: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--message-bg-saturation),
            var(--message-bg-lightness),
            var(--message-bg-opacity)
        );
        color: hsla(
            var(--avatar-hue, var(--default-avatar-hue)),
            var(--message-color-saturation),
            var(--message-color-lightness),
            var(--message-color-opacity)
        );
    }

    .mx_EventTile_content {
        &.mx_EventTile_bigEmoji {
            background: transparent;
        }
    }
}

.mx_EventTile_messageOut:not(.mx_EventTile_emoji, .mx_EventTile_image, .mx_EventTile_sticker)
    .mx_EventTile_line {
    background: #3577ff !important;
}

.mx_EventTile_messageOut {
    &.mx_EventTile_emoji, &.mx_EventTile_image, &.mx_EventTile_sticker {
        .mx_ReplyChain_wrapper {
            background: #3577ff !important;
        }
    }
}


.mx_EventTile_messageOut {
    .mx_EventTile_line {
        background-position: bottom right;
        background-size: 440px;

        padding: 4px 8px;
        align-items: flex-start;

        .mx_EventTile_content {
            color: $message-out-fg !important;

            &.mx_EventTile_bigEmoji {
                background: transparent;
                margin-inline-start: auto;
            }

            .mx_EventTile_body.markdown-body {
                color: $message-out-fg;
            }

            a:not(.mx_Pill) {
                color: $message-out-fg;
            }
        }

        .mx_Pill {
            background: rgba(37, 38, 45, 0.4);
            color: $mention-pill-out-fg;
        }

        .mx_AtRoomPill,
        .mx_UserPill_me {
            color: $at-me-pill-fg;
        }

        .mx_ReplyChain {
            background: rgba(55, 58, 67, 0.24);
            color: $message-out-fg;
        }
    }

    .mx_Spinner_icon {
        background-color: $message-out-fg;
    }

    .bp_ReplyThread_toggle {
        margin-left: 4px;
        color: $message-out-fg;
    }
}

.mx_EventTile_reply {
    .mx_EventTile_content {
        background: none;
        color: $primary-fg-color;
        padding: 0;

        .mx_EventTile_body.markdown-body {
            color: $primary-fg-color;
        }
    }

    .mx_EventTile_body {
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.mx_EventTile_droplet {
    margin-bottom: 8px;
}

.mx_EventTile_readAvatars {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    user-select: none;
    z-index: 1;
}
.mx_EventTile_readAvatars:empty {
    width: 0;
    display: none;
    margin: 0;
}

.mx_EventTile_status {
    display: flex;
    align-items: center;
    line-height: 14px;
}

.mx_EventTile_readAvatars .mx_BaseAvatar {
    position: absolute;
    display: inline-block;
    height: $font-14px;
    width: $font-14px;
}

.mx_EventTile_readAvatarRemainder {
    color: $event-timestamp-color;
    font-size: $font-11px;
    position: absolute;
}

.mx_EventTile_sending .mx_EventTile_statusGroup,
.mx_EventTile_notSent .mx_EventTile_statusGroup {
    &::after {
        margin-left: 5px;
        color: $accent-color;
        content: url("$(res)/themes/nova-light/img/dialogs_sending.svg");
    }
}

.mx_EventTile_message:hover {
    .mx_MessageActionBar {
        visibility: visible;
        right: -85px;
    }
}

.mx_EventTile_edited {
    font-size: 12px;
    line-height: 14px;
}

.mx_EventTile_message.mx_EventTile_messageOut .mx_EventTile_edited {
}

.mx_EventTile_message.mx_EventTile_messageIn .mx_EventTile_edited {
    color: #98a2aa;
}

.mx_EventTile .mx_EditMessageComposer .mx_BasicMessageComposer_input:focus {
    color: $primary-fg-color;
    border-color: $accent-color;
}

.mx_EventTile .mx_EditMessageComposer .mx_EditMessageComposer_buttons {
    position: static;
    background: none;
    margin: 0;
    padding: 3px 4px 4px 4px;
    .mx_AccessibleButton {
        border-radius: 3px;
    }
}
.mx_EventTile:not(.mx_EventTile_droplet) .mx_EditMessageComposer_buttons .mx_AccessibleButton {
    border-bottom-right-radius: 9px;
}

.mx_EventTile .mx_EditMessageComposer .mx_BasicMessageComposer_input {
    border-radius: 8px 8px 3px 3px;
    margin: 6px 4px 0 4px;
    padding: 6px 6px;
}

.mx_EventTile_image,
.mx_EventTile_video {
    .mx_SenderProfile_name {
        margin: 9px 0 6px 14px;
        display: block;
    }
    .mx_EventTile_line {
        padding: 0;
        overflow: hidden;
        &::before,
        &::after {
            display: none;
        }
    }
}

.mx_EventTile_line > .mx_MVideoBody,
.mx_MImageBody_thumbnail_container {
    line-height: 0;
}

.mx_EventTile_image .mx_EventTile_line,
.mx_EventTile_video .mx_EventTile_line {
    background: none;
}

.mx_EventTile_image,
.mx_EventTile_sticker {
    .mx_MImageBody_thumbnail_container {
        .mx_MImageBody_thumbnail {
            position: static;
            object-fit: contain;
        }
    }
}

.mx_EventTile_sticker {
    .mx_MImageBody_thumbnail_container {
        & > div:first-child {
            display: none;
        }
    }
}

.mx_EventTile_image.mx_EventTile_continuation,
.mx_EventTile_video.mx_EventTile_continuation,
.mx_EventTile_image.mx_EventTile_messageOut {
    .mx_EventTile_line {
        background: none;
        box-shadow: none;
    }
}

.mx_EventTile_e2eIcon {
    position: absolute;
    top: 7px;
    right: 11px;
    width: 15px;
    height: 15px;
    display: block;
    opacity: 0.2;
    background-repeat: no-repeat;
    background-size: contain;
}

.mx_EventTile_e2eIcon_undecryptable,
.mx_EventTile_e2eIcon_unverified {
    background-image: url("$(res)/img/e2e/warning.svg");
    opacity: 1;
}

.mx_EventTile_e2eIcon_unknown {
    background-image: url("$(res)/img/e2e/warning.svg");
    opacity: 1;
}

.mx_EventTile_e2eIcon_unencrypted {
    background-image: url("$(res)/img/e2e/warning.svg");
    opacity: 1;
}

.mx_EventTile_e2eIcon_hidden {
    display: none;
}

.mx_EventTile_keyRequestInfo {
    margin-top: 3px;
    font-size: 13px;
    color: #acacac;
    .mx_TooltipButton {
        font-size: 10px;
        border: 1px solid #acacac;
        color: #acacac;
    }
}

.mx_MNoticeBody.mx_EventTile_content {
    font-size: 13px;
    color: #acacac;
    opacity: 1;
    min-height: 30px;
}

.mx_EventTile .mx_ReactionsRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &:empty {
        display: none;
    }
}

.mx_MTextBody {
    white-space: pre-wrap;
}

.mx_EventTile_body.markdown-body {
    font-family: inherit;
    color: inherit;
    line-height: 2.2rem;
    white-space: normal !important;
    .mx_EventTile_pre_container,
    code {
        font-size: 13px !important;
    }

    blockquote {
        color: $primary-fg-color;
        padding-left: 7px;
        margin: 6px 0;
        border-left: 2px solid $accent-color;
        vertical-align: middle;
    }
    code {
        border-radius: 3px;
        padding: 1px 5px;
        line-height: 1.45;
        &::before,
        &::after {
            content: ""; // override gfm.css
        }
    }
    pre {
        padding: 5px 10px;
        code {
            padding: 0;
            white-space: pre-wrap; // see https://gitlab.com/nova/issues/-/issues/662
            word-break: break-all;
        }

        .mx_EventTile_lineNumbers {
            float: left;
            margin: 0 0.5em 0 -1em;
            color: gray;
        }

        .mx_EventTile_lineNumber {
            text-align: right;
            display: block;
            padding-left: 1em;
        }
    }
    & > code,
    pre {
        background-color: rgba(255, 255, 255, 0.65);
    }
    ul,
    ol,
    dl,
    blockquote,
    pre,
    table {
        margin-bottom: 3px;
        margin-top: 3px;
    }
    ol,
    ul {
        padding: 0 0 0 1.2em;
    }
    & > blockquote:first-child {
        margin-top: 3px !important; // override gfm.css
    }
    & > blockquote:last-child {
        margin-bottom: 3px !important; // override gfm.css
    }
    & > .mx_EventTile_pre_container:first-child pre {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    & > .mx_EventTile_pre_container:last-child pre {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    p {
        line-height: 2.2rem;
    }
}

.mx_EventTile_body {
    // ---
    // Forces word-break to fit long words into message balloon.

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: unset;
    // ---
}

.mx_EventTile_messageOut .mx_EventTile_body.markdown-body {
    blockquote {
        color: $primary-bg-color;
    }
    a {
        text-decoration: underline;
    }
}

.mx_EventTile_reply .mx_MImageBody {
    max-width: 320px;
}

.mx_EventTile_inThread.mx_EventTile_selected {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid $divider-color;
}

.bp_ReplyThread_toggle {
    opacity: 0.75;
    border: 1px solid currentColor;
    border-radius: 50px;
    text-decoration: none !important;
    padding: 0 4px;
    cursor: default;
}

.bp_bookmarksIcon {
    width: 12px;
    height: 12px;
    background-image: url("$(res)/img/icons-bookmarks.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    display: block;
}

.bp_notDeliveredText {
    color: $siren-100;
}

/* Spoiler stuff */
.mx_EventTile_spoiler {
    cursor: default;
}

.mx_EventTile_spoiler_reason {
    color: $event-timestamp-color;
    font-size: $font-11px;
}

.mx_EventTile_spoiler_content {
    filter: blur(5px) saturate(0.1) sepia(1);
    transition-duration: 0.5s;
}

.mx_EventTile_spoiler.visible > .mx_EventTile_spoiler_content {
    filter: none;
}

.mx_MessageReadReceipt {
    position: relative;
    font-size: 12px;
    height: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    color: var(--read-receipt-fg);

    &.mx_MessageReadReceipt--own {
        align-self: flex-end;
        text-align: right;
    }

    &.mx_MessageReadReceipt--third-party {
        align-self: flex-start;
        margin-left: 28px;
    }
}

.mx_ReadReceiptTooltip {
    overflow: auto;
    max-height: 400px;

    &.bp_PopperTooltip {
        background: $read-receipt-bg;
        border: 0.348108px solid rgba(0, 0, 0, 0.2);
    }

    .bp_PopperTooltipArrow {
        display: none;
    }
}

.mx_EventTile_messageOut .mx_EventTile_bigEmoji .mx_MTextBody__details-wrapper,
.mx_EventTile_messageOut .mx_EventTile_bigEmoji .mx_MTextBody__details-wrapper .mx_MessageTimestamp {
    color: var(--read-receipt-fg);
}

.mx_EventTile_bigEmoji .mx_MTextBody__details-wrapper {
    float: unset;
}

.mx_EventTile_line--reply-chain .mx_EventTile_bigEmoji .mx_MTextBody__details-wrapper {
    position: absolute;
    right: 7px;
    bottom: 0;
}

.mx_MTextBody__details-wrapper {
    float: right;
}

.mx_MTextBody__detail {
    display: inline-block;
}

.mx_MTextBody__spinner {
    margin-left: 6px;
}

.mx_MemberRow {
    display: flex;
    align-items: center;
    gap: 7px;

    &-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.174054px;
        color: hsla(var(--avatar-hue, var(--default-avatar-hue)), 100%, var(--read-receipt-tooltip-fg), 1);
        opacity: 0.9;
    }
}

.mx_EventTile_gallery {
    .mx_EventTile_line {
        padding: 0 !important;
        background: transparent !important;
        overflow: hidden;
    }

    .mx_MessageTimestamp-media-container {
        position: absolute;
        right: 5px;
        bottom: 5px;
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: baseline;
    }
}