.bp_BannerCloseButtonWrapper {
  background: #585E74;
  border-radius: 80px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.bp_BannerCloseButton {
    mask: url('$(res)/img/feather-customised/cancel.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    width: 16px;
    height: 16px;
    border-radius: 80px;
    background: #F0F0F1;
}
