.bp_Authentication {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: scroll;

    .content {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .page_title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 18px;
        color: #fff;
    }

    .page_description {
        font-size: 20px;
        margin-bottom: 18px;
        color: #fff;
        opacity: .8;
        line-height: 30px;
    }

    .text_input {
      input {
          color: #fff;
      }

      label {
          color: #fff;
      }
    }

    .mx_Dropdown {
      color: #fff;
    }

    .mx_Dropdown_menu {
      background-color: transparent;
    }

    .mx_Dropdown_arrow {
      background: #fff;
    }
}

/*
  EVERYTHING BENEATH HERE WAS DONE IN SPRING OF 2020 AND THUS IS OLD 
*/

.bp-reg {
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-app-region: no-drag;

    .bp-reg-input-wrapper {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px dotted #585e74;
        width: 330px;
        padding: 0px 10px;

        input {
            flex: 1;
            border: none;
            font-size: 28px;
            font-weight: 700;
            color: #fff;
            text-align: center;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #585e74;
            opacity: 1; /* Firefox */
            font-weight: 400;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #585e74;
            font-weight: 400;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #585e74;
            font-weight: 400;
        }

        .bp-reg-hide-pw {
            cursor: default;
            background-image: url('$(res)/img/icons-eye-closed.svg');
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;
            background-position: center;
            padding: 6px;
            margin-top: 5px;

            &.hidden {
                background-image: url('$(res)/img/icons-eye-open.svg');
            }
        }
    }

    label {
        color: #d0d1d8;
        opacity: 0.9;
        height: 70px;
        margin-top: 8px;
        font-size: 14px;
        width: 320px;
        text-align: center;

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        &.error {
            color: #ff6161;
        }
    }
}

.bp-reg-title {
    color: #fff;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.bp-dots-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75px;
    height: 25px;
    border-radius: 12.5px;
    margin-bottom: 48px;

    span {
        background-color: #424961;
        border-radius: 12px;

        &.active {
            &:after {
                width: 16px;
                border-radius: 12px;
                background: linear-gradient(180.97deg, #ff9b71 -10.54%, #db48f3 143.48%);
            }
        }

        &:after {
            content: '';
            display: block;
            flex: 1;
            align-self: center;
            width: 8px;
            height: 8px;
            background-color: #424961;
            border-radius: 100%;
            transition: all 0.3s ease-in-out;
        }
    }
}

.bp-reg-but {
    font-size: 16px;
    font-weight: 700;
    margin-top: 12px;
}

.bp-reg-continue {
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;

    background: linear-gradient(120deg, #A93FE9 12px, #4958F5 502px);

    &:hover {
        box-shadow: 0px 4px 16px rgba(133, 73, 238, 0.24), 0px 2px 12px rgba(130, 74, 239, 0.24);
    }

    &:disabled {
        box-shadow: none;
        background: #d0d1d8;
        cursor: default;
        color: #717689;
    }
}

.bp-security-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .bp-security-icon {
        width: 54px;
        height: 62px;
        background: url('$(res)/img/lock-and-key.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        align-self: center;
    }

    h1 {
        color: #d0d1d8;
        width: 208px;
        font-weight: 800;
        font-size: 32px;
        line-height: 110%;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 12px;
        align-self: center;
    }

    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 165%;
        text-align: center;
        color: #d0d1d8;
        margin-bottom: 20px;
    }
}
