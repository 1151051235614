.mx_RoomSubList_badge > div, .mx_RoomTile_unreadNotify .mx_RoomTile_badge {
    background-color: $roomtile-badge-bg-color;
}
.mx_RoomSubList_scroll {
    padding: 0;
}

.nv_RoomView_messagePanel_useStraitLayout {
    &.nv_RoomView_direct {
        .mx_EventTile_messageIn .mx_EventTile_line {
            margin-left: 0;
        }
    }

    .mx_EventTile_bubbleContainer {
        &.mx_EventTile_continuation .mx_MessageBody {
            justify-content: unset;
        }

        .mx_EventTile_line {
            background: white;
            grid-column: 1/4;

            .mx_EventTileBubble.mx_CreateEvent {
                background-color: #F0F0F1;
            }
        }
    }
}


.nv_RoomView_messagePanel_useStraitLayout {
    .mx_EventTile_messageOut .mx_EventTile_avatar {
        display: none;
    }
    .mx_EventTile_messageOut {
        flex-direction: row-reverse;
        
        ::selection {
            background: rgba(0, 0, 0, 0.2);
        }
    }
    .mx_EventTile .mx_ReactionsRow {
    }
    // .mx_EventTile_droplet.mx_EventTile_messageOut .mx_EventTile_line {
    //     &::before {
    //         clip-path: url('#right-droplet');
    //         left: initial;
    //         right: -10px;
    //     }
    //     &::after {
    //         left: initial;
    //         right: -7px;
    //     }
    // }
    .mx_EventTile_message:hover {
        .mx_MessageActionBar {
            visibility: visible;
            left: -85px;
            right: initial;
        }
    }
}

.mx_EventTile_file {
    .mx_EventTile_line {
        min-width: 268px;
    }
    .mx_SenderProfile_wrapper {
        margin-bottom: 9px;
    }
    &.mx_EventTile_continuation .mx_SenderProfile_wrapper {
        margin-bottom: 0;
    }
    .mx_MFileBody {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .mx_MFileBody_download > a, .mx_MFileBody_downloadLink {
        display: block;
        font-weight: 600;
        color: $primary-fg-color;
        margin-bottom: 6px;
    }
    .mx_MImageBody_size {
        color: #98a2aa;
    }
}

.mx_AutoHideScrollbar {
    scrollbar-width: 4px;
}

// or fallback for webkit browsers
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.mx_RightPanel {
    overflow: hidden;
    max-width: 550px;
}

.mx_RoomHeader_avatar .nv_BridgedIcon {
    height: 12px;
    width: 12px;
    display: block;
    position: absolute;
    bottom: 0;
    right: -3px;
    z-index: 1;
    margin: 0;
}

.mx_RoomBreadcrumbs .mx_RoomBreadcrumbs_crumb .mx_RoomTile_badge {
    background-color: $roomtile-badge-bg-color;
    font-size: 10px;
    height: 14px;
    min-width: 14px;
}

.mx_RoomBreadcrumbs {
    border-top: 1px solid $panel-divider-color;
    border-bottom: 1px solid $panel-divider-color;
}

.mx_MImageBody {
    margin-right: 0;
}

.mx_EventTile_line .mx_ReplyChain_wrapper {
    margin-left: 0;
}
.mx_MatrixChat > .mx_LeftPanel2:hover + .mx_ResizeHandle_horizontal,
.mx_MatrixChat > .mx_ResizeHandle_horizontal:hover {
    &::before {
        display: none;
    }
}

//override for "start chat" and "mark all as read" buttons
.mx_RoomSublist2_contextMenu {
    .mx_AccessibleButton {
        padding: 7px 15px;
        text-align: center;
        border-radius: 4px;
        display: inline-block;
        font-size: 1.4rem;
        color: #ffffff;
        background-color: #419fd9;
        font-weight: 600;
        margin: 0;
    }
    .mx_AccessibleButton:first-child {
        margin-right: 8px;
    }
}

// override for animation to highlight event after scroll to it
.mx_RoomView_MessageList li .mx_EventTile_message .mx_MessageBody {
    position: relative;
    &::before {
        pointer-events: none;
        content: "";
        left: -18px;
        right: -18px;
        top: -2px;
        bottom: -2px;
        position: absolute;
        animation-play-state: initial;
    }
}

@keyframes highlight {
    0% { background-color: rgba(65, 159, 217, 0); }
        10.0% {
            background-color: $event-highlight-bg-color;
        }
    100.0% { background-color: rgba(65, 159, 217, 0); }
}

.nv_AccentedEvent_highlight {
    animation-name: highlight;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-play-state: running;
}


.mx_CallView_holdTransferContent {
    color: #fff;
}

.mx_ReplyChain_wrapper {
    .mx_ReplyChain {
        border-radius: 4px;
        margin-bottom: 2px;
    }
    .mx_ReplyChain:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .mx_ReplyChain:last-child {
        margin-bottom: 4px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.mx_RightPanel_headerButton, .mx_RoomHeader_button {
    opacity: 0.6;

    &:hover {
        opacity: 1;
        background: transparent;
    }
}