.bp_TopUnreadMessagesBar {
    z-index: 1000;
    position: absolute;
    top: 32px;
    left: 50%;
    display: flex;
    align-items: center;
}

.bp_TopUnreadMessagesBar_scrollUp {
    transform: translateX(-50%);
    background: #4958F5;
    color: white;
    font-size: 12px;
    font-weight: bold;
    height: 26px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 30px;
    border: 1.3px solid $muted-fg-color;

    &:before {
      content: "";
      // position: absolute;
      position: static;
      width: 9px;
      height: 10px;
      margin-right: 6px;
      mask: url('$(res)/themes/beeper/img/icon-jump-to-bottom-unread.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      transform: rotate(180deg);
      background: white;
  }
}

.mx_TopUnreadMessagesBar::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 4px;
    border-radius: 16px;
    background-color: $secondary-accent-color;
    border: 6px solid $accent-color;
    pointer-events: none;
}

.mx_TopUnreadMessagesBar_scrollUp {
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    background: $primary-bg-color;
    box-shadow: none;

    cursor: default;

    border: 2px solid $primary-hairline-color;
    &:hover {
        background-color: $primary-hairline-color;
    }
}

.mx_TopUnreadMessagesBar_scrollUp::before {
    content: "";
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    mask: url('$(res)/themes/nova-light/img/icon-jump-to-bottom.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    background: #A8A8A8;
}


.mx_TopUnreadMessagesBar_markAsRead {
    border: 2px solid $primary-hairline-color;
    position: absolute;
    left: 55%;
    &:hover {
        background-color: $primary-hairline-color;
    }
}