/*
Copyright 2017 New Vector Ltd.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

$groupFilterPanelWidth: 56px; // only applies in this file, used for calculations

.mx_GroupFilterPanelContainer {
    flex-grow: 0;
    flex-shrink: 0;
    width: $groupFilterPanelWidth;
    height: 100%;

    // Create another flexbox so the GroupFilterPanel fills the container
    display: flex;
    flex-direction: column;

    // GroupFilterPanel handles its own CSS
}

.mx_GroupFilterPanel {
    z-index: 1;
    background-color: $groupFilterPanel-bg-color;
    flex: 1;
    cursor: default;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 0;
}

.mx_GroupFilterPanel_items_selected {
    cursor: default;
}

.mx_GroupFilterPanel .mx_GroupFilterPanel_divider {
    height: 0px;
    width: 90%;
    border: none;
    border-bottom: 1px solid $tertiary-content;
}

.mx_GroupFilterPanel .mx_GroupFilterPanel_scroller {
    flex-grow: 1;
    width: 100%;
}

.mx_GroupFilterPanel .mx_GroupFilterPanel_tagTileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 6px;
}
.mx_GroupFilterPanel .mx_GroupFilterPanel_tagTileContainer > div {
    margin: 6px 0;
}

.mx_GroupFilterPanel .mx_TagTile {
    // opacity: 0.5;
    position: relative;
}

.mx_GroupFilterPanel .mx_TagTile.mx_TagTile_prototype {
    padding: 3px;
}

.mx_GroupFilterPanel .mx_TagTile:focus,
.mx_GroupFilterPanel .mx_TagTile:hover,
.mx_GroupFilterPanel .mx_TagTile.mx_TagTile_selected {
    // opacity: 1;
}

.mx_GroupFilterPanel .mx_TagTile.mx_TagTile_selected_prototype {
    background-color: $background;
    border-radius: 6px;
}

.mx_TagTile_selected_prototype {
    .mx_TagTile_homeIcon::before {
        background-color: $primary-content; // dark-on-light
    }
}

.mx_TagTile:not(.mx_TagTile_selected_prototype) .mx_TagTile_homeIcon {
    background-color: $roomheader-addroom-bg-color;
    border-radius: 48px;

    &::before {
        background-color: $roomheader-addroom-fg-color;
    }
}

.mx_TagTile_homeIcon {
    width: 32px;
    height: 32px;
    position: relative;

    &::before {
        mask-image: url('$(res)/img/element-icons/home.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 21px;
        content: '';
        display: inline-block;
        width: 32px;
        height: 32px;
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
    }
}

.mx_GroupFilterPanel .mx_TagTile_plus {
    margin-bottom: 12px;
    height: 32px;
    width: 32px;
    border-radius: 20px;
    background-color: $roomheader-addroom-bg-color;
    position: relative;
    /* overwrite mx_RoleButton inline-block */
    display: block !important;

    &::before {
        background-color: $roomheader-addroom-fg-color;
        mask-image: url('$(res)/img/feather-customised/plus.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.mx_GroupFilterPanel .mx_TagTile.mx_TagTile_selected::before {
    content: '';
    height: 100%;
    background-color: $accent;
    width: 4px;
    position: absolute;
    left: -12px;
    border-radius: 0 3px 3px 0;
}

.mx_GroupFilterPanel .mx_TagTile.mx_AccessibleButton:focus {
    filter: none;
}

.mx_TagTile_tooltip {
    position: relative;
    top: -30px;
    left: 5px;
}

.mx_TagTile_context_button {
    min-width: 15px;
    height: 15px;
    position: absolute;
    right: -5px;
    top: -8px;
    border-radius: 8px;
    background-color: $neutral-badge-color;
    color: #000;
    font-weight: 600;
    font-size: $font-10px;
    text-align: center;
    padding-top: 1px;
    padding-left: 4px;
    padding-right: 4px;
}

.mx_TagTile_avatar {
    position: relative;
}

.mx_TagTile_badge {
    position: absolute;
    right: -4px;
    top: -2px;
    border-radius: 8px;
    color: $accent-fg-color;
    font-weight: 600;
    font-size: $font-14px;
    padding: 0 5px;
    background-color: $muted-fg-color;
}

.mx_TagTile_badgeHighlight {
    background-color: $alert;
}
